import React, { useState, useEffect } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import Header from "../../component/Header/Header";
import InternalReceiptForm from "../../component/inventory/InternalReceipt/InternalReceiptForm";
import InternalReceiptTable from "../../component/inventory/InternalReceipt/InternalReceiptTable";
import InternalMaterialRemarks from "../../component/inventory/InternalReceipt/InternalMaterialRemarks";

import { api } from "../../constants/Services";
import { useNavigate } from "react-router";
import useUser from "../../constants/Storage/userData";
import StatusModal from "../../component/StatusModal";

const InternalReceiptScreen = () => {
  const [projectList, setProjectList] = useState([]);
  const { user } = useUser();
  const [mtTableStaticData, setMtTableStaticTable] = useState({
    transfer_qty: {},
    remarks: {},
    net_value: {},
  });
  const [tableSel, setTableSel] = useState([]);

  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [materialList, setMatertialList] = useState({});
  const [showStatus, setShowStatus] = useState(false);

  const [WareHouse, setWareHouse] = useState("");
  const [ProjectTo, setProjectTo] = useState("");
  const [materialIssueNo, setMaterialIssueNo] = useState("");
  const [materialIssueDate, setMaterialIssueDate] = useState("");
  const [retrunSlipNo, setRetrunSlipNo] = useState("");
  const [types, setTypes] = useState("");
  const [retrunSlipDate, setRetrunSlipDate] = useState("");
  const [retrunSlipNoErr, setRetrunSlipNoErr] = useState(false);
  const [WareHouseErr, setWareHouseErr] = useState(false);
  const [typesErr, setTypesErr] = useState(false);
  const [retrunSlipDateErr, setRetrunSlipDateErr] = useState(false);
  const [materialIssueNoErr, setMaterialIssueNoErr] = useState(false);
  const [ProjectToErr, setProjectToErr] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const poPrintHandler = () => {
    navigate("/ism/output", {
      state: { id: successMessage, type: "internal" },
    });
  };

  const getProject = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };

  useEffect(() => {
    getProject();
  }, []);

  const statusModalClose = () => {
    setShowStatus(!showStatus);
    setTimeout(() => window.location.reload(), 2000);
  };

  // console.log("materialList", materialList);

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <InternalReceiptForm
        setMatertialList={setMatertialList}
        projectList={projectList}
        setWareHouse={setWareHouse}
        WareHouse={WareHouse}
        WareHouseErr={WareHouseErr}
        ProjectTo={ProjectTo}
        setProjectTo={setProjectTo}
        materialIssueNo={materialIssueNo}
        setMaterialIssueNo={setMaterialIssueNo}
        materialIssueDate={materialIssueDate}
        setMaterialIssueDate={setMaterialIssueDate}
        retrunSlipNo={retrunSlipNo}
        setRetrunSlipNo={setRetrunSlipNo}
        setTypes={setTypes}
        types={types}
        setRetrunSlipDate={setRetrunSlipDate}
        retrunSlipDate={retrunSlipDate}
        retrunSlipNoErr={retrunSlipNoErr}
        typesErr={typesErr}
        retrunSlipDateErr={retrunSlipDateErr}
        materialIssueNoErr={materialIssueNoErr}
        ProjectToErr={ProjectToErr}
        setTableSel={setTableSel}
      />
      {materialList?.material_issue_detail?.length > 0 && (
        <InternalReceiptTable
          setTableSel={setTableSel}
          tableSel={tableSel}
          materialList={materialList}
        />
      )}
      <InternalMaterialRemarks
        user={user?.name}
        setModal={setShowStatus}
        types={types}
        setTypesErr={setTypesErr}
        retrunSlipNo={retrunSlipNo}
        setRetrunSlipNoErr={setRetrunSlipNoErr}
        materialIssueDate={materialIssueDate}
        setMaterialIssueNoErr={setMaterialIssueNoErr}
        materialIssueNo={materialIssueNo}
        ProjectTo={ProjectTo}
        setProjectToErr={setProjectToErr}
        retrunSlipDate={retrunSlipDate}
        setRetrunSlipDateErr={setRetrunSlipDateErr}
        WareHouse={WareHouse}
        setWareHouseErr={setWareHouseErr}
        tableSel={tableSel}
        setSuccessMessage={setSuccessMessage}
      />
      {/* <Modal show={modal} centered onHide={() => setModal(!modal)}>
        <Modal.Header closeButton className="border-0">
          <label className="w-50 mx-auto my-1 py-1">
            Internal Return : {successMessage ? successMessage : "-"}
          </label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <p className="text-center fs-6">Do you want to Print the Output?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-primary"
            onClick={poPrintHandler}
            variant="primary"
          >
            Print
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setModal(!modal);
              window.location.reload();
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal> */}
      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
          statusMessage={successMessage}
        />
      )}
    </Col>
  );
};

export default InternalReceiptScreen;
