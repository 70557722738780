import React, { useEffect, useState } from "react";
import MrMaterialTable from "../Execution/MrMaterialTable";
import MrBoqTable from "../Execution/MrBoqTable";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";
import MaterialRequestAmendmentTableSection from "../../component/MaterialRequestAmendment/MaterialRequestAmendmentTableSection";
import MaterialRequestAmendForm from "../../component/MaterialRequestAmendment/MaterialRequestAmendmentForm";

const MaterialRequestAmendmentScreen = () => {
  const [projetList, setProjectList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [finalDetails, setFinalDetails] = useState({
    Project_id: "",
    Head_office: "Crescon Projects and Service Pvt Ltd",
    Request_type: "",
    material_type: "",
    mr_no: "",
    mr_date: "",
    Parent_id: "",
  });
  const [saveDataArray, setSaveDataArray] = useState([
    {
      material_code: "",
      boq_code: "",
      required_quantity: "",
      remarks: "",
    },
  ]);
  // const [requestionType, setRequestionType] = useState("");
  const [currentTableDetails, setCurrentTableDetails] = useState([]);
  // console.log("tes", currentTableDetails);
  const [currentTable, setCurrentTable] = useState("mr-form");

  useEffect(() => {
    // console.log(finalDetails);
    if (currentPage) {
      api
        .material_request_through_boq_by_page(
          finalDetails?.Project_id,
          currentPage
        )
        .then((res) => {
          console.log("res-pageination-boq", res);
          if (res?.status == "success") {
            setCurrentTableDetails(res?.boq_details);
          }
        });
    }
  }, [currentPage]);

  useEffect(() => {
    api.project_list().then((res) => {
      if (res?.status === "success") {
        setProjectList(res?.projects);
      }
    });
  }, []);

  let currentTableDisplay;

  switch (currentTable) {
    case "material":
      currentTableDisplay = (
        <MrMaterialTable
          material_details={currentTableDetails}
          setCurrentTableDetails={setCurrentTableDetails}
          setCurrentTable={setCurrentTable}
        />
      );
      break;
    case "boq":
      currentTableDisplay = (
        <MrBoqTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          boq_details={currentTableDetails}
          currentTableDetails={currentTableDetails}
          setCurrentTableDetails={setCurrentTableDetails}
          setCurrentTable={setCurrentTable}
        />
      );
      break;
    case "final":
      currentTableDisplay = (
        <MaterialRequestAmendmentTableSection
          finalDetails={finalDetails}
          currentTableDetails={currentTableDetails}
          setCurrentTableDetails={setCurrentTableDetails}
        />
      );

      break;
    case "mr-form":
      currentTableDisplay = (
        <MaterialRequestAmendForm
          finalDetails={finalDetails}
          setFinalDetails={setFinalDetails}
          projetList={projetList}
          setCurrentTable={setCurrentTable}
          setCurrentTableDetails={setCurrentTableDetails}
        />
      );

      break;
    default:
      currentTableDisplay = (
        <MaterialRequestAmendForm
          setFinalDetails={setFinalDetails}
          projetList={projetList}
          setCurrentTable={setCurrentTable}
          setCurrentTableDetails={setCurrentTableDetails}
        />
      );
      break;
  }
  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header  />
      {currentTableDisplay}
    </Col>
  );
};

export default MaterialRequestAmendmentScreen;
