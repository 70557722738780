import React, { useEffect, useState } from "react";
import MrMaterialTable from "../Execution/MrMaterialTable";
import MrBoqTable from "../Execution/MrBoqTable";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
import Header from "../../component/Header/Header";
import MaterialRequestForm from "../../component/MaterialRequest/MaterialRequestForm";
import MaterialRequestTableSection from "../../component/MaterialRequest/MaterialRequestTableSection";

const MaterialRequestScreen = () => {
  const [projetList, setProjectList] = useState([]);
  const [projectName, setProjectName] = useState();
  const [projectID, setProjectID] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [tablePage, setTablePage] = useState(false);
  const [directPo, setDirectPo] = useState({
    boq_code: [],
    m_code: [],
  });
  const [finalDetails, setFinalDetails] = useState({
    Project_id: "",
    Head_office: "",
    Request_type: "",
    material_type: "",
    mr_date: "",
    Parent_id: "",
    department_id: "",
  });

  const [saveDataArray, setSaveDataArray] = useState([
    {
      material_code: "",
      boq_code: "",
      required_quantity: "",
      remarks: "",
    },
  ]);
  const [requestionType, setRequestionType] = useState("");
  const [materialType, setMaterialType] = useState("");
  const [currentTableDetails, setCurrentTableDetails] = useState([]);
  const [currentTable, setCurrentTable] = useState("mr-form");
  const [pagination, setPagination] = useState();

  useEffect(() => {
    if (tablePage && currentPage) {
      api
        .material_request_through_boq_by_page(
          finalDetails?.Project_id,
          currentPage
        )
        .then((res) => {
          // console.log("res-pageination-boq", res);
          if (res?.status == "success") {
            let temp = [];
            res?.boq_details?.map((item, ind) => {
              temp.push({
                ...item,
                error: 0,
              });
            });
            setCurrentTableDetails(temp);
            // setCurrentTableDetails(res?.boq_details);
            setPagination(res?.pagination);
          }
        });
    }
  }, [currentPage]);

  useEffect(() => {
    api.project_list().then((res) => {
      if (res?.status === "success") {
        setProjectList(res?.projects);
      }
    });
  }, []);

  useEffect(() => {
    api.material_request_project_list().then((res) => {
      if (res?.status == "success") {
        // console.log(res);
        setProjectName(res?.projects);
      }
    });
  }, []);

  let currentTableDisplay;

  switch (currentTable) {
    case "material":
      currentTableDisplay = (
        <MrMaterialTable
          projectName={projectName}
          directPo={directPo}
          finalDetails={finalDetails}
          materialType={materialType}
          projectID={projectID}
        />
      );
      break;
    case "boq":
      currentTableDisplay = (
        <MrBoqTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          boq_details={currentTableDetails}
          currentTableDetails={currentTableDetails}
          setCurrentTableDetails={setCurrentTableDetails}
          setCurrentTable={setCurrentTable}
          pagination={pagination}
          projectID={projectID}
        />
      );
      break;
    case "final":
      currentTableDisplay = (
        <MaterialRequestTableSection
          finalDetails={finalDetails}
          currentTableDetails={currentTableDetails}
          setCurrentTableDetails={setCurrentTableDetails}
        />
      );

      break;
    case "mr-form":
      currentTableDisplay = (
        <MaterialRequestForm
          projectNames={projectName}
          materialType={materialType}
          setDirectPo={setDirectPo}
          setMaterialType={setMaterialType}
          setFinalDetails={setFinalDetails}
          projetList={projetList}
          setCurrentTable={setCurrentTable}
          setTablePage={setTablePage}
          setCurrentTableDetails={setCurrentTableDetails}
          setProjectID={setProjectID}
        />
      );

      break;
    default:
      currentTableDisplay = (
        <MaterialRequestForm
          setFinalDetails={setFinalDetails}
          projetList={projetList}
          setTablePage={setTablePage}
          setCurrentTable={setCurrentTable}
          setCurrentTableDetails={setCurrentTableDetails}
          setProjectID={setProjectID}
        />
      );
      break;
  }

  return (
    <Col xs={12} className="py-2 px-1 p-md-3 ">
      <Header />
      {currentTableDisplay}
    </Col>
  );
};

export default MaterialRequestScreen;
