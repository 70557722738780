import { Col } from "react-bootstrap";
function ProjectCreationForm({ projectForm, setProjectForm, formError }) {
  return (
    <Col className="emply-master-form px-4 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="ms-xl-0 w-95">Project Creation</h4>
      <Col className="d-flex flex-wrap mx-lg-3">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          <p
            style={{ minHeight: "2rem", width: "90%", marginRight: "auto" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1">
            Crescon Projects and Service Pvt Ltd
          </p>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Project Name"
            value={projectForm.name}
            onChange={(e) =>
              setProjectForm({
                ...projectForm,
                name: e.target.value,
              })
            }
          />
          {formError.includes("name") && (
            <p className="err"> Please Enter Project Name</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project No</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Project No"
            value={projectForm.project_no}
            onChange={(e) =>
              setProjectForm({
                ...projectForm,
                project_no: e.target.value,
              })
            }
          />
          {formError.includes("project_no") && (
            <p className="err"> Please Enter Project Number</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Date</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Project Date"
            type="date"
            value={projectForm.date}
            onChange={(e) =>
              setProjectForm({
                ...projectForm,
                date: e.target.value,
              })
            }
          />
          {formError.includes("date") && (
            <p className="err"> Please Enter Project Date</p>
          )}
        </Col>
      </Col>
    </Col>
  );
}

export default ProjectCreationForm;
