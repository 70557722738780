import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { logo } from "../../assets/img";
import { api } from "../../constants/Services";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Header from "../../component/Header/Header";
import useUser from "../../constants/Storage/userData";
// import html2PDF from "jspdf-html2canvas";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";

function WorkOrderOutputScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useUser();
  const [poPrintDetails, setPOPrintDetails] = useState([]);
  const [terms, setTerms] = useState([]);
  const [directPoAttributes, setDirectPoAttributes] = useState([]);
  const [poAnnex, setPOAnnex] = useState([]);
  const [numToWords, setNumToWords] = useState("");
  const [poTable, setPoTable] = useState([]);
  const [approval, setapproval] = useState("");
  // poPrintDetails?.material_request?.request_type == 1
  function doConvert(numberInput) {
    let oneToTwenty = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    let tenth = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    let numToWord = numberInput.toString();
    if (numToWord.includes(".")) numToWord = numToWord.split(".")[0];

    let num = ("000000000" + numToWord)
      .slice(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

    if (!num) return;

    let outputText =
      num[1] != 0
        ? (oneToTwenty[Number(num[1])] ||
            `${tenth[num[1][0]]} ${oneToTwenty[num[1][1]]}`) + " crore "
        : "";
    outputText +=
      num[2] != 0
        ? (oneToTwenty[Number(num[2])] ||
            `${tenth[num[2][0]]} ${oneToTwenty[num[2][1]]}`) + " lakh "
        : "";

    outputText +=
      num[3] != 0
        ? (oneToTwenty[Number(num[3])] ||
            `${tenth[num[3][0]]} ${oneToTwenty[num[3][1]]}`) + "thousand "
        : "";
    outputText +=
      num[4] != 0
        ? (oneToTwenty[Number(num[4])] ||
            `${tenth[num[4][0]]} ${oneToTwenty[num[4][1]]}`) + "hundred "
        : "";
    outputText +=
      num[5] != 0
        ? (oneToTwenty[Number(num[5])] ||
            `${tenth[num[5][0]]} ${oneToTwenty[num[5][1]]}`) + " "
        : "";
    // console.log("Result-1 = ", outputText);

    outputText = outputText?.charAt(0).toUpperCase() + outputText?.slice(1);
    // console.log("Result = ", outputText);

    setNumToWords(outputText);
  }

  const getList = (paramId) => {
    api.workOrderView(paramId ? paramId : location.state?.id).then((res) => {
      if (res?.status == "success") {
        setPOPrintDetails(res?.work_order);
        setPoTable(res?.work_order?.purchase_order_details);
        setPOAnnex(res?.work_order?.work_order_annexure);
        let temp = [];

        const totalValue = res?.work_order?.work_order_detail?.reduce(
          (acc, po) => acc + +po?.net_value,
          0
        );

        setapproval(res?.work_order?.approval?.[0]?.employee?.name);
        if (totalValue) {
          doConvert(totalValue);
        }
        if (
          res?.purchase_orders?.terms_conditions &&
          res?.purchase_orders?.terms_conditions.includes(",")
        ) {
          temp = res?.purchase_orders?.terms_conditions.split(",");
        } else {
          res?.purchase_orders?.terms_conditions &&
            temp.push(res?.purchase_orders?.terms_conditions);
        }
        setTerms(temp);
        if (res?.purchase_orders?.material_request?.request_type == 2) {
          let temp_att = [];
          let temp_obj = {};
          res?.purchase_orders?.purchase_order_details?.map((po) => {
            po?.attributes && po?.attributes.includes(",")
              ? temp_att.push(po?.attributes.split(","))
              : temp_att.push(po?.attributes);
            temp_obj[po?.id] = temp_att;
          });

          setDirectPoAttributes(temp_obj);
        }
      }
    });
  };
  useEffect(() => {
    if (searchParams?.get("id")) {
      getList(searchParams?.get("id"));
    } else {
      getList();
    }
  }, [searchParams?.get("id")]);

  const downloadPdfDocument = (div_id) => {
    const divToPrint = document.getElementById(div_id);

    // return false;
    window.scrollTo(0, 0);
    html2canvas(divToPrint).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 190;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      // return false;
      let heightLeft = imgHeight;
      const doc = new JsPDF("pt", "mm");
      let position = 0;
      doc.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight + 24);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight + 24);
        heightLeft -= pageHeight;
      }
      doc.save("PoInvoice.pdf");
    });
  };

  return (
    <Col
      xs={12}
      className="py-2 px-1 p-md-3 w-lg-90"
      style={{ width: "1039px"
        // , height: "1216px" 
      }}
    >
      <Header />
      <div
        // style={{ height: "100vh", overflow: "hidden" }}
        id="po-out-main-cont"
        className="po-main-box my-3 avoid-break"
      >
        <section className="p-3">
          <header className="d-flex ">
            <Col xs={1} className="d-flex my-3 ">
              <img
                className="ms-4"
                style={{ width: "70px", height: "70px" }}
                src={logo}
                alt="logo"
              />
            </Col>
            <Col
              xs={7}
              className="d-flex flex-column po-vendor-detail-invoice-box my-3"
            >
              <h5 className="mx-auto py-1">
                Crescon Projects and Services Pvt Ltd
              </h5>
              <p className="py-1 mx-auto ">
                3rd Floor, Gulecha Towers 158, Arcot Road, Vadapalani Chennai –
                600 026
              </p>
              <h6 className="m-auto py-1">Work Order</h6>
            </Col>
            <Col xs={4} className="my-auto ">
              <p className="py-1 fs-13px">
                ISO 9001:2015 : Quality Management System
              </p>
              <p className="py-1 fs-13px">
                ISO 14001:2015 : Environmental Management System
              </p>
              <p className="py-1 fs-13px">
                ISO 45001:2018 : Occupational Health & Safety Management System
              </p>
            </Col>
          </header>
          <main className="">
            <section className="d-flex">
              <Col xs={6} className="po-vendor-detail-box p-3">
                {/* <h6>To</h6> */}
                <div className="d-flex  py-1 my-1">
                  <Col md={4}>
                    <label>Work Order No.</label>
                  </Col>
                  <Col md={8}>
                    <p>{poPrintDetails?.order_no}</p>
                  </Col>
                </div>
                <div className="d-flex  py-1 my-1">
                  <Col md={4}>
                    <label>Work Date</label>
                  </Col>
                  <Col md={8}>
                    <p>{poPrintDetails?.order_date}</p>
                  </Col>
                </div>
                <div className="d-flex  py-1 my-1">
                  <Col md={4}>
                    <label>Quote Ref No.</label>
                  </Col>
                  <Col md={8}>
                    <p>{poPrintDetails?.quote_ref_no}</p>
                  </Col>
                </div>
                <div className="d-flex  py-1 my-1">
                  <Col md={4}>
                    <label>Project Name</label>
                  </Col>
                  <Col md={8}>
                    <p>{poPrintDetails?.project?.name}</p>
                  </Col>
                </div>
                <div className="d-flex  my-1 py-1">
                  <Col md={4}>
                    <label>Project Address</label>
                  </Col>
                  <Col md={8}>
                    <p>{`${poPrintDetails?.project?.door_no} , ${poPrintDetails?.project?.address}`}</p>
                  </Col>
                </div>
                <div className="d-flex  py-1 my-1">
                  <Col md={4}>
                    <label>Project Mobile No.</label>
                  </Col>
                  <Col md={8}>
                    <p>{poPrintDetails?.project?.mobile}</p>
                  </Col>
                </div>
                <div className="d-flex  py-1 my-1">
                  <Col md={4}>
                    <label>Project Email</label>
                  </Col>
                  <Col md={8}>
                    <p>{poPrintDetails?.project?.email}</p>
                  </Col>
                </div>
              </Col>
              <Col xs={6} className="po-vendor-detail-invoice-box p-3">
                <div className="d-flex  py-1 my-1">
                  <Col md={4}>
                    <label>Start Date</label>
                  </Col>
                  <Col md={8}>
                    <p>{poPrintDetails?.start_date}</p>
                  </Col>
                </div>
                <div className="d-flex  py-1 my-1">
                  <Col md={4}>
                    <label>End Date</label>
                  </Col>
                  <Col md={8}>
                    <p>{poPrintDetails?.end_date}</p>
                  </Col>
                </div>
                <div className="d-flex  py-1 my-1">
                  <Col md={4}>
                    <label>Vendor Name</label>
                  </Col>
                  <Col md={8}>
                    <p>{poPrintDetails?.vendor?.company_name}</p>
                  </Col>
                </div>
                <div className="d-flex  my-1 py-1">
                  <Col md={4}>
                    <label>Vendor Address</label>
                  </Col>
                  <Col md={8}>
                    <p>{`${poPrintDetails?.vendor?.door_no} , ${poPrintDetails?.vendor?.address}`}</p>
                  </Col>
                </div>
                <div className="d-flex  py-1 my-1">
                  <Col md={4}>
                    <label>Vendor Mobile No.</label>
                  </Col>
                  <Col md={8}>
                    <p>{poPrintDetails?.vendor?.mobile ?? "-"}</p>
                  </Col>
                </div>
                <div className="d-flex ">
                  <Col md={4}>
                    <label>Vendor Email ID</label>
                  </Col>

                  <Col md={8}>
                    <p>{poPrintDetails?.vendor?.email}</p>
                  </Col>
                </div>
                <div className="d-flex ">
                  <Col md={4}>
                    <label>GST No.</label>
                  </Col>

                  <Col md={8}>
                    <p>{poPrintDetails?.vendor?.gst_no ?? "-"}</p>
                  </Col>
                </div>
              </Col>
            </section>
            <section className="d-flex">
              <Col
                style={{ borderRight: "1px solid black" }}
                xs={6}
                className="po-vendor-detail-invoice-box p-3"
              >
                <h6>Invoicing Address:</h6>

                <div className="d-flex flex-wrap">
                  <Col md={4}>
                    <label>Head Office</label>
                  </Col>
                  <Col md={8}>
                    <p>Crescon Projects and Services Pvt Ltd</p>
                  </Col>
                  <Col md={4}>
                    <label>Address</label>
                  </Col>
                  <Col md={8}>
                    <p>
                      3rd Floor, Gulecha Towers 158, Arcot Road, Vadapalani
                      Chennai – 600 026
                    </p>
                  </Col>

                  <Col md={4} className="mt-4">
                    <label>Contact Person</label>
                  </Col>
                  <Col md={8} className="mt-4">
                    <p>M. Janakiraman</p>
                  </Col>
                  <Col md={4}>
                    <label>Contact No.</label>
                  </Col>
                  <Col md={8}>
                    <p>044 - 23664945</p>
                  </Col>
                  <Col md={4}>
                    <label>Mail id</label>
                  </Col>
                  <Col md={8}>
                    <p>purchase@cresconprojects.com</p>
                  </Col>
                  <Col md={4}>
                    <label>GST No.</label>
                  </Col>
                  <Col md={8}>
                    <p>33AAECC6175F1ZT</p>
                  </Col>
                </div>
              </Col>
              <Col xs={6} className="po-vendor-detail-invoice-box p-3">
                <h6>Delivery Address:</h6>
                <div className="d-flex">
                  <Col xs={4}>
                    <label>Project Name</label>
                  </Col>
                  <Col xs={8}>
                    <p>{poPrintDetails?.project?.name ?? "-"}</p>
                  </Col>
                </div>
                <div className="d-flex">
                  <Col xs={4}>
                    <label>Project Address</label>
                  </Col>
                  <Col xs={8}>
                    <p>
                      {`${poPrintDetails?.project?.door_no}, ${poPrintDetails?.project?.address}` ??
                        "-"}
                    </p>
                  </Col>
                </div>
                <div className="d-flex">
                  <Col xs={4}>
                    <label>State</label>
                  </Col>
                  <Col xs={8}>
                    <p>{poPrintDetails?.project?.state ?? "-"}</p>
                  </Col>
                </div>
                <div className="d-flex">
                  <Col xs={4}>
                    <label>District</label>
                  </Col>
                  <Col xs={8}>
                    <p>{poPrintDetails?.project?.district ?? "-"}</p>
                  </Col>
                </div>
                <div className="d-flex">
                  <Col xs={4}>
                    <label>Pincode</label>
                  </Col>

                  <Col xs={8}>
                    <p>{poPrintDetails?.project?.pincode ?? "-"}</p>
                  </Col>
                </div>
                <div className="d-flex">
                  <Col xs={4}>
                    <label>Contact No.</label>
                  </Col>
                  <Col xs={8}>
                    <p>{poPrintDetails?.project?.mobile ?? "-"}</p>
                  </Col>
                </div>
                <div className="d-flex">
                  <Col xs={4}>
                    <label>Mail id</label>
                  </Col>
                  <Col xs={8}>
                    <p>{poPrintDetails?.project?.email ?? "-"}</p>
                  </Col>
                </div>
              </Col>
            </section>
            <Col className="p-2  m-1 text-center">
              <div
                style={{ overflowX: "scroll" }}
                className="po-out-table bg-white"
              >
                <table
                  id="po-output-table"
                  className="table table-responsive-xl"
                >
                  <thead>
                    <>
                      <tr>
                        <th>Sl.No</th>
                        <th>BOQ Code</th>
                        <th>Work Description</th>
                        <th>UOM</th>
                        <th>Quantity</th>
                        <th>Basic Rate </th>
                        <th>Total Value</th>
                        <th>HSN Code</th>
                        <th>Net Value</th>
                        <th>Remarks</th>
                      </tr>
                    </>
                  </thead>

                  <tbody>
                    {poPrintDetails?.work_order_detail?.map((item, ind) => {
                      return (
                        <>
                          <tr key={item?.id}>
                            <td>{ind + 1}</td>
                            <td>{item?.boq_detail?.boq_code}</td>
                            <td>{item?.description}</td>
                            <td>{item?.boq_detail?.uom}</td>
                            <td>{item?.qty}</td>
                            <td>{item?.basic_price}</td>
                            <td>{item?.total_value}</td>
                            <td> {item?.misc ?? "-"}</td>
                            <td>{item?.net_value}</td>
                            <td>{item?.remarks}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Col>

            {/* <div className="p-2  m-1 text-center">
              <div
                style={{ overflowX: "scroll" }}
                className="po-out-table bg-white"
              >
                <table
                  id="po-output-table"
                  className="table table-responsive-xl"
                >
                  <thead>
                    <tr>
                      {poTable[0]?.basic_price > 0 && (
                        <th>Basic Rate (INR) </th>
                      )}
                      {poTable[0]?.discount_value > 0 && <th>Disc</th>}
                      {poTable[0]?.cgst_value > 0 && <th>CGST</th>}
                      {poTable[0]?.igst_value > 0 && <th>IGST</th>}
                      {poTable[0]?.sgst_value > 0 && <th>SGST</th>}
                      {poTable[0]?.net_value > 0 && <th>Total Value</th>}
                    </tr>
                  </thead>
                  {poTable?.length > 0 ? (
                    <tbody>
                      <tr>
                        {poTable[0]?.basic_price > 0 && (
                          <td>
                            {poTable.reduce((accumulator, object) => {
                              return (
                                Number(accumulator) + Number(object.basic_price)
                              );
                            }, 0)}
                          </td>
                        )}

                        {poTable[0]?.discount_value > 0 && (
                          <td>
                            {poTable.reduce((accumulator, object) => {
                              return accumulator + object.discount_value;
                            }, 0)}
                          </td>
                        )}

                        {poTable[0]?.cgst_value > 0 && (
                          <td>
                            {poTable.reduce((accumulator, object) => {
                              return (
                                Number(accumulator) + Number(object.cgst_value)
                              );
                            }, 0)}
                          </td>
                        )}

                        {poTable[0]?.igst_value > 0 && (
                          <td>
                            {poTable.reduce((accumulator, object) => {
                              return (
                                Number(accumulator) + Number(object.igst_value)
                              );
                            }, 0)}
                          </td>
                        )}

                        {poTable[0]?.sgst_value > 0 && (
                          <td>
                            {poTable.reduce((accumulator, object) => {
                              return (
                                Number(accumulator) + Number(object.sgst_value)
                              );
                            }, 0)}
                          </td>
                        )}

                        {poTable[0]?.net_value > 0 && (
                          <td>
                            {poTable.reduce((accumulator, object) => {
                              return (
                                Number(accumulator) + Number(object.net_value)
                              );
                            }, 0)}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        {poTable[0]?.basic_price > 0 && (
                          <td>{poTable[0]?.basic_price}</td>
                        )}
                        {poTable[0]?.discount_value > 0 && (
                          <td>{poTable[0]?.discount_value}</td>
                        )}
                        {poTable[0]?.cgst_value > 0 && (
                          <td>{poTable[0]?.cgst_value}</td>
                        )}
                        {poTable[0]?.igst_value > 0 && (
                          <td>{poTable[0]?.igst_value}</td>
                        )}
                        {poTable[0]?.sgst_value > 0 && (
                          <td>{poTable[0]?.sgst_value}</td>
                        )}
                        {poTable[0]?.net_value > 0 && (
                          <td>{poTable[0]?.net_value}</td>
                        )}
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div> */}

            <div className="d-flex gap-2 m-3 page_break_before_after">
              <label>Amount In Words</label>
              <p className="mt-1">
                ({numToWords ? `Rupees ${numToWords} Only` : "-"})
              </p>
            </div>
            <section
              className="po-terms-box page_break_before_after "
              style={{ borderBottom: "none" }}
            >
              <div className="d-flex">
                <Col md={6} lg={6} sm={6}>
                  <div className="d-flex gap-2 align-items-center">
                    <label>Name</label>
                    <p>{poPrintDetails?.name_1}</p>
                  </div>
                </Col>
                <Col md={6} lg={6} sm={6}>
                  <div className="d-flex gap-2 align-items-center">
                    <label>Phone No.</label>
                    <p>{poPrintDetails?.phone1}</p>
                  </div>
                </Col>
              </div>
              <div className="d-flex mt-2">
                <Col md={6} lg={6} sm={6}>
                  <div className="d-flex gap-2 align-items-center">
                    <label>Name</label>
                    <p>{poPrintDetails?.name_2}</p>
                  </div>
                </Col>
                <Col md={6} lg={6} sm={6}>
                  <div className="d-flex gap-2 align-items-center">
                    <label>Phone No.</label>
                    <p>{poPrintDetails?.phone2}</p>
                  </div>
                </Col>
              </div>
            </section>

            <section className="po-terms-box page_break_before_after">
              <h6>Terms And Conditions</h6>
              <div>
                <ul className="" style={{wordWrap:"break-word"}}>{poPrintDetails?.terms}</ul>
              </div>
            </section>

            <section>
              {poAnnex?.length > 0 && (
                <div className="page_break_before_after">
                  <h6 className="px-2 pt-2 m-1">Annexure Details</h6>
                  <Col className="emply-master-form px-2  m-1 text-center">
                    <div
                      style={{ overflowX: "scroll" }}
                      className="boq-table bg-white"
                    >
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th className="">Sl.No.</th>
                            <th className="">Description</th>
                            <th className="">Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {poAnnex &&
                            poAnnex?.map((annex, ind) => (
                              <tr key={annex?.id}>
                                <td>{ind + 1}</td>
                                <td>{annex?.description}</td>
                                <td>{annex?.quantity}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </div>
              )}

              <div className="d-flex justify-content-between w-90 page_break_before_after">
                <div>
                  <p className="text-capitalize">
                    {poPrintDetails?.employee?.name}
                  </p>
                  <label>Prepared</label>
                </div>
                <div>
                  <p className="text-capitalize">{approval ? approval : "-"}</p>
                  <label>Approved</label>
                </div>
              </div>
            </section>
          </main>
          <footer id="page-break" className="d-flex">
            <Col
              xs={6}
              style={{ borderRight: "1px solid black", minHeight: "6rem" }}
              className="d-flex flex-column"
            >
              <p className="">
                Please Sign and Return Duplicate Copy as a Token of Acceptance
              </p>
              <p className="mt-auto mb-1">Vendor Acceptance</p>
            </Col>
            <Col xs={6} className="d-flex flex-column">
              <p className="ms-auto me-2 pe-3">
                For Crescon Projects and Services Pvt Ltd
              </p>
              <p className="mt-auto ms-auto me-4 mb-1">Authorised Signatory</p>
            </Col>
          </footer>
        </section>
      </div>{" "}
      <div className="d-flex justify-content-between">
        <div>
          {poPrintDetails?.status == 2 && (
            <button
              class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
              onClick={() => downloadPdfDocument("po-out-main-cont")}
            >
              Print
            </button>
          )}
        </div>
        <div className="">
          {searchParams?.get("id") ? (
            <Link
              class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2 "
              to={`/procurement/puchase-order-list?projectname=${searchParams?.get(
                "projectname"
              )}&project=${searchParams?.get("project")}`}
            >
              Close
            </Link>
          ) : (
            <button
              class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
              onClick={() => navigate(-1)}
            >
              Close
            </button>
          )}
        </div>
      </div>
    </Col>
  );
}

export default WorkOrderOutputScreen;
