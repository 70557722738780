// @ts-nocheck
import React, { useEffect, useState } from "react";
import { api } from "../../constants/Services";
import { Navigate, useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import StatusModal from "../../component/StatusModal";

// import { boq_data } from "../../constants/StaticData";

const MaterialTable = ({
  directPo,
  materialType,
  finalDetails,
  projectName,
  projectID,
}) => {
  const naviagate = useNavigate();
  const [BCode, setBCode] = useState([]);
  const [mCode, setMCode] = useState([]);
  const [mName, setMName] = useState([]);
  const [checkboxs, setcheckboxs] = useState([]);
  const [matDetail, setMatDetail] = useState();
  const [matDetailPre, setMatDetailPre] = useState();
  const [showStatus, setShowStatus] = useState(false);
  const [btn, setBtn] = useState(true);
  const [boqDetail, setBoqDetail] = useState();
  const [quan, setQuan] = useState(false);
  const [dropDown, setDropDown] = useState({
    boq: "",
    m_code: "",
    m_name: "",
  });
  const [filter, setFilter] = useState({
    boq_code: "",
    m_code: "",
    m_name: "",
  });
  const [allowRow, setAllowRow] = useState(1);
  const [rows, setRows] = useState([1]);
  const [mrTableFields, setMrTableFields] = useState({
    qty: {},
    remarks: {},
  });
  const [statusMessage, setStatusMessage] = useState("");
  const statusModalClose = (message) => {
    setShowStatus(!showStatus);
    setStatusMessage(message);
    setTimeout(() => naviagate("/notification"), 2000);
  };

  // console.log("directPo", directPo);

  const dropHandler = (id, sel_drop) => {
    console.log("id", id);
    console.log("sel_drop", sel_drop);
    if (sel_drop == "boq") {
      dropDown?.boq == id
        ? setDropDown({ ...dropDown, boq: "" })
        : setDropDown({ ...dropDown, boq: id });
    } else if (sel_drop == "m_code") {
      dropDown?.m_code == id
        ? setDropDown({ ...dropDown, m_code: "" })
        : setDropDown({ ...dropDown, m_code: id });
    } else {
      dropDown?.m_name == id
        ? setDropDown({ ...dropDown, m_name: "" })
        : setDropDown({ ...dropDown, m_name: id });
    }
  };

  useEffect(() => {
    let temp = new Array(+allowRow);
    temp.fill(1);
    setRows(temp);
  }, [allowRow]);

  useEffect(() => {
    setBCode(directPo?.boq_code);
    setMCode(directPo?.m_code);
  }, [directPo]);

  // console.log("mCode", mCode);

  useEffect(() => {
    const filtered_boq = directPo?.boq_code?.filter((boq) =>
      boq.toLocaleLowerCase().includes(filter?.boq_code.toLocaleLowerCase())
    );
    setBCode(filtered_boq);
    const filtered_m_code = Object.values(directPo?.m_code)?.filter((m_code) =>
      m_code.toLocaleLowerCase().includes(filter?.m_code.toLocaleLowerCase())
    );
    setMCode(filtered_m_code);
    const filtered_m_name = Object.entries(directPo?.m_code)?.filter(
      (m_name) => {
        const [key, value] = m_name;
        return key
          .toLocaleLowerCase()
          .includes(filter?.m_name.toLocaleLowerCase());
      }
    );
    setMName(filtered_m_name);
  }, [filter]);

  const getBoqDetails = (boq_code, drop_id) => {
    // console.log("boq_code", boq_code);
    let code = boq_code.trim();
    // console.log("code", code);

    api.boq_code_view(finalDetails?.Project_id, code).then((res) => {
      if (res?.status == "success") {
        setBoqDetail({ ...boqDetail, [drop_id]: res?.boq_code_detail });
        setDropDown({ ...dropDown, boq: "" });
        let temp = [...checkboxs];
        temp.push(code);
        setcheckboxs(temp);
        setFilter({
          ...filter,
          boq_code: "",
        });
      }
    });
  };

  const selectBox = (event, ind) => {
    let temps = [...checkboxs];
    let temp = [];
    temps.map((item) => temp.push(item?.index));
    const value = event;

    const index = temps.indexOf(event);
    if (temp.includes(value.index)) {
      temps.splice(index, 1);
    } else {
      temps.push(value);
    }
    setcheckboxs(temps);
  };

  // console.log("checkboxs", checkboxs);

  const quantityHandler = (index, val) => {
    setQuan(true);
    let onlyNumber = /^[0-9\_]+$/;
    let value;
    if (onlyNumber.test(val)) {
      value = val;
    }
    setMrTableFields({
      ...mrTableFields,
      qty: {
        ...mrTableFields?.qty,
        [index]: value,
      },
    });
  };

  const material_code_view = (m_code, drop_id) => {
    // api.material_code_view(m_code).then((res) => {
    api.material_code_views(m_code, projectID).then((res) => {
      if (res?.status == "success") {
        setMatDetail({ ...matDetail, [drop_id]: res?.material });
        setMatDetailPre({ ...matDetailPre, [drop_id]: res });
        setDropDown({ ...dropDown, m_code: "", m_name: "" });
        // indexedDB.open('name', vesion)
        if (mCode && mName) {
          const filtered_material_code = mCode?.filter(
            (boq) => boq != res?.material?.material_code
          );
          const filtered_material_name = mName?.filter(
            (boq) => boq != res?.material?.name
          );
          setMCode(filtered_material_code);
          setMName(filtered_material_name);
          setFilter({
            ...filter,
            m_code: "",
            m_name: "",
          });
        }
      }
    });
  };

  const directPoSubmitHandler = () => {
    let formdata = new FormData();

    // rows?.map((item, index) => {
    // formdata.append(
    //   `details[${index}][boq_code]`,
    //   boqDetail[`${index}_boq`]?.boq_code ?? ""
    // );
    // formdata.append(
    //   `details[${index}][material_id]`,
    //   matDetail[`${index}_material`]?.id ?? ""
    // );
    // formdata.append(
    //   `details[${index}][required_quantity]`,
    //   mrTableFields?.qty[index] ?? 0
    // );
    // formdata.append(
    //   `details[${index}][remarks]`,
    //   mrTableFields?.remarks[index] ?? ""
    // );
    // });

    let count = 1;

    checkboxs?.map((item, index) => {
      if (+item?.mrTableFields_qty == 0) {
        count = count + 1;
      }

      if (item == boqDetail[`${index}_boq`]?.boq_code) {
        formdata.append(
          `details[${index}][boq_code]`,
          boqDetail[`${index}_boq`]?.boq_code
            ? boqDetail[`${index}_boq`]?.boq_code
            : " "
        );

        formdata.append(
          `details[${index}][material_id]`,
          matDetail[`${index}_material`]?.id
            ? matDetail[`${index}_material`]?.id
            : " "
        );
        formdata.append(
          `details[${index}][required_quantity]`,
          mrTableFields?.qty[index] ? mrTableFields?.qty[index] : " "
        );
        formdata.append(
          `details[${index}][remarks]`,
          mrTableFields?.remarks[index] ? mrTableFields?.remarks[index] : " "
        );
      }
    });

    formdata.append("project_id", +finalDetails?.Project_id);
    formdata.append("head_office", finalDetails?.Head_office);
    formdata.append("request_type", +finalDetails?.Request_type);
    formdata.append(" mr_date", finalDetails?.mr_date);
    formdata.append(" material_type", finalDetails?.material_type);
    formdata.append("department", finalDetails?.department_id);

    // if (boqDetail && matDetail) {
    if (boqDetail && count == 1) {
      api.material_request_store(formdata).then((res) => {
        if (res?.status == "success") {
          statusModalClose(`MR Number :${res?.mr_no}`);
        }
      });
    } else {
      alert("Missing Fields");
    }
  };

  useEffect(() => {
    if (matDetail && boqDetail) {
      setBtn(false);
    } else {
      setBtn(true);
    }
  }, [matDetail, boqDetail]);

  // console.log('mrTableFields?.qty[index] ?? ""', mrTableFields?.qty[0] ?? "");

  console.log("checkboxs", checkboxs);

  const qtyVal = mrTableFields?.qty[0] ?? "";
  return (
    <Col xs={10} className="emply-master-form py-2  mt-3 text-center">
      <Col xs={11} className="d-flex">
        <button
          className="bg-primar border-0 rounded-1 my-1 text-white f-1 fs-14 px-4 py-2 ms-auto"
          onClick={() => setAllowRow(+allowRow + 1)}
        >
          Add
        </button>
      </Col>
      <div style={{ overflowX: "scroll" }} className="boq-table bg-white">
        <table className="table">
          <thead>
            <tr  className=" text-center">
              <th>Sl.No</th>
              <th className="nowrap">Boq Code</th>
              <th  className="nowrap" style={{ minWidth: "500px" }} className="">
                Boq Description
              </th>
              <th className="nowrap">Material Type</th>
              <th className="nowrap">Material Code</th>
              <th  className="nowrap" style={{ minWidth: "230px" }}>Material Name</th>
              <th className="nowrap">UOM</th>
              <th className="nowrap">Previous Quantity</th>
              <th className="nowrap">Raised Qty</th>
              <th className="nowrap">Quantity</th>
              <th className="nowrap">Remarks</th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((item, index) => {
              return (
                index <= allowRow && (
                  <tr key={index}>
                    <td>
                      {index + 1}{" "}
                      {boqDetail && boqDetail[`${index}_boq`] ? (
                        // && mrTableFields?.qty[index]
                        <input
                          type={"checkbox"}
                          checked={checkboxs?.includes(
                            boqDetail[`${index}_boq`]?.boq_code
                          )}
                          onClick={() =>
                            selectBox(
                              // {
                              //   index: index,
                              //   boq_code: boqDetail[`${index}_boq`]?.boq_code,
                              //   id: matDetail
                              //     ? matDetail[`${index}_material`]?.id
                              //     : "",
                              //   mrTableFields_qty: mrTableFields?.qty[index],
                              //   mrTableFields_remarks:
                              //     mrTableFields?.remarks[index] ?? "",
                              // },
                              boqDetail[`${index}_boq`]?.boq_code,
                              index
                            )
                          }
                        />
                      ) : (
                        <input type={"checkbox"} disabled />
                      )}
                    </td>
                    <td>
                      {/* {boqDetail && boqDetail[`${index}_boq`] ? (
                        <p>{boqDetail[`${index}_boq`]?.boq_code}</p>
                      ) : (
                        <p
                          style={{ minWidth: "110px" }}
                          className="pointer"
                          onClick={() => dropHandler(`${index}_boq`, "boq")}
                        >
                          Select BOQ
                        </p>
                      )} */}

                      <p
                        style={{ minWidth: "110px" }}
                        className="pointer"
                        onClick={() => dropHandler(`${index}_boq`, "boq")}
                      >
                        {boqDetail && boqDetail[`${index}_boq`]
                          ? boqDetail[`${index}_boq`]?.boq_code
                          : "Select BOQ"}
                      </p>

                      {dropDown?.boq == `${index}_boq` && (
                        <ul className="drop-cont">
                          <li>
                            <input
                              className="border-0 bg-lightBlue px-2 py-1 mt-1"
                              value={filter?.boq_code}
                              onChange={(e) =>
                                setFilter({
                                  ...filter,
                                  boq_code: e.target.value,
                                })
                              }
                            />
                          </li>
                          {BCode?.map(
                            (boq_code, ind) =>
                              ind < 5 && (
                                <li
                                  key={ind}
                                  onClick={() =>
                                    getBoqDetails(boq_code, `${index}_boq`)
                                  }
                                >
                                  {boq_code}
                                </li>
                              )
                          )}
                        </ul>
                      )}
                    </td>
                    <td  className=" text-center">
                      {boqDetail && boqDetail[`${index}_boq`]?.boq_description}
                    </td>
                    <td>{materialType}</td>
                    <td  className=" text-center">
                      {/* {matDetail && matDetail[`${index}_material`] ? (
                        <p>{matDetail[`${index}_material`]?.material_code}</p>
                      ) : (
                        <p
                          style={{ minWidth: "150px" }}
                          className="pointer"
                          onClick={() =>
                            boqDetail &&
                            dropHandler(`${index}_m_code`, "m_code")
                          }>
                          Select Material Code
                        </p>
                      )} */}

                      <p
                        style={{ minWidth: "150px" }}
                        className="pointer"
                        onClick={() =>
                          boqDetail && dropHandler(`${index}_m_code`, "m_code")
                        }
                      >
                        {matDetail && matDetail[`${index}_material`]
                          ? matDetail[`${index}_material`]?.material_code
                          : " Select Material Code"}
                      </p>

                      {dropDown?.m_code == `${index}_m_code` && (
                        <ul className="drop-cont">
                          <li>
                            <input
                              className="border-0 bg-lightBlue px-2 py-1 mt-1"
                              value={filter?.m_code}
                              onChange={(e) =>
                                setFilter({
                                  ...filter,
                                  m_code: e.target.value,
                                })
                              }
                            />
                          </li>
                          {mCode &&
                            mCode?.map(
                              (material_code, ind) =>
                                ind < 5 && (
                                  <li
                                    key={ind}
                                    onClick={() =>
                                      material_code_view(
                                        material_code,
                                        `${index}_material`
                                      )
                                    }
                                  >
                                    {material_code}
                                  </li>
                                )
                            )}
                        </ul>
                      )}
                    </td>
                    <td  className=" text-center">
                      {matDetail && matDetail[`${index}_material`] ? (
                        <p>{matDetail[`${index}_material`]?.name}</p>
                      ) : (
                        <p
                          style={{ minWidth: "150px" }}
                          className="pointer"
                          onClick={() =>
                            boqDetail &&
                            dropHandler(`${index}_m_name`, "m_name")
                          }
                        >
                          Select Material Name
                        </p>
                      )}
                      {dropDown?.m_name == `${index}_m_name` && (
                        <ul className="drop-cont">
                          <li>
                            <input
                              className="border-0 bg-lightBlue px-2 py-1 mt-1"
                              value={filter?.m_name}
                              onChange={(e) =>
                                setFilter({
                                  ...filter,
                                  m_name: e.target.value,
                                })
                              }
                            />
                          </li>
                          {mName &&
                            mName?.map(
                              (material_name, ind) =>
                                ind < 5 && (
                                  <li
                                    key={ind}
                                    onClick={() =>
                                      material_code_view(
                                        material_name[1],
                                        `${index}_material`
                                      )
                                    }
                                  >
                                    {material_name[0]}
                                  </li>
                                )
                            )}
                        </ul>
                      )}
                    </td>
                    <td>{boqDetail && boqDetail[`${index}_boq`]?.uom}</td>
                    <td  className=" text-center">
                      {matDetailPre &&
                        matDetailPre[`${index}_material`]?.previous_quantity}
                    </td>
                    <td  className=" text-center">
                      {/* {boqDetail && boqDetail[`${index}_boq`]?.approved_mr_qty} */}
                      {matDetailPre &&
                        matDetailPre[`${index}_material`]?.total_quantity}
                    </td>
                    {/* <td>{boqDetail && boqDetail[`${index}_boq`]?.qty}</td> */}
                    <td>
                      <input
                        autoFocus={true}
                        className="border-0 text-center"
                        disabled={
                          matDetail && matDetail[`${index}_material`]
                            ? false
                            : true
                        }
                        value={mrTableFields?.qty[index] ?? ""}
                        onChange={(e) => quantityHandler(index, e.target.value)}
                      />
                    </td>
                    <td>
                      <input
                        className="border-0 text-center"
                        onChange={(e) =>
                          setMrTableFields({
                            ...mrTableFields,
                            remarks: {
                              ...mrTableFields?.remarks,
                              [index]: e.target.value,
                            },
                          })
                        }
                      />
                    </td>
                  </tr>
                )
              );
            })}
          </tbody>
        </table>
        <Col xs={12} sm={12} className="inputinner-box text-start">
          <button
            className={
              checkboxs?.length > 0
                ? "bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2 "
                : "bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2 opacity-50"
            }
            onClick={directPoSubmitHandler}
            disabled={checkboxs?.length > 0 ? false : true}
          >
            Submit
          </button>
        </Col>
      </div>
      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          statusMessage={statusMessage}
          showStatus={showStatus}
        />
      )}
    </Col>
  );
};

export default MaterialTable;
