import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { api } from "../../../constants/Services";

const IsmMaterialIssueLastForm = ({
  setModal,
  projectList,
  setWareHouse,
  setMaterialType,
  setMaterialIssue,
  setMiDate,
  setProject,
  setMaterialRequest,
  setWareHouseErr,
  setMaterialTypeErr,
  setProjectErr,
  WareHouse,
  materialType,
  materialIssue,
  miDate,
  project,
  MaterialRequest,
  WareHouseErr,
  materialTypeErr,
  materialIssueErr,
  setMaterialRequestErr,
  projectErr,
  setMiDateErr,
  miIssueDate,
  setMiIssueDateErr,
  materialTab,
  setStatusMessage,
  setShowStatus,
  materials,
}) => {
  const navigate = useNavigate();
  const [btn, setBtn] = useState(false);
  const [IssuedTo, setIssuedTo] = useState("");
  const [IssuedBy, setIssuedBy] = useState("");
  const [ApprovedBy, setApprovedBy] = useState("");
  const [Remarks, setRemarks] = useState("");

  const [IssuedToErr, setIssuedToErr] = useState(false);
  const [IssuedByErr, setIssuedByErr] = useState(false);
  const [ApprovedByErr, setApprovedByErr] = useState(false);
  const [RemarksErr, setRemarksErr] = useState(false);

  const submitHandler = () => {
    // setModal(true);
    if (
      WareHouse?.length == 0 ||
      MaterialRequest?.length == 0 ||
      miDate?.length == 0 ||
      miIssueDate?.length == 0 ||
      IssuedTo?.length == 0 ||
      project?.length == 0
    ) {
      setWareHouseErr(true);
      // setMaterialTypeErr(true);
      setMaterialRequestErr(true);
      setMiIssueDateErr(true);
      setIssuedToErr(true);
      setMiDateErr(true);
      setProjectErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("project_id", project);
      formdata.append("request_slip_no", MaterialRequest);
      formdata.append("request_slip_date", miDate);
      formdata.append("ware_house_id", WareHouse);
      formdata.append("issue_date", miIssueDate);
      formdata.append("issue_to", IssuedTo);

      if (Remarks?.length > 0) {
        formdata.append("remarks", Remarks);
      }

      let count = 1;

      if (Object.values(materials)?.length > 0) {
        Object.values(materials)?.map((item, ind) => {
          if (materialTab?.includes(item?.id)) {
            if (item?.error == 1) {
              count = count + 1;
            }

            formdata.append(
              `material_issue_details[${ind}][material_id]`,
              item?.material_id
            );
            formdata.append(
              `material_issue_details[${ind}][issued_qty]`,
              item?.issued_qty
            );
            formdata.append(
              `material_issue_details[${ind}][remarks]`,
              item?.remarks ? item?.remarks : ""
            );
          }
        });
      }

      if (count == 1) {
        setBtn(true);
        api
          .matertialIssueAdd(formdata)
          .then((res) => {
            console.log("matertialIssueAdd", res);
            setBtn(false);
            if (res?.status == "success") {
              setShowStatus(true);
              setStatusMessage(res?.message + " " + res?.material_issue_no);
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err");
          });
      }
    }
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 ps-lg-4 text-center">
      <Col className="d-flex  px-lg-3 flex-wrap">
        <Col xs={12} xl={6}>
          <Col xs={12} sm={12} xl={11} className="inputinner-box text-start">
            <label className="w-100">Issued To</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-95 mt-1"
              placeholder="Enter Issued To"
              onChange={(e) => setIssuedTo(e.target.value)}
              value={IssuedTo}
            />
            {IssuedTo?.length == 0 && IssuedToErr && (
              <p className="err">Enter Issued To </p>
            )}
          </Col>
          {/* <Col xs={12} sm={12} xl={11} className="inputinner-box text-start">
            <label className="w-100">Issued By</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-95 mt-1"
              placeholder="Enter Issued By"
              onChange={(e) => setIssuedBy(e.target.value)}
              value={IssuedBy}
            />
            {IssuedBy?.length == 0 && IssuedByErr && (
              <p className="err">enter issued by </p>
            )}
          </Col>
          <Col xs={12} sm={12} xl={11} className="inputinner-box text-start">
            <label className="w-100">Approved By</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-95 mt-1"
              placeholder="Enter Approved By"
              onChange={(e) => setApprovedBy(e.target.value)}
              value={ApprovedBy}
            />
            {ApprovedBy?.length == 0 && ApprovedByErr && (
              <p className="err">enter approved by </p>
            )}
          </Col> */}
        </Col>

        <Col xs={12} sm={12} xl={6} className="inputinner-box text-start">
          <label className="w-100">Remarks</label>
          <textarea
            placeholder="Enter Remarks"
            className="border-0 bg-lightBlue px-2 py-1 w-95 mt-1 fs-14"
            onChange={(e) => setRemarks(e.target.value)}
            value={Remarks}
          />
          {Remarks?.length == 0 && RemarksErr && (
            <p className="err">Enter Remarks </p>
          )}
        </Col>
      </Col>
      <Col className="d-flex justify-content-between ms-lg-3 me-lg-5">
        <div>
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2 me-3"
            onClick={submitHandler}
            disabled={btn ? true : false}
          >
            Save
          </button>
          <button className="bg-gray border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
            Cancel
          </button>
        </div>
        {/* <button className="bg-gray border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
          Export
        </button> */}
      </Col>
    </Col>
  );
};

export default IsmMaterialIssueLastForm;
