import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../../constants/Services";
import useUser from "../../../constants/Storage/userData";

const IsmGinLastForm = ({
  setModal,
  projectType,
  setProjectType,
  projectName,
  setProjectName,
  pRDDate,
  setPRDDate,
  prNo,
  setPrNo,
  PONo,
  setPONo,
  DCNo,
  setDCNo,
  DCDate,
  setDCDate,
  GRNDate,
  setGRNDate,
  InvoiceNo,
  setInvoiceNo,
  grnFile,
  setGrnFile,
  setInvoiceDate,
  InvoiceDate,
  projectNameErr,
  setProjectNameErr,
  PONoErr,
  setPONoErr,
  DCNoErr,
  setDCNoErr,
  GRNNoErr,
  setGRNNoErr,
  DCDateErr,
  setDCDateErr,
  GRNDateErr,
  setGRNDateErr,
  InvoiceNoErr,
  setInvoiceNoErr,
  InvoiceDateErr,
  setInvoiceDateErr,
  setWareHouseErr,
  wareHouseErr,
  wareHouse,
  setWareHouse,
  setDetails,
  details,
  setVendor,
  vendor,
  setShowStatus,
  setProjectTypeErr,
  setStatusMessage,
  matrial,
}) => {
  const [Prepared, setPrepared] = useState("");
  const [btn, setBtn] = useState(false);
  // const { user } = useUser();
  // console.log(
  //   "🚀 ~ file: IsmGinLastSecForm.js:8 ~ IsmGinLastForm ~ user:",
  //   user
  // );
  const [Remarks, setRemarks] = useState("");

  const [RemarksErr, setRemarksErr] = useState(false);

  console.log("matrial", matrial);

  const submitHandler = () => {
    if (
      projectType?.length == 0 ||
      projectName?.length == 0 ||
      // DCNo?.length == 0 ||
      // DCDate?.length == 0 ||
      GRNDate?.length == 0 ||
      // InvoiceDate?.length == 0 ||
      // InvoiceNo?.length == 0 ||
      // Remarks?.length == 0 ||
      wareHouse?.length == 0
    ) {
      setProjectNameErr(true);
      // setDCNoErr(true);
      // setDCDateErr(true);
      setGRNDateErr(true);
      // setInvoiceNoErr(true);
      // setInvoiceDateErr(true);
      setProjectTypeErr(true);
      setWareHouseErr(true);
      // setRemarksErr(true);
    } else {
      // setModal(true);

      let formdata = new FormData();
      formdata.append("image", grnFile);
      formdata.append("type", projectType);
      formdata.append("project_id", projectName);
      formdata.append("vendor_id", vendor);
      formdata.append("dc_no", DCNo);
      formdata.append("dc_date", DCDate);
      formdata.append("grn_date", GRNDate);
      // formdata.append("invoice_no", InvoiceNo);
      // formdata.append("invoice_date", InvoiceDate);
      formdata.append("ware_house_id", wareHouse);
      formdata.append("remarks", Remarks);

      if (PONo?.length !== 0) {
        formdata.append("purchase_order_id", PONo);
      }

      let count = 1;

      if (Object.values(matrial)?.length > 0) {
        Object.values(matrial)?.map((item, ind) => {
          if (details?.includes(item?.id)) {
            if (item?.error == 1) {
              count = count + 1;
            }

            if (item?.qty_received <= 0) {
              count = count + 1;
              alert("Received Qty Should not be 0");
            }

            formdata.append(
              `grn_details[${ind}][material_id]`,
              item?.material_id
            );

            if (projectType == 1) {
              formdata.append(
                `grn_details[${ind}][purchase_order_detail_id]`,
                item?.id
              );
            }

            formdata.append(
              `grn_details[${ind}][test_certificate]`,
              item?.test_certificate
            );
            formdata.append(
              `grn_details[${ind}][warranty_certificate]`,
              item?.waranty_certificate
            );
            formdata.append(
              `grn_details[${ind}][mrir_certificate]`,
              item?.MRIR_certificate
            );
            formdata.append(
              `grn_details[${ind}][received_qty]`,
              item?.qty_received
            );
            formdata.append(
              `grn_details[${ind}][basic_price]`,
              item?.basic_rate
            );
            formdata.append(
              `grn_details[${ind}][total_price]`,
              item?.total_value
            );
            formdata.append(`grn_details[${ind}][remarks]`, item?.remarks);
          }
        });
      }

      if (count == 1) {
        setBtn(true);
        api
          .grnAdd(formdata)
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              setShowStatus(true);
              setStatusMessage(res?.message + " " + res?.grn_no);
            }
            console.log("res", res);
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  return (
    <Col className="emply-master-form px-3 py-2 px-lg-4 py-md-4 bg-white mt-3 text-center">
      <Col className="d-flex flex-column  flex-wrap">
        <Col
          xs={12}
          sm={12}
          lg={6}
          xl={5}
          className="inputinner-box text-start"
        >
          <label className="w-100">Remarks</label>
          <textarea
            className="border-0 bg-lightBlue px-2 py-1 w-95 mt-1 fs-14"
            onChange={(e) => setRemarks(e.target.value)}
            value={Remarks}
          />
          {Remarks?.length == 0 && RemarksErr && (
            <p className="err">Enter Remarks</p>
          )}
        </Col>
      </Col>
      <Col className="d-flex justify-content-between mx-lg-1">
        <div>
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2 me-3"
            onClick={submitHandler}
            disabled={btn ? true : false}
          >
            Save
          </button>
          <button className="bg-gray border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
            Cancel
          </button>
        </div>
        {/* <button className="bg-gray border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
          Export
        </button> */}
      </Col>
    </Col>
  );
};

export default IsmGinLastForm;
