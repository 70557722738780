import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import { api } from "../../constants/Services";
import { Col, Tab } from "react-bootstrap";
import Header from "../../component/Header/Header";

function MaterialRequestActionPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [materialRequestDetails, setMaterialRequestDetails] = useState({
    table: [],
    material_type: {},
  });
  const [matId, setMatId] = useState();
  const [list, setList] = useState();

  const material_request_id = location?.state?.id;
  const project_id = location?.state?.proId;

  const getView = (id) => {
    // console.log("id", id);
    let data = {
      material_request_id: id,
    };
    api.matrialRequest(data).then((res) => {
      if (res?.status == "success") {
        // setMaterialRequestDetails(
        //   res?.material_request?.material_request_details
        // );
        // console.log("res", res);
        setMaterialRequestDetails({
          material_type: res?.material_request,
          table: res?.material_request_list,
        });
      }
    });
  };

  useEffect(() => {
    if (material_request_id) {
      getView(material_request_id);
    }
  }, []);

  const getList = () => {
    let data = {
      project_id: searchParams?.get("proId"),
    };
    api.matrialList(data).then((res) => {
      if (res?.status == "success") {
        setList(res?.material_requests);
      }
    });
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      getList();
      getView(searchParams.get("id"));
    }
  }, [searchParams.get("id")]);

  const nextHandler = () => {
    let next_id = list.indexOf(matId);
    console.log("next_id", next_id);
    console.log("list.length", list.length);
    console.log("list.length-1", list.length - 1);
    list.length - 1 === next_id ? (next_id = 0) : (next_id = +next_id + 1);
    console.log("next_id1", next_id);
    next_id = list[next_id];
    console.log("next_id2", next_id);
    // if (!searchParams.get("id")) {
    //   next_id = searchParams.get("id");
    // }
    setMatId(next_id);
    getView(next_id?.id);
  };

  // console.log("materialRequestDetails", materialRequestDetails);

  return (
    <Col xs={12} className="p-3">
      <Header />
      <Col
        style={{ overflowX: "scroll" }}
        className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center "
      >
        <h4>Material Request Details</h4>
        <Col className="emply-master-form py-2  mt-1 text-center">
          <div style={{ overflowX: "scroll" }} className="boq-table  bg-white">
            <table className="table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>BOQ Code</th>
                  <th>Material Name</th>
                  <th>Material Code</th>
                  <th>Material Type</th>
                  <th>UOM</th>
                  <th>Basic Price</th>
                  <th>Required Quantity</th>
                  <th>Created By</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              {materialRequestDetails?.table.length > 0 &&
                materialRequestDetails?.table.map((item, ind) => {
                  return (
                    <tr key={ind}>
                      <td style={{fontSize:"12px"}}>0{ind + 1}</td>
                      <td style={{fontSize:"12px"}}>{item?.boq_code ?? "-"}</td>
                      <td style={{textAlign:"start",paddingLeft:"5px",fontSize:"12px"}}>{item?.material?.name ?? "-"}</td>
                      <td style={{fontSize:"12px"}}>{item?.material?.material_code ?? "-"}</td>
                      <td style={{fontSize:"12px"}}>
                        {materialRequestDetails?.material_type?.material_type ??
                          "-"}
                      </td>
                      <td style={{fontSize:"12px"}}>{item?.uom ?? "-"}</td>
                      <td style={{fontSize:"12px"}}>{item?.basic_price ?? "-"}</td>
                      <td style={{fontSize:"12px"}}>{item?.required_quantity}</td>
                      <td className="text-center" style={{fontSize:"12px"}}>
                        {
                          materialRequestDetails?.material_type?.created_user
                            ?.name
                        }
                      </td>
                      <td style={{fontSize:"12px"}}>{item?.remarks}</td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </Col>
      </Col>
      <div className="d-flex justify-content-end">
        {searchParams.get("id") && (
          <div className="me-3">
            <button
              className="bg-success border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 "
              // onClick={() => navigate("/notification")}
              onClick={() => nextHandler()}
            >
              Next
            </button>
          </div>
        )}
        <div className="">
          {searchParams.get("id") ? (
            <Link
              to={`/execution/material-request-list?proId=${searchParams?.get(
                "proId"
              )}&projectName=${searchParams?.get("projectName")}`}
            >
              <button className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-auto">
                Close
              </button>
            </Link>
          ) : (
            <button
              className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-auto"
              onClick={() => navigate("/notification" )}
            >
              Close
            </button>
          )}
        </div>
      </div>
    </Col>
  );
}

export default MaterialRequestActionPage;
