import React, { useState, useEffect } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import Header from "../../component/Header/Header";
import MaterialTransferForm from "../../component/inventory/MaterialTranfer/MaterialTransferForm";
import MaterialTransferTable from "../../component/inventory/MaterialTranfer/MaterialTransferTable";
import MatrialInstruction from "../../component/inventory/MaterialTranfer/MatrialInstruction";
import { api } from "../../constants/Services";
import { useNavigate } from "react-router";
import useUser from "../../constants/Storage/userData";
import StatusModal from "../../component/StatusModal";

const IsmMaterialTransferScreen = () => {
  const [showStatus, setShowStatus] = useState(false);
  const [materialList, setMaterialList] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [TransferType, setTransferType] = useState("");
  const [tableHeadings, setTableHeadings] = useState([]);
  const [row, setRow] = useState([1]);
  const [project, setProject] = useState("");
  const { user } = useUser();
  const [mtTableStaticData, setMtTableStaticTable] = useState({
    transfer_qty: {},
    remarks: {},
    net_value: {},
  });
  const [tableSel, setTableSel] = useState([]);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const [projectTo, setProjectTo] = useState("");
  const [transferDate, setTransferDate] = useState("");
  const [files, setFiles] = useState("");
  const [wareHouse, setWarehouse] = useState("");
  const [wareHouseTo, setWarehouseTo] = useState("");

  const [projectToErr, setProjectToErr] = useState(false);
  const [projectErr, setProjectErr] = useState(false);
  const [transferDateErr, setTransferDateErr] = useState(false);
  const [filesErr, setFilesErr] = useState(false);
  const [wareHouseErr, setWarehouseErr] = useState(false);
  const [wareHouseToErr, setWarehouseToErr] = useState(false);
  const [TransferTyperErr, setTransferTyperErr] = useState(false);

  const poPrintHandler = () => {
    setModal(!modal);
  };

  const statusModalClose = () => {
    setShowStatus(!showStatus);
  };

  const getProject = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };

  useEffect(() => {
    getProject();
  }, []);
  const [materialCodeDrop, setMaterialCodeDrop] = useState(false);
  const [materialDiscDrop, setMaterialDiscDrop] = useState(false);

  const mCode = () => {
    setMaterialCodeDrop(!materialCodeDrop);
  };

  const mDisc = () => {
    setMaterialDiscDrop(!materialDiscDrop);
  };

  const warehouseSelector = (id) => {
    setWarehouse(id);
    let formdata = new FormData();
    formdata.append("project_id", project);
    formdata.append("ware_house_id", id);
    api
      .matertialIssueGetMaterial(formdata)
      .then((res) => {
        if (res?.status == "success") {
          let temp = [];
          res?.grn_materials?.map((item, ind) => {
            item?.grn_detail?.map((mat, inx) => {
              temp.push(mat);
            });
          });
          console.log("temp", temp);
          // setMaterialList(temp);
          let arr = [];
          res?.available_stocks?.map((item, ind) => {
            if (item?.available_stock !== 0) {
              arr.push(item);
            }
          });
          setMaterialList(arr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <MaterialTransferForm
        projectList={projectList}
        setTransferType={setTransferType}
        setProject={setProject}
        setFiles={setFiles}
        setProjectTo={setProjectTo}
        setTransferDate={setTransferDate}
        warehouseSelector={warehouseSelector}
        setWarehouse={setWarehouse}
        setWarehouseTo={setWarehouseTo}
        project={project}
        TransferType={TransferType}
        projectTo={projectTo}
        transferDate={transferDate}
        files={files}
        wareHouse={wareHouse}
        wareHouseTo={wareHouseTo}
        projectToErr={projectToErr}
        projectErr={projectErr}
        transferDateErr={transferDateErr}
        filesErr={filesErr}
        wareHouseErr={wareHouseErr}
        wareHouseToErr={wareHouseToErr}
        TransferTyperErr={TransferTyperErr}
        setTableSel={setTableSel}
        setRow={setRow}
      />

      <MaterialTransferTable
        project={project}
        wareHouse={wareHouse}
        TransferType={TransferType}
        setTableSel={setTableSel}
        tableSel={tableSel}
        mtTableStaticData={mtTableStaticData}
        setMtTableStaticTable={setMtTableStaticTable}
        mCode={mCode}
        materialCodeDrop={materialCodeDrop}
        mDisc={mDisc}
        materialDiscDrop={materialDiscDrop}
        materialList={materialList}
        setMaterialList={setMaterialList}
        setRow={setRow}
        row={row}
      />

      <MatrialInstruction
        user={user?.name}
        setModal={setModal}
        project={project}
        TransferType={TransferType}
        projectTo={projectTo}
        transferDate={transferDate}
        files={files}
        wareHouse={wareHouse}
        wareHouseTo={wareHouseTo}
        setProjectToErr={setProjectToErr}
        setProjectErr={setProjectErr}
        setTransferDateErr={setTransferDateErr}
        setFilesErr={setFilesErr}
        setWarehouseErr={setWarehouseErr}
        setWarehouseToErr={setWarehouseToErr}
        setTransferTyperErr={setTransferTyperErr}
        tableSel={tableSel}
        setShowStatus={setShowStatus}
        setStatusMessage={setStatusMessage}
      />

      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
          statusMessage={statusMessage}
        />
      )}

      <Modal show={modal} centered onHide={() => setModal(!modal)}>
        <Modal.Header closeButton className="border-0">
          <label className="w-50 mx-auto my-1 py-1">Material Transfer : </label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <p className="text-center fs-6">Do you want to Print the Output?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-primary"
            onClick={poPrintHandler}
            variant="primary"
          >
            Print
          </Button>
          <Button variant="secondary" onClick={() => setModal(!modal)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default IsmMaterialTransferScreen;
