import Header from "../component/Header/Header";
import React, { useEffect, useState } from "react";
import useUser from "../constants/Storage/userData";
import { tickIcon } from "../assets/img";
import eye_icon from "../assets/icons/common/Eye.svg";
import { api } from "../constants/Services";
import { Col, Modal, Button } from "react-bootstrap";
import ViewNotification from "./Notification/ViewNotification";
import moment from "moment";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function NotificationScreen() {
  const navigate = useNavigate();
  const { user } = useUser();
  // // console.log(
  // // "🚀 ~ file: NotificationComponent.js:15 ~ NotificationScreen ~ user:",
  // // user
  // // );
  const permissions = localStorage.getItem("permissions");
  const [show, setShow] = useState(false);
  const [decline, setDecline] = useState(false);
  const [declines, setDeclines] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [remarksRej, setRemarksRej] = useState("");
  const [remData, setRemData] = useState({});
  const [materialDetail, setMaterialDetail] = useState({
    mr_date: "",
    mr_no: "",
    project_name: "",
    created_at: "",

    // updated_at: "",
  });
  const [currentShow, setCurrentShow] = useState();
  const [notifications, setnotifications] = useState([]);
  const [matrialList, setMaterialList] = useState([]);
  const [purchaseList, setPurchaseList] = useState([]);
  const [grnList, setGrnList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [materialIssueList, setMaterialIssueList] = useState([]);
  const [proBoq, setProBoqList] = useState([]);
  const [matrialRejectList, setMaterialRejectList] = useState([]);
  const [purchaseRejectList, setPurchaseRejectList] = useState([]);
  const [proBoqReject, setProBoqRejectList] = useState([]);
  const [workOrderList, setWorkOrderList] = useState([]);
  const [messageLists, setMessageLists] = useState([]);
  const [internalReturnList, setInternalReturnList] = useState([]);
  const [matrialReceiptList, setMatrialReceiptList] = useState([]);
  const [matrialTransferList, setMatrialTransferList] = useState([]);
  const [matrialReturnList, setMatrialReturnList] = useState([]);
  const [tab, setTab] = useState(0);
  const location = useLocation();

  // console.log(
  // "🚀 ~ file: NotificationComponent.js:33 ~ NotificationScreen ~ notifications:",
  // notifications
  // );
  const [materialReqView, setMaterialReqView] = useState();

  const materialViewHandler = (id) => {
    // // // console.log(
    // // //   "🚀 ~ file: NotificationComponent.js:29 ~ materialViewHandler ~ id:",
    // // //   id
    // // // );
    navigate("/execution/material-request-view", { state: { id: id } });
    // let project_name = projects?.filter(
    //   (project) => project?.id == material_details?.project_id
    // );
    // setMaterialDetail({
    //   mr_date: material_details?.mr_date,
    //   mr_no: material_details?.mr_no,
    //   project_name: project_name[0]?.name,
    //   created_at: moment(material_details?.created_at).format("LL"),
    //   // updated_at: moment(material_details?.updated_at).format("LL"),
    // });

    setViewModal(!viewModal);
  };

  const onclickViewHandler = (e) => {
    // setViewModal(!viewModal);
    // api.material_request_view(e).then((resp) => {
    //   console.log(resp);
    // });
    console.log(e);
  };

  // const get_materials_details = (mat_id) => {
  //   api.material_request_view(mat_id).then((res) => {
  //     setMaterialDetail(res?.details);
  //     console.log("materials", res);
  //     if (currentShow == mat_id) {
  //       setCurrentShow("");
  //     } else {
  //       setCurrentShow(mat_id);
  //     }
  //   });
  // };

  const notifyStatus = (item, status, remarksText) => {
    let data = {
      notification_id:
        item?.id || item?.approval?.id || item?.previous_approved_list?.id,
      status: status,
    };
    if (remarksText) {
      data.remarks = remarksText;
    }
    api.notifyStatus(data).then((res) => {
      if (res?.status == "success") {
        alert(res?.message);
        if (status == 0) {
          setDecline(false);
          setRemarks("");
          getRejectList();
        }
        getMessages();
        getList();
      } else {
        alert(res?.message);
      }
    });
  };

  const declineRemarks = (noti) => {
    notifyStatus(noti, 0, remarks);
  };

  const projectList = () => {
    api.project_list().then((res) => {
      if (res?.status == "success") {
        setProjects(res?.projects);
      }
      // console.log("project-list-res-noto", res);
    });
  };

  const purcaseOrderDetailHandler = (sel_po, po_num, po_date) => {
    // console.log(sel_po, po_num, po_date);
    // navigate("/procurement/purchase-order-action", {
    //   state: { id: sel_po, po_no: po_num, po_date: po_date },
    // });
    navigate("/procurement/po-output", { state: { id: sel_po } });
  };

  // const getList = () => {
  //   api.get_notifications().then((res) => {
  //     if (res?.status == "success") {
  //       setnotifications(res?.notifications);
  //       if (
  //         permissions?.includes("CAN-MATERIAL LIST-NOTIFICATION") ||
  //         permissions?.includes("CAN-PO LIST-NOTIFICATION")
  //       ) {
  //         const notiShow = res?.notifications.filter((noti) => {
  //           if (
  //             (noti?.material_request?.approved_by == null &&
  //               noti?.material_request?.status == 1) ||
  //             (noti?.purchase_order?.approved_by == null &&
  //               noti?.purchase_order?.status == 1)
  //           ) {
  //             return noti;
  //           } else {
  //             return false;
  //           }
  //         });
  //         if (notiShow?.length > 0) {
  //           setnotifications(notiShow);
  //           setShow(true);
  //         }
  //       } else {
  //         setShow(false);
  //       }
  //       // console.log(res);
  //     }
  //   });
  // };

  const getLists = () => {
    api.get_notifications().then((res) => {
      if (res?.status == "success") {
        let material = [];
        let purchase = [];
        let project_boq = [];
        res?.notifications?.map((item, ind) => {
          if (
            item?.material_request &&
            item?.material_request?.status == 1 &&
            item?.material_request?.approved_by == null
          ) {
            material.push(item);
          } else if (
            item?.purchase_order &&
            item?.purchase_order?.status == 1 &&
            item?.purchase_order?.approved_by == null
          ) {
            purchase.push(item);
          } else if (item?.project_boq_id) {
            project_boq.push(item);
          }
        });

        material.sort(
          (a, b) =>
            Date.parse(a.material_request?.mr_date) -
            Date.parse(b.material_request?.mr_date)
        );

        purchase.sort(
          (a, b) =>
            Date.parse(a.purchase_order?.po_date) -
            Date.parse(b.purchase_order?.po_date)
        );

        // project_boq.sort(
        //   (a, b) =>
        //     Date.parse(a.purchase_order?.po_date) -
        //     Date.parse(b.purchase_order?.po_date)
        // );

        if (material?.length > 0) {
          setShow(true);
        } else if (purchase?.length > 0) {
          setShow(true);
        } else if (project_boq?.length > 0) {
          setShow(true);
        } else {
          setShow(false);
        }

        setMaterialList(material);
        setPurchaseList(purchase);
        setProBoqList(project_boq);
      }
    });
  };

  console.log("proBoq", proBoq);

  const getList = () => {
    api.get_notifications().then((res) => {
      if (res?.status == "success") {
        let temp = [];
        let material = [];
        let purchase = [];
        let project_boq = [];
        let grnList = [];
        let invoice = [];
        let materialIssue = [];
        let workorder = [];
        let matrialReceipt = [];
        let internalReturn = [];
        let matrialTransfer = [];
        let matrialReturn = [];

        res?.approvals?.map((item, ind) => {
          temp.push(item);
        });

        res?.previous_approved_list?.map((item, ind) => {
          temp.push(item);
        });
        // console.log("te", temp);
        temp.map((item, ind) => {
          // if (
          //   item?.material_request &&
          //   item?.material_request?.status == 1 &&
          //   item?.material_request?.approved_by == null
          // ) {
          //   material.push(item);
          // } else if (
          //   item?.purchase_order &&
          //   item?.purchase_order?.status == 1 &&
          //   item?.purchase_order?.approved_by == null
          // ) {
          //   purchase.push(item);
          // } else if (item?.project_boq_id) {
          //   project_boq.push(item);
          // }
          if (
            item?.approval?.approvable_type == "App\\Models\\MaterialRequest" ||
            item?.approvable_type == "App\\Models\\MaterialRequest" ||
            item?.previous_approved_list?.approvable_type ==
              "App\\Models\\MaterialRequest"
          ) {
            material.push(item);
          } else if (
            item?.approval?.approvable_type == "App\\Models\\ProjectBoq" ||
            item?.approvable_type == "App\\Models\\ProjectBoq" ||
            item?.previous_approved_list?.approvable_type ==
              "App\\Models\\ProjectBoq"
          ) {
            project_boq.push(item);
          } else if (
            item?.approval?.approvable_type == "App\\Models\\Grn" ||
            item?.approvable_type == "App\\Models\\Grn" ||
            item?.previous_approved_list?.approvable_type == "App\\Models\\Grn"
          ) {
            grnList.push(item);
          } else if (
            item?.approval?.approvable_type == "App\\Models\\PurchaseOrder" ||
            item?.approvable_type == "App\\Models\\PurchaseOrder" ||
            item?.previous_approved_list?.approvable_type ==
              "App\\Models\\PurchaseOrder"
          ) {
            purchase.push(item);
          } else if (
            item?.approval?.approvable_type == "App\\Models\\Invoice" ||
            item?.approvable_type == "App\\Models\\Invoice" ||
            item?.previous_approved_list?.approvable_type ==
              "App\\Models\\Invoice"
          ) {
            invoice.push(item);
          } else if (
            item?.approval?.approvable_type == "App\\Models\\MaterialIssue" ||
            item?.approvable_type == "App\\Models\\MaterialIssue" ||
            item?.previous_approved_list?.approvable_type ==
              "App\\Models\\MaterialIssue"
          ) {
            materialIssue.push(item);
          } else if (
            item?.approval?.approvable_type == "App\\Models\\WorkOrder" ||
            item?.approvable_type == "App\\Models\\WorkOrder" ||
            item?.previous_approved_list?.approvable_type ==
              "App\\Models\\WorkOrder"
          ) {
            workorder.push(item);
          } else if (
            item?.approval?.approvable_type ==
              "App\\Models\\MaterialTransfer" ||
            item?.approvable_type == "App\\Models\\MaterialTransfer" ||
            item?.previous_approved_list?.approvable_type ==
              "App\\Models\\MaterialTransfer"
          ) {
            matrialTransfer.push(item);
          } else if (
            item?.approval?.approvable_type == "App\\Models\\InternalReturn" ||
            item?.approvable_type == "App\\Models\\InternalReturn" ||
            item?.previous_approved_list?.approvable_type ==
              "App\\Models\\InternalReturn"
          ) {
            internalReturn.push(item);
          } else if (
            item?.approval?.approvable_type == "App\\Models\\MaterialReceipt" ||
            item?.approvable_type == "App\\Models\\MaterialReceipt" ||
            item?.previous_approved_list?.approvable_type ==
              "App\\Models\\MaterialReceipt"
          ) {
            matrialReceipt.push(item);
          } else if (
            item?.approval?.approvable_type == "App\\Models\\MaterialReturn" ||
            item?.approvable_type == "App\\Models\\MaterialReturn" ||
            item?.previous_approved_list?.approvable_type ==
              "App\\Models\\MaterialReturn"
          ) {
            matrialReturn.push(item);
          }
        });

        // console.log("material", material);
        // console.log("project_boq", project_boq);
        // console.log("grnList", grnList);

        if (
          permissions?.includes("CAN-MATERIAL LIST-NOTIFICATION") &&
          material?.length > 0
        ) {
          setShow(true);
        } else if (
          permissions?.includes("CAN-PO LIST-NOTIFICATION") &&
          purchase?.length > 0
        ) {
          setShow(true);
        } else if (
          permissions?.includes("CAN-PROJECT BOQ LIST-NOTIFICATION") &&
          project_boq?.length > 0
        ) {
          setShow(true);
        } else if (
          permissions?.includes("CAN-GRN LIST-NOTIFICATION") &&
          grnList?.length > 0
        ) {
          setShow(true);
        } else if (
          permissions?.includes("CAN-INVOICE LIST-NOTIFICATION") &&
          invoice?.length > 0
        ) {
          setShow(true);
        } else if (
          permissions?.includes("CAN-MATERIAL ISSUE LIST-NOTIFICATION") &&
          materialIssue?.length > 0
        ) {
          setShow(true);
        } else if (workorder?.length > 0) {
          setShow(true);
        } else if (
          permissions?.includes("CAN-INTERNAL RETURN LIST-NOTIFICATION") &&
          internalReturn?.length > 0
        ) {
          setShow(true);
        } else if (
          permissions?.includes("CAN-MATERIAL RECEIPT LIST-NOTIFICATION") &&
          matrialReceipt?.length > 0
        ) {
          setShow(true);
        } else if (
          permissions?.includes("CAN-MATERIAL TRANSFER LIST-NOTIFICATION") &&
          matrialTransfer?.length > 0
        ) {
          setShow(true);
        } else if (
          permissions?.includes("CAN-MATERIAL RETURN LIST-NOTIFICATION") &&
          matrialReturn?.length > 0
        ) {
          setShow(true);
        } else {
          setShow(false);
        }

        setGrnList(grnList);
        setMaterialList(material);
        setPurchaseList(purchase);
        setProBoqList(project_boq);
        setInvoiceList(invoice);
        setMaterialIssueList(materialIssue);
        setWorkOrderList(workorder);
        setInternalReturnList(internalReturn);
        setMatrialTransferList(matrialTransfer);
        setMatrialReceiptList(matrialReceipt);
        setMatrialReturnList(matrialReturn);
      }
    });
  };

  // console.log("matrialList", matrialList);

  const getRejectList = () => {
    api.notifyRejectList().then((res) => {
      if (res?.status == "success") {
        setMaterialRejectList(res?.material_requests);
        setProBoqRejectList(res?.project_boqs);
        setPurchaseRejectList(res?.purchase_orders);
      }
    });
  };

  const getMessages = () => {
    api.messageList().then((res) => {
      setMessageLists(res?.notifications);
    });
  };

  useEffect(() => {
    if (location?.state?.tab) {
      setTab(location?.state?.tab);
    }
    getList();
    getRejectList();
    projectList();
    getMessages();
  }, []);

  // console.log("invoiceList", invoiceList);

  // useEffect(() => {
  //   if (
  //     permissions?.includes("CAN-MATERIAL LIST-NOTIFICATION") ||
  //     permissions?.includes("CAN-PO LIST-NOTIFICATION")
  //   ) {
  //     const notiShow = notifications.filter((noti) => {
  //       if (
  //         (noti?.material_request?.approved_by == null &&
  //           noti?.material_request?.status == 1) ||
  //         (noti?.purchase_order?.approved_by == null &&
  //           noti?.purchase_order?.status == 1)
  //       ) {
  //         return noti;
  //       } else {
  //         return false;
  //       }
  //     });
  //     if (notiShow?.length > 0 && user?.id == 307) {
  //       setShow(true);
  //     }
  //   } else {
  //     setShow(false);
  //   }
  // }, []);

  // console.log("notifications", notifications);

  // console.log("matrialList", matrialList);

  return (
    <>
      <Col xs={12} className="py-2 px-1 p-md-3">
        <Header />

        <Col className="emply-master-form p-3 my-3 bg-white">
          {" "}
          <Tabs
            defaultActiveKey="request"
            id="uncontrolled-tab-example"
            className="mx-3"
            onSelect={(e) => console.log("e", e)}
          >
            <Tab
              className={`${tab == 0 || ""}mb-2`}
              eventKey="message"
              title="Message"
            >
              <>
                <h6 className=" py-3 my-2">Message</h6>
                {messageLists?.map((noti, ind) => (
                  <Col xs={12} className="notificaion-text-box" key={ind}>
                    <p className="d-flex gap-5">
                      <span>{moment(noti?.created_at).format("LL")}</span>
                      <span>{noti?.message ?? " "}</span>
                    </p>
                  </Col>
                ))}
              </>
            </Tab>
            <Tab
              className={`${tab == 1 || ""}mb-2`}
              eventKey="reject"
              title="Reject"
            >
              <h4 className="mb-1 mt-4 pb-2">Reject Notifications</h4>
              {permissions?.includes("CAN-PO LIST-NOTIFICATION") ? (
                <>
                  <h6 className=" py-3 my-2">Boq Amendment</h6>
                  {proBoqReject?.map((noti, ind) => (
                    <Col xs={12} className="notificaion-text-box" key={ind}>
                      <p className="d-flex gap-5">
                        <span>{moment(noti?.created_at).format("LL")}</span>
                        <span>{noti?.boq_no ?? "PO4567712"}</span>
                      </p>
                      {permissions?.includes(
                        "CAN-ACCEPT & DECLINE PO LIST-NOTIFICATION"
                      ) ? (
                        <div>
                          <button
                            className="border-0 rounded-1 bg-secondary my-2 text-white px-2 py-1 ms-2"
                            // onClick={() => notifyStatus(noti, 0)}
                            onClick={() => {
                              setDeclines(true);
                              setRemarksRej(noti?.approval?.remarks);
                            }}
                          >
                            Remarks
                          </button>
                          {/* <img
                        onClick={() =>
                          purcaseOrderDetailHandler(
                            noti?.project_boq?.id,
                            noti?.project_boq?.boq_no,
                            noti?.purchase_order?.po_date
                          )
                        }
                        src={eye_icon}
                        alt="eye-icon"
                        style={{ width: "24px" }}
                        className="ms-3 pointer"
                      /> */}
                        </div>
                      ) : null}
                    </Col>
                  ))}
                </>
              ) : null}{" "}
              {permissions?.includes("CAN-MATERIAL LIST-NOTIFICATION") ? (
                <>
                  <h6 className="mt-1 pt-2 pb-3 mb-2">Material Requests</h6>
                  {matrialRejectList?.map((item, index) => {
                    return (
                      <Col
                        key={index}
                        xs={12}
                        className="notificaion-text-box pointer"
                      >
                        <p className="d-flex gap-5">
                          <span>{moment(item?.mr_date).format("LL")}</span>
                          <span>
                            {/* {"Noti :" + item?.id}{" "}
                      {"Mat_req :" + item?.material_request?.id}{" "} */}
                            {item?.mr_no}
                          </span>
                        </p>

                        <div>
                          {permissions?.includes(
                            "CAN-ACCEPT & DECLINE MATERIAL LIST-NOTIFICATION"
                          ) ? (
                            <button
                              className="border-0 rounded-1 bg-secondary my-2 text-white px-2 py-1 ms-2"
                              // onClick={() => notifyStatus(item, 0)}
                              onClick={() => {
                                setDeclines(true);
                                setRemarksRej(item?.approval?.remarks);
                              }}
                            >
                              Remarks
                            </button>
                          ) : null}
                          <button
                            onClick={() => materialViewHandler(item?.id)}
                            className="cust-btn"
                          >
                            <img
                              src={eye_icon}
                              alt="eye-icon"
                              style={{ width: "24px" }}
                              className="ms-3 pointer"
                            />
                          </button>
                        </div>
                      </Col>
                    );
                  })}
                </>
              ) : null}
              {permissions?.includes("CAN-PO LIST-NOTIFICATION") ? (
                <>
                  <h6 className=" py-3 my-2">purchase orders</h6>
                  {purchaseRejectList?.map((noti, ind) => (
                    <Col xs={12} className="notificaion-text-box" key={ind}>
                      <p className="d-flex gap-5">
                        <span>{moment(noti?.po_date).format("LL")}</span>
                        <span>{noti?.po_no ?? "PO4567712"}</span>
                      </p>

                      <div>
                        {permissions?.includes(
                          "CAN-ACCEPT & DECLINE PO LIST-NOTIFICATION"
                        ) ? (
                          <button
                            className="border-0 rounded-1 bg-secondary my-2 text-white px-2 py-1 ms-2"
                            // onClick={() => notifyStatus(noti, 0)}
                            onClick={() => {
                              setDeclines(true);
                              setRemarksRej(noti?.approval?.remarks);
                            }}
                          >
                            Remarks
                          </button>
                        ) : null}
                        <img
                          onClick={() =>
                            purcaseOrderDetailHandler(
                              noti?.id,
                              noti?.po_no,
                              noti?.po_date
                            )
                          }
                          src={eye_icon}
                          alt="eye-icon"
                          style={{ width: "24px" }}
                          className="ms-3 pointer"
                        />
                      </div>
                    </Col>
                  ))}
                </>
              ) : null}{" "}
            </Tab>
            <Tab
              className={`${tab == 2 || ""}mb-2`}
              eventKey="request"
              title="Request"
              x
            >
              <h4 className="mb-1 mt-4 pb-2">Request Notifications</h4>
              {permissions?.includes("CAN-PROJECT BOQ LIST-NOTIFICATION") ? (
                <>
                  <h6 className=" py-3 my-2">Boq Amendment</h6>
                  {proBoq?.map((noti, ind) => {
                    // console.log(noti);
                    return (
                      <Col xs={12} className="notificaion-text-box" key={ind}>
                        <p className="d-flex gap-5">
                          <span>
                            {noti?.approval?.approvable?.created_at
                              ? moment(
                                  noti?.approval?.approvable?.created_at
                                ).format("LL")
                              : noti?.approvable?.created_at
                              ? moment(noti?.approvable?.created_at).format(
                                  "LL"
                                )
                              : noti?.previous_approved_list?.approvable
                                  ?.created_at
                              ? moment(
                                  noti?.previous_approved_list?.approvable
                                    ?.created_at
                                ).format("LL")
                              : ""}
                          </span>
                          <span style={{ width: "60px" }}>
                            {noti?.approval?.approvable?.boq_no ||
                              noti?.approvable?.boq_no ||
                              noti?.previous_approved_list?.approvable?.boq_no}
                          </span>
                          <span style={{ width: "400px" }}>
                            {noti?.approval?.approvable?.project?.name ||
                              noti?.approvable?.project?.name ||
                              noti?.previous_approved_list?.approvable?.project
                                ?.name}
                          </span>
                        </p>
                        <div className="d-flex gap-2">
                          {permissions?.includes(
                            // "CAN-APPROVAL OR DECLINE BOQ AMENDMENT-BILLING & BUDGET"
                            "CAN-ACCEPT & DECLINE PROJECT BOQ LIST-NOTIFICATION"
                          ) ? (
                            <div className="d-flex gap-2">
                              <button
                                className="border-0 rounded-1 bg-primary my-2 text-white px-2 py-1 me-2"
                                onClick={() => notifyStatus(noti, 1)}
                              >
                                Accept
                              </button>
                              <button
                                className="border-0 rounded-1 bg-secondary my-2 text-white px-2 py-1 ms-2"
                                // onClick={() => notifyStatus(noti, 0)}
                                onClick={() => {
                                  setDecline(true);
                                  setRemData(noti);
                                }}
                              >
                                Decline
                              </button>
                            </div>
                          ) : null}
                          <img
                            onClick={() => {
                              navigate("/notification-request", {
                                state: { listData: noti },
                              });
                              console.log(noti);
                            }}
                            src={eye_icon}
                            alt="eye-icon"
                            style={{ width: "24px" }}
                            className="ms-3 pointer"
                          />
                        </div>
                      </Col>
                    );
                  })}
                </>
              ) : null}{" "}
              {permissions?.includes("CAN-MATERIAL LIST-NOTIFICATION") ? (
                <>
                  <h6 className="mt-1 pt-2 pb-3 mb-2">Material Requests</h6>
                  {matrialList?.map((item, index) => {
                    return (
                      <Col
                        key={index}
                        xs={12}
                        className="notificaion-text-box pointer"
                      >
                        <p className="d-flex gap-5">
                          <span>
                            {item?.approval?.approvable?.mr_date
                              ? moment(
                                  item?.approval?.approvable?.mr_date
                                ).format("LL")
                              : item?.approvable?.mr_date
                              ? moment(item?.approvable?.mr_date).format("LL")
                              : item?.previous_approved_list?.approvable
                                  ?.mr_date
                              ? moment(
                                  item?.previous_approved_list?.approvable
                                    ?.mr_date
                                ).format("LL")
                              : ""}
                          </span>
                          <span>
                            {/* {"Noti :" + item?.id}{" "}
                      {"Mat_req :" + item?.material_request?.id}{" "} */}
                            {item?.approval?.approvable?.mr_no ||
                              item?.approvable?.mr_no ||
                              item?.previous_approved_list?.approvable?.mr_no}
                          </span>
                          <span style={{ width: "400px" }}>
                            {item?.approval?.approvable?.project?.name ||
                              item?.approvable?.project?.name ||
                              item?.previous_approved_list?.approvable?.project
                                ?.name}
                          </span>
                        </p>
                        <div className="d-flex gap-2">
                          {permissions?.includes(
                            "CAN-ACCEPT & DECLINE MATERIAL LIST-NOTIFICATION"
                          ) ? (
                            <div className="d-flex gap-2">
                              <button
                                className="border-0 rounded-1 bg-primary my-2 text-white px-2 py-1 me-2"
                                onClick={() => notifyStatus(item, 1)}
                              >
                                Accept
                              </button>
                              <button
                                className="border-0 rounded-1 bg-secondary my-2 text-white px-2 py-1 ms-2"
                                // onClick={() => notifyStatus(item, 0)}
                                onClick={() => {
                                  setDecline(true);
                                  setRemData(item);
                                }}
                              >
                                Decline
                              </button>
                            </div>
                          ) : null}
                          <button
                            onClick={() =>
                              materialViewHandler(
                                item?.approval?.approvable?.id ||
                                  item?.approvable?.id ||
                                  item?.previous_approved_list?.approvable?.id
                              )
                            }
                            className="cust-btn"
                          >
                            <img
                              src={eye_icon}
                              alt="eye-icon"
                              style={{ width: "24px" }}
                              className="ms-3 pointer"
                            />
                          </button>
                        </div>
                      </Col>
                    );
                  })}
                </>
              ) : null}
              {permissions?.includes("CAN-PO LIST-NOTIFICATION") ? (
                <>
                  <h6 className=" py-3 my-2">purchase orders</h6>
                  {purchaseList?.map((noti, ind) => (
                    <Col xs={12} className="notificaion-text-box" key={ind}>
                      <p className="d-flex gap-5">
                        <span>
                          {noti?.approval?.approvable?.po_date
                            ? moment(
                                noti?.approval?.approvable?.po_date
                              ).format("LL")
                            : noti?.approvable?.po_date
                            ? moment(noti?.approvable?.po_date).format("LL")
                            : noti?.previous_approved_list?.approvable?.po_date
                            ? moment(
                                noti?.previous_approved_list?.approvable
                                  ?.po_date
                              ).format("LL")
                            : ""}
                        </span>
                        <span>
                          {noti?.approval?.approvable?.po_no ||
                            noti?.approvable?.po_no ||
                            noti?.previous_approved_list?.approvable?.po_no}
                        </span>
                        <span style={{ width: "400px" }}>
                          {noti?.approval?.approvable?.project?.name ||
                            noti?.approvable?.project?.name ||
                            noti?.previous_approved_list?.approvable?.project
                              ?.name}
                        </span>
                      </p>
                      <div className="d-flex gap-2">
                        {permissions?.includes(
                          "CAN-ACCEPT & DECLINE PO LIST-NOTIFICATION"
                        ) ? (
                          <div className="d-flex gap-2">
                            <button
                              className="border-0 rounded-1 bg-primary my-2 text-white px-2 py-1 me-2"
                              onClick={() => notifyStatus(noti, 1)}
                            >
                              Accept
                            </button>
                            <button
                              className="border-0 rounded-1 bg-secondary my-2 text-white px-2 py-1 ms-2"
                              // onClick={() => notifyStatus(noti, 0)}
                              onClick={() => {
                                setDecline(true);
                                setRemData(noti);
                              }}
                            >
                              Decline
                            </button>
                          </div>
                        ) : null}
                        <img
                          onClick={() =>
                            purcaseOrderDetailHandler(
                              noti?.approval?.approvable?.id ||
                                noti?.approvable?.approvable_id ||
                                noti?.previous_approved_list?.approvable?.id,
                              noti?.approval?.approvable?.po_no ||
                                noti?.approvable?.po_no ||
                                noti?.previous_approved_list?.approvable?.po_no,
                              noti?.approval?.approvable?.po_date ||
                                noti?.approvable?.po_date ||
                                noti?.previous_approved_list?.approvable
                                  ?.po_date
                            )
                          }
                          src={eye_icon}
                          alt="eye-icon"
                          style={{ width: "24px" }}
                          className="ms-3 pointer"
                        />
                      </div>
                    </Col>
                  ))}
                </>
              ) : null}{" "}
              {permissions?.includes("CAN-GRN LIST-NOTIFICATION") && (
                <>
                  <h6 className=" py-3 my-2">Work Order </h6>
                  {workOrderList?.map((noti, ind) => (
                    <Col xs={12} className="notificaion-text-box" key={ind}>
                      <p className="d-flex gap-5">
                        <span>
                          {noti?.approval?.approvable?.order_date
                            ? moment(
                                noti?.approval?.approvable?.order_date
                              ).format("LL")
                            : noti?.approvable?.order_date
                            ? moment(noti?.approvable?.order_date).format("LL")
                            : noti?.previous_approved_list?.approvable
                                ?.order_date
                            ? moment(
                                noti?.previous_approved_list?.approvable
                                  ?.order_date
                              ).format("LL")
                            : ""}
                        </span>
                        <span>
                          {noti?.approval?.approvable?.order_no ||
                            noti?.approvable?.order_no ||
                            noti?.previous_approved_list?.approvable?.order_no}
                        </span>
                        <span style={{ width: "400px" }}>
                          {noti?.approval?.approvable?.project?.name ||
                            noti?.approvable?.project?.name ||
                            noti?.previous_approved_list?.approvable?.project
                              ?.name}
                        </span>
                      </p>
                      <div className="d-flex gap-2">
                        <div className="d-flex gap-2">
                          {permissions?.includes(
                            "CAN-ACCEPT & DECLINE GRN LIST-NOTIFICATION"
                          ) ? (
                            <>
                              <button
                                className="border-0 rounded-1 bg-primary my-2 text-white px-2 py-1 me-2"
                                onClick={() => notifyStatus(noti, 1)}
                              >
                                Accept
                              </button>
                              <button
                                className="border-0 rounded-1 bg-secondary my-2 text-white px-2 py-1 ms-2"
                                // onClick={() => notifyStatus(noti, 0)}
                                onClick={() => {
                                  setDecline(true);
                                  setRemData(noti);
                                }}
                              >
                                Decline
                              </button>
                            </>
                          ) : null}
                          <img
                            onClick={() =>
                              // navigate("/procurement/work-order-output", {
                              //   state: { data: item },
                              // })
                              navigate("/procurement/work-order-output", {
                                state: {
                                  id:
                                    noti?.approval?.approvable?.id ||
                                    noti?.approvable?.id ||
                                    noti?.previous_approved_list?.approvable
                                      ?.id,
                                },
                              })
                            }
                            src={eye_icon}
                            alt="eye-icon"
                            style={{ width: "24px" }}
                            className="ms-3 pointer"
                          />
                        </div>
                      </div>
                    </Col>
                  ))}
                </>
              )}
              {permissions?.includes("CAN-GRN LIST-NOTIFICATION") && (
                <>
                  <h6 className=" py-3 my-2">GRN </h6>
                  {grnList?.map((noti, ind) => (
                    <Col xs={12} className="notificaion-text-box" key={ind}>
                      <p className="d-flex gap-5">
                        <span>
                          {noti?.approval?.approvable?.grn_date
                            ? moment(
                                noti?.approval?.approvable?.grn_date
                              ).format("LL")
                            : noti?.approvable?.grn_date
                            ? moment(noti?.approvable?.grn_date).format("LL")
                            : noti?.previous_approved_list?.approvable?.grn_date
                            ? moment(
                                noti?.previous_approved_list?.approvable
                                  ?.grn_date
                              ).format("LL")
                            : ""}
                        </span>
                        <span>
                          {noti?.approval?.approvable?.grn_no ||
                            noti?.approvable?.grn_no ||
                            noti?.previous_approved_list?.approvable?.grn_no}
                        </span>
                        <span style={{ width: "400px" }}>
                          {noti?.approval?.approvable?.project?.name ||
                            noti?.approvable?.project?.name ||
                            noti?.previous_approved_list?.approvable?.project
                              ?.name}
                        </span>
                      </p>
                      <div className="d-flex gap-2">
                        <div className="d-flex gap-2">
                          {permissions?.includes(
                            "CAN-ACCEPT & DECLINE GRN LIST-NOTIFICATION"
                          ) ? (
                            <>
                              <button
                                className="border-0 rounded-1 bg-primary my-2 text-white px-2 py-1 me-2"
                                onClick={() => notifyStatus(noti, 1)}
                              >
                                Accept
                              </button>
                              <button
                                className="border-0 rounded-1 bg-secondary my-2 text-white px-2 py-1 ms-2"
                                // onClick={() => notifyStatus(noti, 0)}
                                onClick={() => {
                                  setDecline(true);
                                  setRemData(noti);
                                }}
                              >
                                Decline
                              </button>
                            </>
                          ) : null}
                          <Link
                            to={`/ism/grn-output?id=${
                              noti?.approval?.approvable?.id ||
                              noti?.approvable?.approvable_id ||
                              noti?.previous_approved_list?.approvable?.id
                            }&method=noti`}
                          >
                            <img
                              onClick={() =>
                                navigate("/ism/grn-output", {
                                  state: {
                                    id:
                                      noti?.approval?.approvable?.id ||
                                      noti?.approvable?.approvable_id ||
                                      noti?.previous_approved_list?.approvable
                                        ?.id,
                                  },
                                })
                              }
                              src={eye_icon}
                              alt="eye-icon"
                              style={{ width: "24px" }}
                              className="ms-3 pointer"
                            />
                          </Link>
                        </div>
                      </div>
                    </Col>
                  ))}
                </>
              )}
              {permissions?.includes("CAN-INVOICE LIST-NOTIFICATION") && (
                <>
                  <h6 className=" py-3 my-2">Invoice </h6>
                  {invoiceList?.map((noti, ind) => (
                    <Col xs={12} className="notificaion-text-box" key={ind}>
                      <p className="d-flex gap-5">
                        <span>
                          {noti?.approval?.approvable?.invoice_date
                            ? moment(
                                noti?.approval?.approvable?.invoice_date
                              ).format("LL")
                            : noti?.approvable?.invoice_date
                            ? moment(noti?.approvable?.invoice_date).format(
                                "LL"
                              )
                            : noti?.previous_approved_list?.approvable
                                ?.invoice_date
                            ? moment(
                                noti?.previous_approved_list?.approvable
                                  ?.invoice_date
                              ).format("LL")
                            : ""}
                        </span>
                        <span>
                          {noti?.approval?.approvable?.invoice_no ||
                            noti?.approvable?.invoice_no ||
                            noti?.previous_approved_list?.approvable
                              ?.invoice_no}
                        </span>
                        <span style={{ width: "400px" }}>
                          {noti?.approval?.approvable?.project?.name ||
                            noti?.approvable?.project?.name ||
                            noti?.previous_approved_list?.approvable?.project
                              ?.name}
                        </span>
                      </p>
                      <div className="d-flex gap-2">
                        <div className="d-flex gap-2">
                          {permissions?.includes(
                            "CAN-ACCEPT & DECLINE INVOICE LIST-NOTIFICATION"
                          ) ? (
                            <>
                              <button
                                className="border-0 rounded-1 bg-primary my-2 text-white px-2 py-1 me-2"
                                onClick={() => notifyStatus(noti, 1)}
                              >
                                Accept
                              </button>
                              <button
                                className="border-0 rounded-1 bg-secondary my-2 text-white px-2 py-1 ms-2"
                                // onClick={() => notifyStatus(noti, 0)}
                                onClick={() => {
                                  setDecline(true);
                                  setRemData(noti);
                                }}
                              >
                                Decline
                              </button>{" "}
                            </>
                          ) : null}
                          <Link
                            to={`/ism/grn-output?id=${
                              noti?.approval?.approvable?.id ||
                              noti?.approvable?.approvable_id ||
                              noti?.previous_approved_list?.approvable_id ||
                              noti?.previous_approved_list?.approvable
                                ?.approvable_id
                            }&type=invoice&method=noti`}
                          >
                            <img
                              onClick={() =>
                                navigate("/ism/grn-output", {
                                  state: {
                                    id:
                                      noti?.approval?.approvable?.id ||
                                      noti?.approvable?.approvable_id ||
                                      noti?.previous_approved_list
                                        ?.approvable_id ||
                                      noti?.previous_approved_list?.approvable
                                        ?.approvable_id,
                                    type: "invoice",
                                  },
                                })
                              }
                              src={eye_icon}
                              alt="eye-icon"
                              style={{ width: "24px" }}
                              className="ms-3 pointer"
                            />
                          </Link>
                        </div>
                      </div>
                    </Col>
                  ))}
                </>
              )}
              {permissions?.includes(
                "CAN-MATERIAL ISSUE LIST-NOTIFICATION"
              ) && (
                <>
                  <h6 className=" py-3 my-2">Material Issue </h6>
                  {materialIssueList?.map((noti, ind) => (
                    <Col xs={12} className="notificaion-text-box" key={ind}>
                      <p className="d-flex gap-5">
                        <span>
                          {noti?.approval?.approvable?.issue_date
                            ? moment(
                                noti?.approval?.approvable?.issue_date
                              ).format("LL")
                            : noti?.approvable?.issue_date
                            ? moment(noti?.approvable?.issue_date).format("LL")
                            : noti?.previous_approved_list?.approvable
                                ?.issue_date
                            ? moment(
                                noti?.previous_approved_list?.approvable
                                  ?.issue_date
                              ).format("LL")
                            : ""}
                        </span>
                        <span>
                          {noti?.approval?.approvable?.issue_no ||
                            noti?.approvable?.issue_no ||
                            noti?.previous_approved_list?.approvable?.issue_no}
                        </span>
                        <span style={{ width: "400px" }}>
                          {noti?.approval?.approvable?.project?.name ||
                            noti?.approvable?.project?.name ||
                            noti?.previous_approved_list?.approvable?.project
                              ?.name}
                        </span>
                      </p>
                      <div className="d-flex gap-2">
                        <div className="d-flex gap-2">
                          {permissions?.includes(
                            "CAN-ACCEPT & DECLINE MATERIAL ISSUE LIST-NOTIFICATION"
                          ) ? (
                            <>
                              <button
                                className="border-0 rounded-1 bg-primary my-2 text-white px-2 py-1 me-2"
                                onClick={() => notifyStatus(noti, 1)}
                              >
                                Accept
                              </button>
                              <button
                                className="border-0 rounded-1 bg-secondary my-2 text-white px-2 py-1 ms-2"
                                // onClick={() => notifyStatus(noti, 0)}
                                onClick={() => {
                                  setDecline(true);
                                  setRemData(noti);
                                }}
                              >
                                Decline
                              </button>{" "}
                            </>
                          ) : null}
                          <Link
                            to={`/ism/output?id=${
                              noti?.approval?.approvable_id ||
                              noti?.previous_approved_list?.approvable_id
                            }`}
                          >
                            <img
                              // onClick={() =>
                              //   navigate("/ism/output", {
                              //     state: {
                              //       id:
                              //         noti?.approval?.approvable_id ||
                              //         noti?.previous_approved_list?.approvable_id,
                              //     },
                              //   })
                              // }
                              src={eye_icon}
                              alt="eye-icon"
                              style={{ width: "24px" }}
                              className="ms-3 pointer mt-3"
                            />
                          </Link>
                        </div>
                      </div>
                    </Col>
                  ))}
                </>
              )}
              {permissions?.includes(
                "CAN-INTERNAL RETURN LIST-NOTIFICATION"
              ) && (
                <>
                  <h6 className=" py-3 my-2">Internal Return </h6>
                  {internalReturnList?.map((noti, ind) => (
                    <Col xs={12} className="notificaion-text-box" key={ind}>
                      <p className="d-flex gap-5">
                        <span>
                          {noti?.approval?.approvable?.return_slip_date
                            ? moment(
                                noti?.approval?.approvable?.return_slip_date
                              ).format("LL")
                            : noti?.approvable?.return_slip_date
                            ? moment(noti?.approvable?.return_slip_date).format(
                                "LL"
                              )
                            : noti?.previous_approved_list?.approvable
                                ?.return_slip_date
                            ? moment(
                                noti?.previous_approved_list?.approvable
                                  ?.return_slip_date
                              ).format("LL")
                            : ""}
                        </span>
                        <span>
                          {noti?.approval?.approvable?.return_slip_no ||
                            noti?.approvable?.return_slip_no ||
                            noti?.previous_approved_list?.approvable
                              ?.return_slip_no}
                        </span>
                        <span style={{ width: "400px" }}>
                          {noti?.approval?.approvable?.project?.name ||
                            noti?.approvable?.project?.name ||
                            noti?.previous_approved_list?.approvable?.project
                              ?.name}
                        </span>
                      </p>
                      <div className="d-flex gap-2">
                        <div className="d-flex gap-2">
                          {permissions?.includes(
                            "CAN-ACCEPT & DECLINE INTERNAL RETURN LIST-NOTIFICATION"
                          ) ? (
                            <>
                              <button
                                className="border-0 rounded-1 bg-primary my-2 text-white px-2 py-1 me-2"
                                onClick={() => notifyStatus(noti, 1)}
                              >
                                Accept
                              </button>
                              <button
                                className="border-0 rounded-1 bg-secondary my-2 text-white px-2 py-1 ms-2"
                                // onClick={() => notifyStatus(noti, 0)}
                                onClick={() => {
                                  setDecline(true);
                                  setRemData(noti);
                                }}
                              >
                                Decline
                              </button>{" "}
                            </>
                          ) : null}
                          <Link
                            to={`/ism/output?id=${
                              noti?.approval?.approvable_id ||
                              noti?.previous_approved_list?.approvable_id
                            }&type=internal`}
                          >
                            <img
                              // onClick={() =>
                              //   navigate("/ism/output", {
                              //     state: {
                              //       id:
                              //         noti?.approval?.approvable_id ||
                              //         noti?.previous_approved_list?.approvable_id,
                              //       type: "internal",
                              //     },
                              //   })
                              // }
                              src={eye_icon}
                              alt="eye-icon"
                              style={{ width: "24px" }}
                              className="ms-3 pointer"
                            />
                          </Link>
                        </div>
                      </div>
                    </Col>
                  ))}
                </>
              )}
              {permissions?.includes(
                "CAN-MATERIAL TRANSFER LIST-NOTIFICATION"
              ) && (
                <>
                  <h6 className=" py-3 my-2">Matrial Transfer</h6>
                  {matrialTransferList?.map((noti, ind) => (
                    <Col xs={12} className="notificaion-text-box" key={ind}>
                      <p className="d-flex gap-5">
                        <span>
                          {noti?.approval?.approvable?.transfer_date
                            ? moment(
                                noti?.approval?.approvable?.transfer_date
                              ).format("LL")
                            : noti?.approvable?.transfer_date
                            ? moment(noti?.approvable?.transfer_date).format(
                                "LL"
                              )
                            : noti?.previous_approved_list?.approvable
                                ?.transfer_date
                            ? moment(
                                noti?.previous_approved_list?.approvable
                                  ?.transfer_date
                              ).format("LL")
                            : ""}
                        </span>
                        <span>
                          {noti?.approval?.approvable?.transfer_no ||
                            noti?.approvable?.transfer_no ||
                            noti?.previous_approved_list?.approvable
                              ?.transfer_no}
                        </span>
                        <span style={{ width: "400px" }}>
                          {noti?.approval?.approvable?.project?.name ||
                            noti?.approvable?.project?.name ||
                            noti?.previous_approved_list?.approvable?.project
                              ?.name}
                        </span>
                      </p>
                      <div className="d-flex gap-2">
                        <div className="d-flex gap-2">
                          {permissions?.includes(
                            "CAN-ACCEPT & DECLINE MATERIAL TRANSFER LIST-NOTIFICATION"
                          ) ? (
                            <>
                              <button
                                className="border-0 rounded-1 bg-primary my-2 text-white px-2 py-1 me-2"
                                onClick={() => notifyStatus(noti, 1)}
                              >
                                Accept
                              </button>
                              <button
                                className="border-0 rounded-1 bg-secondary my-2 text-white px-2 py-1 ms-2"
                                // onClick={() => notifyStatus(noti, 0)}
                                onClick={() => {
                                  setDecline(true);
                                  setRemData(noti);
                                }}
                              >
                                Decline
                              </button>{" "}
                            </>
                          ) : null}
                          <Link
                            to={`/ism/matrial/output?id=${
                              noti?.approval?.approvable_id ||
                              noti?.previous_approved_list?.approvable_id
                            }&path=mt&method=noti`}
                          >
                            <img
                              onClick={() =>
                                navigate("/ism/matrial/output", {
                                  state: {
                                    id:
                                      noti?.approval?.approvable_id ||
                                      noti?.previous_approved_list
                                        ?.approvable_id,
                                  },
                                })
                              }
                              src={eye_icon}
                              alt="eye-icon"
                              style={{ width: "24px" }}
                              className="ms-3 pointer"
                            />
                          </Link>
                        </div>
                      </div>
                    </Col>
                  ))}
                </>
              )}
              {permissions?.includes(
                "CAN-MATERIAL RECEIPT LIST-NOTIFICATION"
              ) && (
                <>
                  <h6 className=" py-3 my-2">Matrial Receipt</h6>
                  {matrialReceiptList?.map((noti, ind) => (
                    <Col xs={12} className="notificaion-text-box" key={ind}>
                      <p className="d-flex gap-5">
                        <span>
                          {noti?.approval?.approvable?.receipt_date
                            ? moment(
                                noti?.approval?.approvable?.receipt_date
                              ).format("LL")
                            : noti?.approvable?.receipt_date
                            ? moment(noti?.approvable?.receipt_date).format(
                                "LL"
                              )
                            : noti?.previous_approved_list?.approvable
                                ?.receipt_date
                            ? moment(
                                noti?.previous_approved_list?.approvable
                                  ?.receipt_date
                              ).format("LL")
                            : ""}
                        </span>
                        <span>
                          {noti?.approval?.approvable?.receipt_no ||
                            noti?.approvable?.receipt_no ||
                            noti?.previous_approved_list?.approvable
                              ?.receipt_no}
                        </span>
                        <span style={{ width: "400px" }}>
                          {noti?.approval?.approvable?.project?.name ||
                            noti?.approvable?.project?.name ||
                            noti?.previous_approved_list?.approvable?.project
                              ?.name}
                        </span>
                      </p>
                      <div className="d-flex gap-2">
                        <div className="d-flex gap-2">
                          {permissions?.includes(
                            "CAN-ACCEPT & DECLINE MATERIAL RECEIPT LIST-NOTIFICATION"
                          ) ? (
                            <>
                              <button
                                className="border-0 rounded-1 bg-primary my-2 text-white px-2 py-1 me-2"
                                onClick={() => notifyStatus(noti, 1)}
                              >
                                Accept
                              </button>
                              <button
                                className="border-0 rounded-1 bg-secondary my-2 text-white px-2 py-1 ms-2"
                                // onClick={() => notifyStatus(noti, 0)}
                                onClick={() => {
                                  setDecline(true);
                                  setRemData(noti);
                                }}
                              >
                                Decline
                              </button>{" "}
                            </>
                          ) : null}
                          <img
                            onClick={() =>
                              navigate("/ism/matrial/output", {
                                state: {
                                  id:
                                    noti?.approval?.approvable_id ||
                                    noti?.previous_approved_list?.approvable_id,
                                  type: "internal",
                                },
                              })
                            }
                            src={eye_icon}
                            alt="eye-icon"
                            style={{ width: "24px" }}
                            className="ms-3 pointer"
                          />
                        </div>
                      </div>
                    </Col>
                  ))}
                </>
              )}
              {permissions?.includes(
                "CAN-MATERIAL RETURN LIST-NOTIFICATION"
              ) && (
                <>
                  <h6 className=" py-3 my-2">Matrial Return</h6>
                  {matrialReturnList?.map((noti, ind) => (
                    <Col xs={12} className="notificaion-text-box" key={ind}>
                      <p className="d-flex gap-5">
                        <span>
                          {noti?.approval?.approvable?.return_date
                            ? moment(
                                noti?.approval?.approvable?.return_date
                              ).format("LL")
                            : noti?.approvable?.receipt_date
                            ? moment(noti?.approvable?.return_date).format("LL")
                            : noti?.previous_approved_list?.approvable
                                ?.return_date
                            ? moment(
                                noti?.previous_approved_list?.approvable
                                  ?.return_date
                              ).format("LL")
                            : ""}
                        </span>
                        <span>
                          {noti?.approval?.approvable?.return_no ||
                            noti?.approvable?.return_no ||
                            noti?.previous_approved_list?.approvable?.return_no}
                        </span>
                        <span style={{ width: "400px" }}>
                          {noti?.approval?.approvable?.project?.name ||
                            noti?.approvable?.project?.name ||
                            noti?.previous_approved_list?.approvable?.project
                              ?.name}
                        </span>
                      </p>
                      <div className="d-flex gap-2">
                        <div className="d-flex gap-2">
                          {permissions?.includes(
                            "CAN-ACCEPT & DECLINE MATERIAL RECEIPT LIST-NOTIFICATION"
                          ) ? (
                            <>
                              <button
                                className="border-0 rounded-1 bg-primary my-2 text-white px-2 py-1 me-2"
                                onClick={() => notifyStatus(noti, 1)}
                              >
                                Accept
                              </button>
                              <button
                                className="border-0 rounded-1 bg-secondary my-2 text-white px-2 py-1 ms-2"
                                // onClick={() => notifyStatus(noti, 0)}
                                onClick={() => {
                                  setDecline(true);
                                  setRemData(noti);
                                }}
                              >
                                Decline
                              </button>{" "}
                            </>
                          ) : null}
                          <Link
                            to={`/ism/output/material-return?id=${
                              noti?.approval?.approvable_id ||
                              noti?.previous_approved_list?.approvable_id
                            }&method=noti`}
                          >
                            <img
                              onClick={() =>
                                navigate("/ism/output/material-return", {
                                  state: {
                                    id:
                                      noti?.approval?.approvable_id ||
                                      noti?.previous_approved_list
                                        ?.approvable_id,
                                  },
                                })
                              }
                              src={eye_icon}
                              alt="eye-icon"
                              style={{ width: "24px" }}
                              className="ms-3 pointer"
                            />
                          </Link>
                        </div>
                      </div>
                    </Col>
                  ))}
                </>
              )}
            </Tab>
          </Tabs>
        </Col>

        <Modal
          show={viewModal}
          onHide={() => setViewModal(!viewModal)}
          centered
          backdrop="static"
        >
          <Modal.Header closeButton className="border-0 ms-auto"></Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-wrap w-90">
              <Col xs={6} className="my-1 py-1">
                <label className="py-1">Project Name</label>
                <p>{materialDetail?.project_name}</p>
              </Col>
              <Col xs={6} className="my-1 py-1">
                <label className="py-1">Mr No</label>
                <p>{materialDetail?.mr_no}</p>
              </Col>
              <Col xs={6} className="my-1 py-1">
                <label className="py-1">Mr Date</label>
                <p>{materialDetail?.mr_date}</p>
              </Col>
              <Col xs={6} className="my-1 py-1">
                <label className="py-1">Created Date</label>
                <p>{materialDetail?.created_at}</p>
              </Col>
              {/* <Col xs={6} className="my-1 py-1">
                <label className="py-1">Updated Date</label>
                <p>{materialDetail?.updated_at}</p>
              </Col> */}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button
              variant="secondary"
              onClick={() => setViewModal(!viewModal)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header
            closeButton
            className="border-0 ms-auto mb-0 pb-0"
          ></Modal.Header>
          <Modal.Body className="pt-0 mt-0 ">
            <div className="d-flex flex-column w-70 mx-auto">
              {permissions?.includes("CAN-PROJECT BOQ LIST-NOTIFICATION") &&
                proBoq?.length > 0 && (
                  <h6 className="my-1 py-1">
                    Boq Amendment request has been raised
                  </h6>
                )}

              {permissions?.includes("CAN-MATERIAL LIST-NOTIFICATION") &&
                matrialList?.length > 0 && (
                  <h6 className="my-1 py-1">
                    Material request has been raised
                  </h6>
                )}
              {permissions?.includes("CAN-PO LIST-NOTIFICATION") &&
                purchaseList?.length > 0 && (
                  <h6 className="my-1 py-1">Purchase order has been raised</h6>
                )}
              {permissions?.includes("CAN-GRN LIST-NOTIFICATION") &&
                grnList?.length > 0 && (
                  <h6 className="my-1 py-1">GRN has been raised</h6>
                )}

              {permissions?.includes("CAN-MATERIAL ISSUE LIST-NOTIFICATION") &&
                materialIssueList?.length > 0 && (
                  <h6 className="my-1 py-1">Material Issue has been raised</h6>
                )}

              {permissions?.includes("CAN-INVOICE LIST-NOTIFICATION") &&
                invoiceList?.length > 0 && (
                  <h6 className="my-1 py-1">Invoice has been raised</h6>
                )}
              {permissions?.includes("CAN-INVOICE LIST-NOTIFICATION") &&
                workOrderList?.length > 0 && (
                  <h6 className="my-1 py-1">Work Order has been raised</h6>
                )}
              {permissions?.includes(
                "CAN-MATERIAL RECEIPT LIST-NOTIFICATION"
              ) &&
                matrialReceiptList?.length > 0 && (
                  <h6 className="my-1 py-1">Matrial Receipt has been raised</h6>
                )}
              {permissions?.includes(
                "CAN-MATERIAL TRANSFER LIST-NOTIFICATION"
              ) &&
                matrialTransferList?.length > 0 && (
                  <h6 className="my-1 py-1">
                    Matrial Transfer has been raised
                  </h6>
                )}
              {permissions?.includes("CAN-INTERNAL RETURN LIST-NOTIFICATION") &&
                internalReturnList?.length > 0 && (
                  <h6 className="my-1 py-1">Internal Return has been raised</h6>
                )}
              {permissions?.includes("CAN-MATERIAL RETURN LIST-NOTIFICATION") &&
                matrialReturnList?.length > 0 && (
                  <h6 className="my-1 py-1">Matrial Return has been raised</h6>
                )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={decline}
          onHide={() => setDecline(false)}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header
            closeButton
            className="border-0 ms-auto mb-0 pb-0"
          ></Modal.Header>
          <Modal.Body className="pt-0 mt-0 ">
            <div className="d-flex flex-column w-70 mx-auto">
              <Col
                xs={12}
                sm={12}
                xl={12}
                className="inputinner-box text-start"
              >
                <label className="w-100">Remarks</label>
                <textarea
                  className="border-0 bg-lightBlue px-2 my-1 py-1"
                  onChange={(e) => setRemarks(e.target.value)}
                  value={remarks}
                />
              </Col>
              <div
                className="d-flex justify-content-end"
                onClick={() => declineRemarks(remData)}
              >
                <button className="border-0 rounded-1 bg-primary my-2 text-white px-2 py-1 me-2">
                  Submit
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={declines}
          onHide={() => setDeclines(false)}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header
            closeButton
            className="border-0 ms-auto mb-0 pb-0"
          ></Modal.Header>
          <Modal.Body className="pt-0 mt-0 ">
            <div className="d-flex flex-column w-70 mx-auto">
              <Col
                xs={12}
                sm={12}
                xl={12}
                className="inputinner-box text-start"
              >
                <label className="w-100">Remarks</label>
                <textarea
                  className="border-0 bg-lightBlue px-2 my-1 py-1"
                  onChange={(e) => setRemarksRej(e.target.value)}
                  value={remarksRej}
                  disabled
                />
              </Col>
              <div
                className="d-flex justify-content-end"
                onClick={() => declineRemarks(remData)}
              ></div>
            </div>
          </Modal.Body>
        </Modal>
      </Col>
      {/* {viewModal && (
        <div className="modal-table">
          <ViewNotification onclickViewHandler={onclickViewHandler} />
        </div>
      )} */}
    </>
  );
}

export default NotificationScreen;
