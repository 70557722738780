import React, { useState, useEffect } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import StatusModal from "../../component/StatusModal";
import { api } from "../../constants/Services";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../component/Header/Header";

import WorkOrderForm from "../../component/WorkOrder/WorkOrderForm";
import WorkOrderTable from "../../component/WorkOrder/WorkOrderTable";
import WorkOrderLastSection from "../../component/WorkOrder/WorkOrderLastSection";
import { upload } from "@testing-library/user-event/dist/upload";
import WorkOrderTableAmd from "../../component/WorkOrder/WorkOrderTableAmd";
import WorkOrderFormAmd from "../../component/WorkOrder/WorkOrderFormAmd";

function WorkOrderAmendmentScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const dataType = location?.state?.type;
  const [modalDisplay, setModalDisplay] = useState({
    upload_modal: false,
    print_modal: false,
  });
  const [workOrderDetails, setWorkOrderDetails] = useState([
    {
      order_type: "",
    },
  ]);
  const [showStatus, setShowStatus] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [vendors, setVendors] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [terms, setTerms] = useState("");
  const [quote, setQuote] = useState("");
  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [remarks, setRemarks] = useState("");
  const [tabelData, setTableData] = useState([]);
  const [tabelBoq, setTableBoq] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [workOrderList, setWorkOrderList] = useState([]);
  const [workOrderNo, setworkOrderNo] = useState("");

  const [projectNameErr, setProjectNameErr] = useState(false);
  const [vendorsErr, setVendorsErr] = useState(false);
  const [orderDateErr, setOrderDateErr] = useState(false);
  const [orderNoErr, setOrderNoErr] = useState(false);
  const [startDateErr, setStartDateErr] = useState(false);
  const [endDateErr, setEndDateErr] = useState(false);
  const [termsErr, setTermsErr] = useState(false);
  const [quoteErr, setQuoteErr] = useState(false);
  const [name1Err, setName1Err] = useState(false);
  const [name2Err, setName2Err] = useState(false);
  const [phone1Err, setPhone1Err] = useState(false);
  const [phone2Err, setPhone2Err] = useState(false);

  const statusModalClose = () => {
    setShowStatus(!showStatus);
    setTimeout(() => {
      setModalDisplay({ ...modalDisplay, print_modal: true });
    }, 2000);
  };

  const getView = () => {
    api
      .workOrderView(data?.id)
      .then((res) => {
        if (res?.status == "success") {
        } else {
          console.log("res", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const workOrderSubmitHandler = () => {
    if (
      projectName?.length == 0 ||
      vendors?.length == 0 ||
      orderDate?.length == 0 ||
      startDate?.length == 0 ||
      endDate?.length == 0 ||
      terms?.length == 0 ||
      quote?.length == 0 ||
      name1?.length == 0 ||
      name2?.length == 0 ||
      phone1?.length == 0 ||
      phone2?.length == 0
    ) {
      setProjectNameErr(true);
      setVendorsErr(true);
      setOrderDateErr(true);
      setStartDateErr(true);
      setEndDateErr(true);
      setTermsErr(true);
      setQuoteErr(true);
      setName1Err(true);
      setName2Err(true);
      setPhone1Err(true);
      setPhone2Err(true);
    } else {
      let formdata = new FormData();

      formdata.append("vendor_id", vendors);
      formdata.append("project_id", projectName);
      formdata.append("order_date", orderDate);
      formdata.append("start_date", startDate);
      formdata.append("end_date", endDate);
      formdata.append("terms", terms);
      formdata.append("quote_ref_no", quote);
      formdata.append("name_1", name1);
      formdata.append("name_2", name2);
      formdata.append("phone1", phone1);
      formdata.append("phone2", phone2);
      if (remarks?.length > 0) {
        formdata.append("remarks", remarks);
      }

      if (tabelData?.length > 0) {
        tabelData?.map((item, ind) => {
          formdata.append(
            `work_order_details[${ind}][boq_detail_id]`,
            item?.boq_detail_id
          );

          if (item?.basic_price) {
            formdata.append(
              `work_order_details[${ind}][basic_price]`,
              item?.basic_price
            );
          } else {
            formdata.append(
              `work_order_details[${ind}][basic_price]`,
              item?.basic_rate
            );
          }

          formdata.append(`work_order_details[${ind}][qty]`, item?.qty);
          formdata.append(
            `work_order_details[${ind}][net_value]`,
            item?.net_value
          );

          if (item?.misc) {
            formdata.append(`work_order_details[${ind}][misc]`, item?.misc);
          } else {
            formdata.append(
              `work_order_details[${ind}][misc]`,
              item?.misc_value
            );
          }

          formdata.append(
            `work_order_details[${ind}][total_value]`,
            item?.total_value
          );

          if (item?.remarks) {
            formdata.append(
              `work_order_details[${ind}][remarks]`,
              item?.remarks
            );
          }

          if (item?.description) {
            formdata.append(
              `work_order_details[${ind}][description]`,
              item?.description
            );
          }
        });
      }

      formdata.append("work_order_id", +workOrderNo);

      api
        .workOrderUpdate(formdata)
        .then((res) => {
          if (res?.status == "success") {
            navigate("/procurement/work-order-list");
          } else {
            console.log("res", res);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const [micsPoppup, setMicePoppup] = useState(false);
  const [search, setSearch] = useState();

  const poppupHandle = () => {
    setMicePoppup(!micsPoppup);
  };

  const getProject = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };

  useEffect(() => {
    getProject();
  }, []);

  const projectBoq = (id) => {
    api
      .material_request_boq(+id)
      .then((res) => {
        if (res?.status == "success") {
          setTableBoq(res?.boq_details);
        } else {
          console.log("res", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const projectSelect = (id) => {
    setProjectName(id);
    projectBoq(id);
    let finder = projectList?.find((i) => i?.id == id);
    // console.log("finder", finder);
    setProjectData(finder);
  };

  const getWorkOrders = () => {
    let formdata = new FormData();
    formdata.append("project_id", projectName);
    formdata.append("vendor_id", vendors);

    api
      .workOrders(formdata)
      .then((res) => {
        if (res?.status == "success") {
          setWorkOrderList(res?.work_order);
        } else {
          console.log("res", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (vendors && projectName) {
      getWorkOrders();
    }
  }, [vendors, projectName]);

  const [workOrderDetail, setWorkOrderDetail] = useState([]);
  const workOrderSelect = (id) => {
    setworkOrderNo(id);
    let finder = workOrderList?.find((i) => i?.id == +id);
    console.log("fin", finder);
    setTableData(finder?.work_order_detail);
    setName1(finder?.name_1);
    setName2(finder?.name_2);
    setPhone1(finder?.phone1);
    setPhone2(finder?.phone2);
    setRemarks(finder?.remarks);
    setOrderDate(finder?.order_date);
    setStartDate(finder?.start_date);
    setEndDate(finder?.end_date);
    setQuote(finder?.quote_ref_no);
    setTerms(finder?.terms);
  };

  return (
    <Col xs={12} className="py-2  px-1 p-md-3">
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <WorkOrderFormAmd
          workOrderNo={workOrderNo}
          workOrderSelect={workOrderSelect}
          workOrderList={workOrderList}
          setWorkOrderDetails={setWorkOrderDetails}
          workOrderDetails={workOrderDetails}
          projectList={projectList}
          setProjectName={setProjectName}
          projectName={projectName}
          projectNameErr={projectNameErr}
          vendors={vendors}
          setVendors={setVendors}
          vendorsErr={vendorsErr}
          setOrderDate={setOrderDate}
          orderDate={orderDate}
          setOrderNo={setOrderNo}
          orderNo={orderNo}
          orderDateErr={orderDateErr}
          orderNoErr={orderNoErr}
          setStartDate={setStartDate}
          startDate={startDate}
          startDateErr={startDateErr}
          setEndDate={setEndDate}
          endDate={endDate}
          endDateErr={endDateErr}
          setTerms={setTerms}
          terms={terms}
          termsErr={termsErr}
          setQuote={setQuote}
          quote={quote}
          quoteErr={quoteErr}
          projectData={projectData}
          projectSelect={projectSelect}
        />
        {tabelBoq?.length > 0 && (
          <WorkOrderTableAmd
            type={"work-order-amendment"}
            workOrderDetail={workOrderDetail}
            tabelBoq={tabelBoq}
            poppupHandle={poppupHandle}
            orderType={workOrderDetails?.order_type}
            tabelData={tabelData}
            setTableData={setTableData}
          />
        )}
        <WorkOrderLastSection
          name1={name1}
          setName1={setName1}
          name2={name2}
          setName2={setName2}
          phone1={phone1}
          setPhone1={setPhone1}
          phone2={phone2}
          setPhone2={setPhone2}
          remarks={remarks}
          setRemarks={setRemarks}
          name1Err={name1Err}
          name2Err={name2Err}
          phone1Err={phone1Err}
          phone2Err={phone2Err}
        />
        <Col xs={12} className="d-flex w-90">
          <Col xs={12} lg={6} className="ms-auto d-flex">
            <button className="bg-gray border-0 ms-auto me-3 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2">
              Cancel
            </button>
            <button
              className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
              onClick={workOrderSubmitHandler}
            >
              Save
            </button>
          </Col>
        </Col>
      </Col>
      <Modal
        show={modalDisplay?.upload_modal}
        centered
        onHide={() => setModalDisplay({ ...modalDisplay, upload_modal: false })}
      >
        <Modal.Header closeButton className="border-0">
          <label className="w-50 mx-auto my-1 py-1"> {}</label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <div className="d-flex justify-content-end flex-column my-3">
            <a
              href="http://erp-script.cresconprojects.com/public/excel/cresscon-annexure-sample.xlsx"
              target="_blank"
              rel="noreferrer"
              className="bg-primar border-0 rounded-1 text-white f-1 mx-auto fs-14 px-4 my-2 py-2 pointer"
            >
              Sample Download
            </a>
            <div class="input-group my-1">
              <input
                type="file"
                class="form-control border-0 bg-lightBlue my-1 w-90"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
              />
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={null}
              >
                Upload
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-primary"
            variant="primary"
            onClick={() => {
              setModalDisplay({
                ...modalDisplay,
                upload_modal: false,
              });
              setTimeout(navigate("/procurement/work-order-print"), 1000);
            }}
          >
            Submit
          </Button>
          <Button
            variant="secondary"
            onClick={() =>
              setModalDisplay({ ...modalDisplay, upload_modal: false })
            }
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal
        show={modalDisplay?.print_modal}
        centered
        onHide={() => setModalDisplay({ ...modalDisplay, print_modal: false })}>
        <Modal.Header closeButton className="border-0">
          <label className="w-50 mx-auto my-1 py-1"> Work Order No:{}</label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <p className="text-center fs-6">Do you want to Print the Output?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-primary"
            variant="primary"
            onClick={() =>
              setModalDisplay({
                ...modalDisplay,
                upload_modal: true,
                print_modal: false,
              })
            }>
            Print
          </Button>
          <Button
            variant="secondary"
            onClick={() =>
              setModalDisplay({ ...modalDisplay, print_modal: false })
            }>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* Misc Poppup start */}
      {micsPoppup && (
        <Modal
          show={micsPoppup}
          centered
          onHide={() => {
            setMicePoppup(!micsPoppup);
          }}
        >
          <Modal.Header closeButton className="border-0">
            <h5 className="mx-auto">Miscellaneous Screen</h5>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 my-1 py-1">
              <label style={{ width: "140px" }} className="fs-14 me-2">
                HSN Code
              </label>
              <input onChange={(e) => setSearch(e.target.value)} />
            </div>
            <div className="w-100 my-1 py-1">
              <label style={{ width: "140px" }} className="fs-14 me-2">
                HSN Description
              </label>
              <input
              //  value={list?.description ? list?.description : " "}
              />
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  Total Value
                </label>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input
                  className="w-50"
                  readOnly
                  type="text"
                  disabled
                  // value={miscModalDetails?.totalValue}
                />
              </Col>
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  Discount
                </label>
                <input
                  className="w-25"
                  type="number"
                  // value={
                  //   miscModalDetails?.discount_percent
                  //     ? miscModalDetails?.discount_percent
                  //     : ""
                  // }
                  // onChange={(e) => {
                  //   miscDiscounthandler(e.target.value);
                  //   // discounHandle(e.target.value);
                  // }}
                />
                <span className="ms-1">%</span>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input
                  className="w-50"
                  // value={
                  //   miscModalDetails?.discount_amount
                  //     ? miscModalDetails?.discount_amount
                  //     : " "
                  // }
                />
              </Col>
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  IGST
                </label>

                <input
                  className="w-25"
                  // value={list?.igst ? list?.igst : " "}
                />
                <span className="ms-1">%</span>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input
                  className="w-50"
                  // value={igstVal ? igstVal : " "}
                />
              </Col>
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  SGST
                </label>
                <input
                  className="w-25"
                  // value={list?.sgst ? list?.sgst : " "}
                />
                <span className="ms-1">%</span>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input
                  className="w-50"
                  // value={sgstVal ? sgstVal : " "}
                />
              </Col>
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  CGST
                </label>
                <input
                  className="w-25"
                  //  value={list?.cgst ? list?.cgst : " "}
                />
                <span className="ms-1">%</span>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input
                  className="w-50"
                  // value={cgstVal ? cgstVal : " "}
                />
              </Col>
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  Round Off
                </label>
                <button
                  className="border-0 me-1 bg-primary1"
                  // onClick={() => roundOffActionHandler("+")}
                >
                  +
                </button>
                <button
                  className="border-0 ms-1 bg-primary2"
                  // onClick={() => roundOffActionHandler("-")}
                >
                  -
                </button>
              </Col>
            </div>

            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  Net Value
                </label>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input
                  className="w-50"
                  readOnly
                  type="text"
                  disabled
                  // value={`${miscModalDetails?.discount_value ?? " "}`}
                />
              </Col>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button
              className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
              onClick={() => {
                setMicePoppup(!micsPoppup);
                // setList({});
                // setcgstVal("");
                // setigstVal("");
                // setsgstVal("");
                // setMiscModalDetails({});
              }}
            >
              Cancel
            </button>
            <button
              className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
              onClick={() => setMicePoppup(!micsPoppup)}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      )}
      {/* Misc Poppup end */}

      <Modal
        show={modalDisplay?.print_modal}
        centered
        onHide={() => setModalDisplay({ ...modalDisplay, print_modal: false })}
      >
        <Modal.Header closeButton className="border-0">
          <label className="w-50 mx-auto my-1 py-1"> {"statusMes"}</label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <div className="d-flex justify-content-end flex-column my-3">
            <a
              // href="http://erp-script.cresconprojects.com/public/excel/cresscon-annexure-sample.xlsx"
              // target="_blank"
              // rel="noreferrer"
              className="bg-primar border-0 rounded-1 text-white f-1 mx-auto fs-14 px-4 my-1 py-2 pointer"
            >
              Sample Download
            </a>
            <div class="input-group">
              <input
                type="file"
                class="form-control border-0 bg-lightBlue my-1 w-90"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
              />
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
              >
                Upload
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-primary"
            onClick={() =>
              setModalDisplay({
                ...modalDisplay,
                upload_modal: true,
                print_modal: false,
              })
            }
            variant="primary"
          >
            Submit
          </Button>
          <Button
            variant="secondary"
            onClick={() =>
              setModalDisplay({ ...modalDisplay, print_modal: false })
            }
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
        />
      )}
    </Col>
  );
}

export default WorkOrderAmendmentScreen;
