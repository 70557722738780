// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../../constants/Services";

const MaterialReceiptForm = ({
  projectList,
  selectProject,
  materialList,
  selectMatrialNo,
  transferNo,
  MTRDate,
  ProjectFrom,
  warehouseFrom,
  warehouseFromName,
  warehouseTo,
  setWarehouseTo,
  setReceiptDate,
  receiptDate,
  transferNoErr,
  ProjectErr,
  Project,
  setProject,
  warehouseToErr,
  receiptDateErr,
}) => {
  const [warehouseList, setWarehouseList] = useState();

  const [GRTransferNo, setGRTransferNo] = useState("");
  const [MTRNo, setMTRNo] = useState("");
  const [GRTransferDate, setGRTransferDate] = useState("");

  const [ProjectFromErr, setProjectFromErr] = useState(false);
  const [GRTransferNoErr, setGRTransferNoErr] = useState(false);
  const [MTRNoErr, setMTRNoErr] = useState(false);
  const [GRTransferDateErr, setGRTransferDateErr] = useState(false);
  const [MTRDateErr, setMTRDateErr] = useState(false);

  const [TransferType, setTransferType] = useState("");

  // console.log("ufyutyuioiuytryui", TransferType);

  const getWarehouseList = (id) => {
    api
      .warehousePojectView(id)
      .then((res) => {
        if (res?.status == "success") {
          setWarehouseList(res?.warehouse_project);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const selecters = (id) => {
    selectProject(id);
    getWarehouseList(id);
  };

  const filtered_date = (sel_date, MTR_date) => {
    let today = new Date();
    let selected_date = new Date(sel_date);
    let now = new Date(MTR_date);
    var defaultDate = now - 1000 * 60 * 60 * 24 * 1;
    defaultDate = new Date(defaultDate);
    if (selected_date > defaultDate || selected_date == defaultDate) {
      setReceiptDate(sel_date);
    }
  };

  // useEffect(() => {
  //   getWarehouseList();
  // }, []);

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">Material Receipt</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          <p
            style={{ minHeight: "2rem", width: "90%", marginRight: "auto",fontSize:"12px" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            Crescon Projects and Service Pvt Ltd
          </p>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>
          <select
            style={{ minHeight: "2.1rem" ,fontSize:"12px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => {
              selecters(e.target.value);
            }}
            value={Project}
          >
            <option>Select Project</option>
            {projectList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
          {Project?.length == 0 && ProjectErr && (
            <p className="err">Select Project</p>
          )}
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Transfer Number</label>
          <select
            style={{ minHeight: "2.1rem" ,fontSize:"12px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => selectMatrialNo(e.target.value)}
            value={transferNo}
          >
            <option>Select Transfer No.</option>
            {materialList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.transfer_no}
              </option>
            ))}
          </select>
          {transferNo?.length == 0 && transferNoErr && (
            <p className="err">Select Transfer No.</p>
          )}
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Transfer Date</label>
          <input style={{fontSize:"12px" }}
            disabled
            value={MTRDate}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
          />
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project From</label>
          <select
            style={{ minHeight: "2.1rem",fontSize:"12px"  }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            // onChange={(e) => setProjectFrom(e.target.value)}
            value={ProjectFrom}
            disabled
          >
            <option>Select Project</option>
            {projectList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>

          {ProjectFrom?.length == 0 && ProjectFromErr && (
            <p className="err">Select Project From </p>
          )}
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Warehouse From</label>
          {/* <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            value={warehouseFrom}
            disabled
          >
            {warehouseList?.map((item, ind) => {
              return (
                <option value={item?.id} key={ind}>
                  {item?.name}
                </option>
              );
            })}
          </select> */}
          <input style={{fontSize:"12px"}}
            disabled
            value={warehouseFromName}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
          />
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Warehouse To</label>
          <select
            style={{ minHeight: "2.1rem" ,fontSize:"12px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => setWarehouseTo(e.target.value)}
            value={warehouseTo}
          >
            <option>Select Warehouse To</option>
            {warehouseList?.map((item, ind) => {
              return (
                // item?.id !== +warehouseFrom && (
                <option value={item?.id} key={ind}>
                  {item?.name}
                </option>
              );
              // );
            })}
          </select>
          {warehouseTo?.length == 0 && warehouseToErr && (
            <p className="err">Select Warehouse To</p>
          )}
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Receipt Date</label>
          <input
            type="date" style={{fontSize:"12px"}}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
            onChange={(e) => filtered_date(e.target.value, MTRDate)}
            value={receiptDate}
          />
          {receiptDate?.length == 0 && receiptDateErr && (
            <p className="err">Select Receipt Date</p>
          )}
        </Col>
      </Col>
    </Col>
  );
};

export default MaterialReceiptForm;
