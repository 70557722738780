import React, { useState, useEffect } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import Header from "../../component/Header/Header";
import IsmMaterialIssueTable from "../../component/ism/IsmMaterialIssue/IsmMaterialIssueTable";
import IsmMaterialIssueFirstForm from "../../component/ism/IsmMaterialIssue/IsmMaterialIssueFirstForm";
import IsmMaterialIssueLastForm from "../../component/ism/IsmMaterialIssue/IsmMaterialIssueLastForm";
import { useNavigate } from "react-router";
import { api } from "../../constants/Services";
import useUser from "../../constants/Storage/userData";
import StatusModal from "../../component/StatusModal";

const IsmMaterialIssueScreen = () => {
  const [showStatus, setShowStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [projectList, setProjectList] = useState([]);
  const { user } = useUser();
  const [tableSel, setTableSel] = useState([]);

  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const [WareHouse, setWareHouse] = useState("");
  const [materialType, setMaterialType] = useState("");
  const [materialIssue, setMaterialIssue] = useState("");
  const [miDate, setMiDate] = useState("");
  const [project, setProject] = useState("");
  const [MaterialRequest, setMaterialRequest] = useState("");
  const [miIssueDate, setMiIssueDate] = useState("");
  const [MaterialRequestErr, setMaterialRequestErr] = useState(false);
  const [miDateErr, setMiDateErr] = useState(false);
  const [miIssueDateErr, setMiIssueDateErr] = useState(false);
  const [WareHouseErr, setWareHouseErr] = useState(false);
  const [materialTypeErr, setMaterialTypeErr] = useState(false);
  const [materialIssueErr, setMaterialIssueErr] = useState(false);
  const [projectErr, setProjectErr] = useState(false);
  const [materialTab, setMaterialTab] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [materials, setMaterials] = useState({});

  const statusModalClose = () => {
    setShowStatus(!showStatus);
  };

  const poPrintHandler = () => {
    setModal(!modal);
  };

  const getProject = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };

  useEffect(() => {
    getProject();
  }, []);

  const warehouseSelector = (id) => {
    setWareHouse(id);
    let formdata = new FormData();
    formdata.append("project_id", project);
    formdata.append("ware_house_id", id);
    api
      // .matertialIssueGetMaterial(formdata)
      .cumulativeAmount(formdata)
      .then((res) => {
        if (res?.status == "success") {
          let temp = [];
          let final = [];
          res?.grn_materials?.map((item, ind) => {
            item?.grn_detail?.map((mat, inx) => {
              temp.push(mat);
            });
          });
          res?.cumulative_values?.map((item, ind) => {
            let finder = temp.filter(
              (i) => i?.material?.material_code == item?.material_code
            );
            // console.log("finder", finder);

            if (item?.received_qty !== 0) {
              final.push({
                ...finder[0],
                received_qty_cumulative: item?.received_qty,
              });
            }
          });
          // console.log("temp", temp);
          // console.log("final", final);
          setMaterialList(final);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //
  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <IsmMaterialIssueFirstForm
        projectList={projectList}
        setWareHouse={setWareHouse}
        WareHouse={WareHouse}
        setMaterialType={setMaterialType}
        materialType={materialType}
        setMaterialIssue={setMaterialIssue}
        materialIssue={materialIssue}
        setMiDate={setMiDate}
        miDate={miDate}
        setProject={setProject}
        project={project}
        setMaterialRequest={setMaterialRequest}
        MaterialRequest={MaterialRequest}
        setWareHouseErr={setWareHouseErr}
        WareHouseErr={WareHouseErr}
        setMaterialTypeErr={setMaterialTypeErr}
        materialTypeErr={materialTypeErr}
        setMaterialIssueErr={setMaterialIssueErr}
        materialIssueErr={materialIssueErr}
        setProjectErr={setProjectErr}
        projectErr={projectErr}
        MaterialRequestErr={MaterialRequestErr}
        miDateErr={miDateErr}
        miIssueDateErr={miIssueDateErr}
        miIssueDate={miIssueDate}
        setMiIssueDate={setMiIssueDate}
        warehouseSelector={warehouseSelector}
        setMaterialTab={setMaterialTab}
        setMaterials={setMaterials}
      />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <IsmMaterialIssueTable
          setMaterialList={setMaterialList}
          materialList={materialList}
          project={project}
          setTableSel={setTableSel}
          tableSel={tableSel}
          setMaterialTab={setMaterialTab}
          materialTab={materialTab}
          setMaterials={setMaterials}
          materials={materials}
        />
      </Col>
      <IsmMaterialIssueLastForm
        setModal={setModal}
        projectList={projectList}
        setWareHouse={setWareHouse}
        WareHouse={WareHouse}
        setMaterialType={setMaterialType}
        materialType={materialType}
        setMaterialIssue={setMaterialIssue}
        materialIssue={materialIssue}
        setMiDate={setMiDate}
        miDate={miDate}
        setProject={setProject}
        project={project}
        setMaterialRequest={setMaterialRequest}
        MaterialRequest={MaterialRequest}
        setWareHouseErr={setWareHouseErr}
        WareHouseErr={WareHouseErr}
        setMaterialTypeErr={setMaterialTypeErr}
        materialTypeErr={materialTypeErr}
        setMaterialIssueErr={setMaterialIssueErr}
        materialIssueErr={materialIssueErr}
        setProjectErr={setProjectErr}
        projectErr={projectErr}
        setMiIssueDateErr={setMiIssueDateErr}
        setMiDateErr={setMiDateErr}
        setMaterialRequestErr={setMaterialRequestErr}
        materialTab={materialTab}
        miIssueDate={miIssueDate}
        setStatusMessage={setStatusMessage}
        setShowStatus={setShowStatus}
        materials={materials}
      />

      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
          statusMessage={statusMessage}
        />
      )}

      <Modal show={modal} centered onHide={() => setModal(!modal)}>
        <Modal.Header closeButton className="border-0">
          <label className="w-50 mx-auto my-1 py-1">Material Issue : </label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <p className="text-center fs-6">Do you want to Print the Output?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-primary"
            onClick={poPrintHandler}
            variant="primary"
          >
            Print
          </Button>
          <Button variant="secondary" onClick={() => setModal(!modal)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default IsmMaterialIssueScreen;
