import React, { useState, useEffect } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import StatusModal from "../../component/StatusModal";
import { api } from "../../constants/Services";
import { Form, useNavigate } from "react-router-dom";
import Header from "../../component/Header/Header";

import WorkOrderForm from "../../component/WorkOrder/WorkOrderForm";
import WorkOrderTable from "../../component/WorkOrder/WorkOrderTable";
import WorkOrderLastSection from "../../component/WorkOrder/WorkOrderLastSection";
import { upload } from "@testing-library/user-event/dist/upload";
import { BASE_FILE_URL } from "../../constants/Services/constants";

function WorkOrderMasterScreen() {
  const navigate = useNavigate();
  const [modalDisplay, setModalDisplay] = useState({
    upload_modal: false,
    print_modal: false,
  });
  const [model, setModel] = useState(false);
  const [viewTable, setViewTable] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [workOrderDetails, setWorkOrderDetails] = useState([
    {
      order_type: "",
    },
  ]);
  const [showStatus, setShowStatus] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [vendors, setVendors] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [terms, setTerms] = useState("");
  const [quote, setQuote] = useState("");
  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [remarks, setRemarks] = useState("");
  const [tabelData, setTableData] = useState([]);
  const [tabelBoq, setTableBoq] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [upload, setUpload] = useState("");
  const [projectType, setProjectType] = useState("");
  const [boqList, setBoqList] = useState([]);

  const [projectNameErr, setProjectNameErr] = useState(false);
  const [vendorsErr, setVendorsErr] = useState(false);
  const [orderDateErr, setOrderDateErr] = useState(false);
  const [orderNoErr, setOrderNoErr] = useState(false);
  const [startDateErr, setStartDateErr] = useState(false);
  const [endDateErr, setEndDateErr] = useState(false);
  const [termsErr, setTermsErr] = useState(false);
  const [quoteErr, setQuoteErr] = useState(false);
  const [name1Err, setName1Err] = useState(false);
  const [name2Err, setName2Err] = useState(false);
  const [phone1Err, setPhone1Err] = useState(false);
  const [phone2Err, setPhone2Err] = useState(false);
  const [boq, setBoq] = useState({});
  const [row, setRow] = useState([]);
  const [boqCheck, setBoqCheck] = useState([]);

  const statusModalClose = () => {
    setShowStatus(!showStatus);
    // setTimeout(() => {
    //   setModalDisplay({ ...modalDisplay, print_modal: true });
    // }, 2000);
  };

  const workOrderSubmitHandler = (method, num) => {
    if (
      projectName?.length == 0 ||
      vendors?.length == 0 ||
      orderDate?.length == 0 ||
      startDate?.length == 0 ||
      endDate?.length == 0 ||
      // terms?.length == 0 ||
      // quote?.length == 0 ||
      name1?.length == 0 ||
      name2?.length == 0 ||
      phone1?.length == 0 ||
      phone2?.length == 0
    ) {
      setProjectNameErr(true);
      setVendorsErr(true);
      setOrderDateErr(true);
      setStartDateErr(true);
      setEndDateErr(true);
      // setTermsErr(true);
      // setQuoteErr(true);
      setName1Err(true);
      setName2Err(true);
      setPhone1Err(true);
      setPhone2Err(true);
    } else {
      let formdata = new FormData();

      formdata.append("vendor_id", vendors);
      formdata.append("project_id", projectName);
      formdata.append("order_date", orderDate);
      formdata.append("start_date", startDate);
      formdata.append("end_date", endDate);
      formdata.append("terms", terms);
      formdata.append("quote_ref_no", quote);
      formdata.append("name_1", name1);
      formdata.append("name_2", name2);
      formdata.append("phone1", phone1);
      formdata.append("phone2", phone2);
      if (remarks?.length > 0) {
        formdata.append("remarks", remarks);
      }

      let count = 1;

      if (Object.values(boq)?.length > 0) {
        Object.values(boq)?.map((item, ind) => {
          if (tabelData?.includes(item?.id)) {
            if (item?.error == 1) {
              count = count + 1;
            }

            formdata.append(
              `work_order_details[${ind}][boq_detail_id]`,
              item?.id
            );

            formdata.append(
              `work_order_details[${ind}][basic_price]`,
              item?.basic_rate
            );
            formdata.append(`work_order_details[${ind}][qty]`, item?.qty);
            formdata.append(
              `work_order_details[${ind}][net_value]`,
              item?.net_value
            );
            formdata.append(
              `work_order_details[${ind}][misc]`,
              item?.misc_value
            );
            formdata.append(
              `work_order_details[${ind}][total_value]`,
              item?.total_value
            );

            if (item?.remarks) {
              formdata.append(
                `work_order_details[${ind}][remarks]`,
                item?.remarks
              );
            }

            if (item?.description) {
              formdata.append(
                `work_order_details[${ind}][description]`,
                item?.description
              );
            }
          }
        });
      }

      if (count == 1) {
        if (statusMessage?.data?.id) {
          uploadFile(statusMessage);
        } else {
          if (num == 1) {
            apiFunction(formdata, method);
          } else {
            submitPop();
          }
        }
      }
    }
  };

  const submitPop = () => {
    setModel(true);
  };

  const apiFunction = (formdata, method) => {
    api
      .workOrderAdd(formdata)
      .then((res) => {
        if (res?.status == "success") {
          setStatusMessage({
            status: res?.message,
            data: res?.work_order,
          });
          setModel(true);
          // navigate("/procurement/work-order-list");

          if (method == 1) {
            uploadFile({
              status: res?.message,
              data: res?.work_order,
            });
          } else {
            setModel(false);
            setShowStatus(true);
          }
        } else {
          console.log("res", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const [micsPoppup, setMicePoppup] = useState(false);
  const [search, setSearch] = useState();

  const poppupHandle = () => {
    setMicePoppup(!micsPoppup);
  };

  const getProject = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };

  useEffect(() => {
    getProject();
  }, []);

  const projectBoq = (id) => {
    api
      .material_request_boq(+id)
      .then((res) => {
        if (res?.status == "success") {
          setTableBoq(res?.boq_details);
        } else {
          console.log("res", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const projectSelect = (id) => {
    setProjectName(id);
    projectBoq(id);
    let finder = projectList?.find((i) => i?.id == id);
    // console.log("finder", finder);
    setProjectData(finder);
    setProjectType("");
    setBoqCheck([]);
    setRow([]);
    setTableData([]);
    setBoq({});
  };

  const uploadFile = (item) => {
    let data = {
      import_excel: upload,
      work_order_id: item?.data?.id,
    };
    if (upload) {
      api
        .workOrderImport(data)
        .then((res) => {
          alert(res?.message);
          if (res?.status == "success") {
            setUpload("");
            setModel(false);
            setShowStatus(true);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      alert("Please Upload File");
    }
  };

  // console.log("statusMessage", statusMessage);

  const getProductType = (event) => {
    setProjectType(event);
    setBoqCheck([]);
    setRow([]);
    setTableData([]);
    setBoq({});

    let temp = [...tabelBoq];
    let arr = [];

    if (event == "billable") {
      temp.map((item, ind) => {
        if (item?.material_code !== "NA") {
          arr.push(item);
        }
      });
    } else {
      temp.map((item, ind) => {
        if (item?.material_code == "NA") {
          arr.push(item);
        }
      });
    }
    setBoqList(arr);
    setViewTable(true);
  };

  const checkBoxHandler = (id) => {
    let temp = [...boqCheck];
    let inx = temp.indexOf(id);
    if (temp.includes(id)) {
      temp.splice(inx, 1);
    } else {
      temp.push(id);
    }

    setBoqCheck(temp);
  };

  const boqHandler = () => {
    let temp = [...boqList];
    let arr = [];
    let inx = [];

    let finder = temp.filter((item) => boqCheck.includes(item?.id));
    finder?.map((item, ind) => {
      setBoq((val) => ({ ...val, [ind]: item }));
      arr.push(item?.id);
      inx.push(ind);
    });
    setRow(inx);
    setTableData(arr);
    setViewTable(false);
  };

  // console.log("boq", boq);

// const [size, setSize]=useState("");

  return (

    <Col xs={12} className={`py-2  px-1 p-md-3  ${row?.length > 0 && 'w-80 m-0'}`}>
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <WorkOrderForm
          setWorkOrderDetails={setWorkOrderDetails}
          workOrderDetails={workOrderDetails}
          projectList={projectList}
          setProjectName={setProjectName}
          projectName={projectName}
          projectNameErr={projectNameErr}
          vendors={vendors}
          setVendors={setVendors}
          vendorsErr={vendorsErr}
          setOrderDate={setOrderDate}
          orderDate={orderDate}
          setOrderNo={setOrderNo}
          orderNo={orderNo}
          orderDateErr={orderDateErr}
          orderNoErr={orderNoErr}
          setStartDate={setStartDate}
          startDate={startDate}
          startDateErr={startDateErr}
          setEndDate={setEndDate}
          endDate={endDate}
          endDateErr={endDateErr}
          setTerms={setTerms}
          terms={terms}
          termsErr={termsErr}
          setQuote={setQuote}
          quote={quote}
          quoteErr={quoteErr}
          projectData={projectData}
          projectSelect={projectSelect}
          getProductType={getProductType}
          projectType={projectType}
        />
        {row?.length > 0 && (
          <WorkOrderTable
            tabelBoq={tabelBoq}
            poppupHandle={poppupHandle}
            orderType={workOrderDetails?.order_type}
            tabelData={tabelData}
            setTableData={setTableData}
            boq={boq}
            setBoq={setBoq}
            row={row}
            setRow={setRow}
            setViewTable={setViewTable}
          />
        )}
        <WorkOrderLastSection
          name1={name1}
          setName1={setName1}
          name2={name2}
          setName2={setName2}
          phone1={phone1}
          setPhone1={setPhone1}
          phone2={phone2}
          setPhone2={setPhone2}
          remarks={remarks}
          setRemarks={setRemarks}
          name1Err={name1Err}
          name2Err={name2Err}
          phone1Err={phone1Err}
          phone2Err={phone2Err}
        />
        <Col xs={12} className="d-flex w-90">
          <Col xs={12} lg={6} className="ms-auto d-flex">
            <button className="bg-gray border-0 ms-auto me-3 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2">
              Cancel
            </button>
            <button
              className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
              onClick={workOrderSubmitHandler}
              // onClick={submitPop}
            >
              Save
            </button>
          </Col>
        </Col>
      </Col>

      <Modal
        show={modalDisplay?.upload_modal}
        centered
        onHide={() => setModalDisplay({ ...modalDisplay, upload_modal: false })}
      >
        <Modal.Header closeButton className="border-0">
          <label className="w-50 mx-auto my-1 py-1"> {}</label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <div className="d-flex justify-content-end flex-column my-3">
            <a
              href={`${BASE_FILE_URL}excel/work_order-annexure-sample.xlsx`}
              target="_blank"
              rel="noreferrer"
              className="bg-primar border-0 rounded-1 text-white f-1 me-auto fs-14 px-4 py-2 pointer"
            >
              Sample Download
            </a>
            <div class="input-group my-1">
              <input
                type="file"
                class="form-control border-0 bg-lightBlue my-1 w-90"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
              />
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={null}
              >
                Upload
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-primary"
            variant="primary"
            onClick={() => {
              setModalDisplay({
                ...modalDisplay,
                upload_modal: false,
              });
              setTimeout(navigate("/procurement/work-order-print"), 1000);
            }}
          >
            Submit
          </Button>
          <Button
            variant="secondary"
            onClick={() =>
              setModalDisplay({ ...modalDisplay, upload_modal: false })
            }
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal
        show={modalDisplay?.print_modal}
        centered
        onHide={() => setModalDisplay({ ...modalDisplay, print_modal: false })}>
        <Modal.Header closeButton className="border-0">
          <label className="w-50 mx-auto my-1 py-1"> Work Order No:{}</label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <p className="text-center fs-6">Do you want to Print the Output?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-primary"
            variant="primary"
            onClick={() =>
              setModalDisplay({
                ...modalDisplay,
                upload_modal: true,
                print_modal: false,
              })
            }>
            Print
          </Button>
          <Button
            variant="secondary"
            onClick={() =>
              setModalDisplay({ ...modalDisplay, print_modal: false })
            }>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* Misc Poppup start */}
      {micsPoppup && (
        <Modal
          show={micsPoppup}
          centered
          onHide={() => {
            setMicePoppup(!micsPoppup);
          }}
        >
          <Modal.Header closeButton className="border-0">
            <h5 className="mx-auto">Miscellaneous Screen</h5>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 my-1 py-1">
              <label style={{ width: "140px" }} className="fs-14 me-2">
                HSN Code
              </label>
              <input onChange={(e) => setSearch(e.target.value)} />
            </div>
            <div className="w-100 my-1 py-1">
              <label style={{ width: "140px" }} className="fs-14 me-2">
                HSN Description
              </label>
              <input
              //  value={list?.description ? list?.description : " "}
              />
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  Total Value
                </label>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input
                  className="w-50"
                  readOnly
                  type="text"
                  disabled
                  // value={miscModalDetails?.totalValue}
                />
              </Col>
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  Discount
                </label>
                <input
                  className="w-25"
                  type="number"
                  // value={
                  //   miscModalDetails?.discount_percent
                  //     ? miscModalDetails?.discount_percent
                  //     : ""
                  // }
                  // onChange={(e) => {
                  //   miscDiscounthandler(e.target.value);
                  //   // discounHandle(e.target.value);
                  // }}
                />
                <span className="ms-1">%</span>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input
                  className="w-50"
                  // value={
                  //   miscModalDetails?.discount_amount
                  //     ? miscModalDetails?.discount_amount
                  //     : " "
                  // }
                />
              </Col>
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  IGST
                </label>

                <input
                  className="w-25"
                  // value={list?.igst ? list?.igst : " "}
                />
                <span className="ms-1">%</span>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input
                  className="w-50"
                  // value={igstVal ? igstVal : " "}
                />
              </Col>
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  SGST
                </label>
                <input
                  className="w-25"
                  // value={list?.sgst ? list?.sgst : " "}
                />
                <span className="ms-1">%</span>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input
                  className="w-50"
                  // value={sgstVal ? sgstVal : " "}
                />
              </Col>
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  CGST
                </label>
                <input
                  className="w-25"
                  //  value={list?.cgst ? list?.cgst : " "}
                />
                <span className="ms-1">%</span>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input
                  className="w-50"
                  // value={cgstVal ? cgstVal : " "}
                />
              </Col>
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  Round Off
                </label>
                <button
                  className="border-0 me-1 bg-primary1"
                  // onClick={() => roundOffActionHandler("+")}
                >
                  +
                </button>
                <button
                  className="border-0 ms-1 bg-primary2"
                  // onClick={() => roundOffActionHandler("-")}
                >
                  -
                </button>
              </Col>
            </div>

            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  Net Value
                </label>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input
                  className="w-50"
                  readOnly
                  type="text"
                  disabled
                  // value={`${miscModalDetails?.discount_value ?? " "}`}
                />
              </Col>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button
              className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
              onClick={() => {
                setMicePoppup(!micsPoppup);
                // setList({});
                // setcgstVal("");
                // setigstVal("");
                // setsgstVal("");
                // setMiscModalDetails({});
              }}
            >
              Cancel
            </button>
            <button
              className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
              onClick={() => setMicePoppup(!micsPoppup)}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      )}
      {/* Misc Poppup end */}

      <Modal show={model} centered onHide={() => setModel(!model)}>
        <Modal.Header closeButton className="border-0">
          <label className="w-50 mx-auto my-1 py-1">
            {" "}
            {statusMessage?.status
              ? statusMessage?.status + " " + statusMessage?.data?.order_no
              : ""}
          </label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <div className="d-flex justify-content-end flex-column my-3">
            <a
              href={`${BASE_FILE_URL}excel/work_order-annexure-sample.xlsx`}
              target="_blank"
              rel="noreferrer"
              className="bg-primar border-0 rounded-1 text-white f-1 me-auto fs-14 px-4 py-2 pointer"
            >
              Sample Download
            </a>
            <div class="input-group">
              <input
                type="file"
                class="form-control border-0 bg-lightBlue my-1 w-90"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                onChange={(e) => setUpload(e.target.files[0])}
              />
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                // onClick={uploadFile}
                onClick={() => workOrderSubmitHandler(1, 1)}
              >
                Upload
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-primary"
            onClick={() => {
              workOrderSubmitHandler(2, 1);
            }}
            variant="primary"
          >
            Submit
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              // setModel(!model);
              // setShowStatus(true);
              window.location.reload();
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
          statusMessage={statusMessage}
        />
      )}

      <Modal
        show={viewTable}
        onHide={() => setViewTable(!viewTable)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-0">
          <label className="w-100 mx-auto my-1 py-1">
            {" "}
            {projectType == "billable" ? "Billable BOQ" : "Non Billable BOQ"}
          </label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <div
            className="d-flex justify-content-end flex-column my-3"
            // style={{ width: "500px", height: "300px", overflowY: "scroll" }}
          >
            <Col className="emply-master-form py-2  mt-3 text-center">
              <div
                className="boq-table bg-white new_tab"
                style={{ height: "500px", overflow: "scroll" }}
              >
                <table className="table">
                  <thead className="text-center">
                    <tr>
                      <th>S.NO</th>
                      <th>BOQ Code</th>
                      <th>BOQ Description</th>
                      <th>Qty</th>
                      <th>Basic Rate</th>
                    </tr>
                  </thead>
                  <tbody className="text-center"></tbody>
                  {boqList?.map((item, ind) => {
                    return (
                      <tr key={ind} className="mt-2">
                        <td>
                          <div className="d-flex gap-2">
                            <p>{ind + 1}</p>
                            <input
                              type={"checkbox"}
                              onClick={() => checkBoxHandler(item?.id)}
                              checked={
                                boqCheck?.includes(item?.id) ? true : false
                              }
                            />
                          </div>
                        </td>
                        <td>{item?.boq_code}</td>
                        <td style={{ textAlign: "start" }}>
                          {item?.boq_description}
                        </td>
                        <td>{item?.qty}</td>
                        <td>{item?.basic_rate}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </Col>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              boqHandler();
            }}
            variant="primary"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}

export default WorkOrderMasterScreen;
