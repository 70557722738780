import React, { useState, useEffect } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import Header from "../../component/Header/Header";
import IsmGinForm from "../../component/ism/IsmGin/IsmGinForm";
import IsmGinTable from "../../component/ism/IsmGin/IsmGinTable";
import IsmGinLastForm from "../../component/ism/IsmGin/IsmGinLastSecForm";
import createUtilityClassName from "react-bootstrap/esm/createUtilityClasses";
import StatusModal from "../../component/StatusModal";

const IsmGinScreen = () => {
  const [dateError, setDateError] = useState([]);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const poPrintHandler = () => {
    setModal(!modal);
  };

  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState([]);
  const [poTableStaticFields, setPoTableStaticFields] = useState({
    rec_qty: {},
    test_certificate: {},
    warranty_certificate: {},
    mrir_certificate: {},
    basic_rate: {},
    net_value: {},
    remarks: {},
  });

  const [projectType, setProjectType] = useState("");
  const [projectName, setProjectName] = useState("");
  const [pRDDate, setPRDDate] = useState("");
  const [prNo, setPrNo] = useState("");
  const [PONo, setPONo] = useState("");
  const [DCNo, setDCNo] = useState("");
  const [DCDate, setDCDate] = useState("");
  const [GRNDate, setGRNDate] = useState("");
  const [InvoiceNo, setInvoiceNo] = useState("");
  const [grnFile, setGrnFile] = useState("");
  const [InvoiceDate, setInvoiceDate] = useState("");
  const [wareHouse, setWareHouse] = useState("");
  const [details, setDetails] = useState([]);
  const [vendor, setVendor] = useState("");
  const [matrial, setMatrial] = useState({});

  const [projectTypeErr, setProjectTypeErr] = useState(false);
  const [projectNameErr, setProjectNameErr] = useState(false);
  const [PONoErr, setPONoErr] = useState(false);
  const [DCNoErr, setDCNoErr] = useState(false);
  const [GRNNoErr, setGRNNoErr] = useState(false);
  const [DCDateErr, setDCDateErr] = useState(false);
  const [GRNDateErr, setGRNDateErr] = useState(false);
  const [InvoiceNoErr, setInvoiceNoErr] = useState(false);
  const [wareHouseErr, setWareHouseErr] = useState(false);
  const [InvoiceDateErr, setInvoiceDateErr] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  useEffect(() => {
    if (purchaseOrderDetails?.po_table?.length > 0) {
      purchaseOrderDetails?.po_table?.map((poDetail, ind) => {
        setPoTableStaticFields({
          ...poTableStaticFields,
          basic_rate: {
            ...poTableStaticFields?.basic_rate,
            [poDetail?.id]: poDetail?.basic_price,
          },
          remarks: {
            ...poTableStaticFields?.remarks,
            [poDetail?.id]: poDetail?.remarks,
          },
        });
      });
    }
  }, [purchaseOrderDetails]);

  const statusModalClose = () => {
    setShowStatus(!showStatus);
    setTimeout(() => window.location.reload(), 2000);
  };

  return (
    <Col xs={12} className={`py-2 px-1 p-md-3  m-0 ${purchaseOrderDetails?.purchase_order_details?.length > 0 && "w-80 m-0"}`}>
      <Header />
      <IsmGinForm
        setDetails={setDetails}
        purchaseOrderDetails={purchaseOrderDetails}
        setPurchaseOrderDetails={setPurchaseOrderDetails}
        setDateError={setDateError}
        dateError={dateError}
        projectType={projectType}
        projectTypeErr={projectTypeErr}
        setProjectType={setProjectType}
        projectName={projectName}
        setProjectName={setProjectName}
        pRDDate={pRDDate}
        setPRDDate={setPRDDate}
        prNo={prNo}
        setPrNo={setPrNo}
        PONo={PONo}
        setPONo={setPONo}
        DCNo={DCNo}
        setDCNo={setDCNo}
        DCDate={DCDate}
        setDCDate={setDCDate}
        GRNDate={GRNDate}
        setGRNDate={setGRNDate}
        InvoiceNo={InvoiceNo}
        setInvoiceNo={setInvoiceNo}
        grnFile={grnFile}
        setGrnFile={setGrnFile}
        setInvoiceDate={setInvoiceDate}
        InvoiceDate={InvoiceDate}
        projectNameErr={projectNameErr}
        setProjectNameErr={setProjectNameErr}
        PONoErr={PONoErr}
        setPONoErr={setPONoErr}
        DCNoErr={DCNoErr}
        setDCNoErr={setDCNoErr}
        GRNNoErr={GRNNoErr}
        setGRNNoErr={setGRNNoErr}
        DCDateErr={DCDateErr}
        setDCDateErr={setDCDateErr}
        GRNDateErr={GRNDateErr}
        setGRNDateErr={setGRNDateErr}
        InvoiceNoErr={InvoiceNoErr}
        setInvoiceNoErr={setInvoiceNoErr}
        InvoiceDateErr={InvoiceDateErr}
        setInvoiceDateErr={setInvoiceDateErr}
        setWareHouseErr={setWareHouseErr}
        wareHouseErr={wareHouseErr}
        wareHouse={wareHouse}
        setWareHouse={setWareHouse}
        setVendor={setVendor}
        vendor={vendor}
      />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center ">
        {purchaseOrderDetails?.purchase_order_details?.length > 0 && (
          <IsmGinTable
            projectType={projectType}
            purchaseOrderDetails={purchaseOrderDetails}
            setPoTableStaticFields={setPoTableStaticFields}
            poTableStaticFields={poTableStaticFields}
            setDetails={setDetails}
            details={details}
            setMatrial={setMatrial}
            matrial={matrial}
          />
        )}
      </Col>
      <IsmGinLastForm
        setModal={setModal}
        projectType={projectType}
        setProjectType={setProjectType}
        projectName={projectName}
        setProjectName={setProjectName}
        pRDDate={pRDDate}
        setPRDDate={setPRDDate}
        prNo={prNo}
        setPrNo={setPrNo}
        PONo={PONo}
        setPONo={setPONo}
        DCNo={DCNo}
        setDCNo={setDCNo}
        DCDate={DCDate}
        setDCDate={setDCDate}
        GRNDate={GRNDate}
        setGRNDate={setGRNDate}
        InvoiceNo={InvoiceNo}
        setInvoiceNo={setInvoiceNo}
        grnFile={grnFile}
        setGrnFile={setGrnFile}
        setInvoiceDate={setInvoiceDate}
        InvoiceDate={InvoiceDate}
        projectNameErr={projectNameErr}
        setProjectNameErr={setProjectNameErr}
        PONoErr={PONoErr}
        setPONoErr={setPONoErr}
        DCNoErr={DCNoErr}
        setDCNoErr={setDCNoErr}
        GRNNoErr={GRNNoErr}
        setGRNNoErr={setGRNNoErr}
        DCDateErr={DCDateErr}
        setDCDateErr={setDCDateErr}
        GRNDateErr={GRNDateErr}
        setGRNDateErr={setGRNDateErr}
        InvoiceNoErr={InvoiceNoErr}
        setInvoiceNoErr={setInvoiceNoErr}
        InvoiceDateErr={InvoiceDateErr}
        setInvoiceDateErr={setInvoiceDateErr}
        setWareHouseErr={setWareHouseErr}
        wareHouseErr={wareHouseErr}
        wareHouse={wareHouse}
        setWareHouse={setWareHouse}
        setDetails={setDetails}
        details={details}
        setVendor={setVendor}
        vendor={vendor}
        setShowStatus={setShowStatus}
        setStatusMessage={setStatusMessage}
        setProjectTypeErr={setProjectTypeErr}
        matrial={matrial}
      />
      <Modal show={modal} centered onHide={() => setModal(!modal)}>
        <Modal.Header closeButton className="border-0">
          <label className="w-50 mx-auto my-1 py-1">GRN No : </label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <p className="text-center fs-6">Do you want to Print the Output?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-primary"
            onClick={poPrintHandler}
            variant="primary"
          >
            Print
          </Button>
          <Button variant="secondary" onClick={() => setModal(!modal)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
          statusMessage={statusMessage}
        />
      )}
    </Col>
  );
};

export default IsmGinScreen;
