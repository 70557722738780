import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { tickIcon } from "../../assets/img";
import { Currencies } from "../../constants/StaticData";
import { api } from "../../constants/Services";

const PoCreationForm = ({
  setPoTableData,
  matReqTableDetailsHandler,
  assetReqSelectHandler,
  poDateHandler,
  error,
  vendorList,
  projectList,
  poFormDetails,
  setPoFormDetails,
  procureSelectHandler,
  setRequestionType,
  requestionType,
  currenyHandler,
  setCheck,
  department,
  setDepartment,
  check,
}) => {
  const [MRList, setMRList] = useState([]);
  const [mrDate, setMrDate] = useState("");
  const [vendorDetail, setVendorDetail] = useState({
    company_name: "",
    company_address: "",
    drop: false,
  });
  const [vendorRenderingList, setVendorRenderingList] = useState(vendorList);
  const sparesClickHandler = () => setCheck(!check);

  const vendorNameSearchHandler = (en_val) => {
    let renderedList = vendorList?.filter((vendor) =>
      vendor?.company_name.toLowerCase().includes(en_val.toLowerCase())
    );
    setVendorRenderingList(renderedList);
  };

  const [selectedItem, setSelectedItem] = useState(false);
  const [value, setValue] = useState("");
  const fun = (e) => {
    setValue(e.target.value);
    if (e.target.value == "Assets") {
      assetReqSelectHandler();
      setPoFormDetails({
        ...poFormDetails,
        type: 1,
      });
    } else {
      setPoFormDetails({
        ...poFormDetails,
        type: 2,
      });
    }
  };

  const vendorSelectHandler = (
    ven_id,
    ven_name,
    ven_address,
    ven_gst,
    state
  ) => {
    setVendorDetail({
      company_name: ven_name,
      company_address: ven_address,
      drop: !vendorDetail?.drop,
    });
    setPoFormDetails({
      ...poFormDetails,
      vendor_id: +ven_id,
      is_gst: +ven_gst,
    });

    // setStates(state);
  };

  const matReqSelectHandler = (mr_id) => {
    let filtered_material = MRList?.filter((mr) => mr?.id == mr_id);
    filtered_material = filtered_material[0];
    setMrDate(filtered_material?.mr_date);
    setRequestionType(filtered_material?.request_type);
    matReqTableDetailsHandler(filtered_material?.request_type, mr_id);
    // console.log(
    //   "filtered_material?.request_type",
    //   filtered_material?.request_type
    // );
  };

  const materialProducets = () => {
    let data = {
      project_id: +poFormDetails?.project_id,
    };
    // api.matrialList(data).then((res) => {
    api.matrialListPo(data).then((res) => {
      setMRList(res?.material_requests);
    });
  };

  const subDepartment = [
    "ELECTRICAL",
    "CIVIL",
    "MECHANICAL",
    "HVAC",
    "FIRE FIGHTING",
    "ELV",
    "OTHERS",
    "PHE",
    "PROCESS PIPE",
    "SOLVENT PIPE",
    "ASSETS",
  ];

  useEffect(() => {
    if (poFormDetails?.project_id !== "") {
      materialProducets();
    }
  }, [poFormDetails?.project_id]);

  useEffect(() => {
    if (value == "Assets") {
      setPoTableData([]);
      setMRList([]);
      setMrDate("");
      setRequestionType("");
    }
  }, [value]);

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  // console.log("poFormDetails", poFormDetails);

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">Po Creation</h4>
      <Col className="d-flex flex-wrap mx-lg-3">
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          <p
            style={{ minHeight: "2.1rem", width: "90%", marginRight: "auto" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            Crescon Projects and Service Pvt Ltd
          </p>
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Master Type</label>
          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            // onChange={handleChange}
            // value={selectedItem}
            onChange={(e) => {
              fun(e);
              setPoFormDetails({
                ...poFormDetails,
                type_of_material: e.target.value,
              });
            }}
            value={poFormDetails?.type_of_material}
          >
            <option>Select Master Type</option>
            <option value="Assets">Assets</option>
            <option value="Material">Material</option>
            {/* <option value="Material">{mastertype}</option> */}
          </select>
          {/* {error.includes("procure_type") && (
            <p className="err"> Please Select Procure Type</p>
          )} */}
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>
          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) =>
              setPoFormDetails({ ...poFormDetails, project_id: e.target.value })
            }
            value={poFormDetails?.project_id}
          >
            <option>select project</option>
            {projectList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
          {error.includes("project_id") && (
            <p className="err"> Please Select Project</p>
          )}
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">MR No</label>
          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            value={poFormDetails?.material_request_id}
            onChange={(e) => matReqSelectHandler(e.target.value)}
            disabled={value === "Assets" && true}
            // disabled={assets === true ? true : false}
          >
            <option>Select Mr No</option>
            {MRList?.length > 0 &&
              MRList?.map((material_req) => (
                <option key={material_req?.id} value={material_req?.id}>
                  {material_req?.mr_no}
                </option>
              ))}
          </select>
          {error.includes("material_request_ids") && (
            <p className="err"> Please Mr No</p>
          )}
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">MR Date</label>
          <p
            style={value == "Assets" ? { opacity: 0.7 } : { opacity: 1 }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1 inputp"
          >
            {mrDate ?? "-"}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Requestion Type</label>
          <p
            style={value == "Assets" ? { opacity: 0.7 } : { opacity: 1 }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1 inputp"
          >
            {requestionType == 1
              ? "BOQ"
              : requestionType == 2
              ? "Direct PO"
              : ""}
          </p>
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Type</label>
          <p
            style={value == "Assets" ? { opacity: 0.7 } : { opacity: 1 }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1 inputp"
          >
            {poFormDetails?.material_type ?? "-"}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Department</label>
          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => setDepartment(e.target.value)}
            value={department}
            disabled={value === "Assets" && true}
          >
            <option>Select Department</option>
            {subDepartment?.map((item) => (
              <option value={item?.id}>{item}</option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">PO Date</label>
          <input
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            value={poFormDetails?.po_date}
            // onChange={(e) => filtered_date(e.target.value, materialIssueDate)}
            onChange={(e) => poDateHandler(e.target.value, mrDate)}
            placeholder="Enter Date"
            min={value == "Material" && mrDate ? mrDate : ""}
          />
          {error.includes("po_date") && (
            <p className="err"> Please Select PO Date</p>
          )}
        </Col>

        <Col xs={12} className="inputinner-box text-start">
          {/* <label className="w-100">Spares</label> */}
          <div className="d-flex mt-2">
            <button
              className="d-flex cust-btn fs-12 f2 align-items-center me-1 me-lg-3"
              onClick={sparesClickHandler}
            >
              <div className="checkbox me-1">
                {check && <img src={tickIcon} alt="tick" />}
              </div>
              Spares
            </button>
          </div>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Company Name</label>
          <p
            className="border-0 bg-lightBlue py-1 px-2 w-90 mt-1 mb-0 ms-0 pointer"
            onClick={() =>
              setVendorDetail({ ...vendorDetail, drop: !vendorDetail.drop })
            }
          >
            {vendorDetail?.company_name
              ? vendorDetail?.company_name
              : "Select Company"}
          </p>
          {vendorDetail.drop && (
            <ul
              style={{
                maxHeight: "230px",
                overflow: "scroll",
                width: "90%",
                marginRight: "auto",
              }}
              className="border-0 bg-lightBlue px-2 py-1"
            >
              <li>
                <input
                  onChange={(e) => vendorNameSearchHandler(e.target.value)}
                  placeholder="Please Search Company"
                  style={{ border: "1px solid #c2dcff" }}
                  className="bg-lightBlue rounded-1 px-2 py-1 w-100 my-2"
                />
              </li>
              {vendorRenderingList?.map((vendor) => (
                <li
                  onClick={() =>
                    vendorSelectHandler(
                      vendor?.id,
                      vendor?.company_name,
                      vendor?.address,
                      vendor?.gst_status,
                      vendor?.state
                    )
                  }
                  key={vendor?.id}
                  value={vendor?.id}
                  className="pointer fs-6"
                >
                  {vendor.company_name}
                </li>
              ))}
            </ul>
          )}
          {error.includes("vendor_id") && (
            <p className="err"> Please Choose Company Name</p>
          )}
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GST Registration</label>
          <p
            style={{ width: "90%", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1"
          >
            {poFormDetails?.is_gst == 1
              ? "Yes"
              : poFormDetails?.is_gst == 0
              ? "No"
              : "-"}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Vendor address</label>
          <p
            style={{ width: "90%", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1"
          >
            {vendorDetail?.company_address ?? "-"}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Procure Type</label>
          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => procureSelectHandler(e.target.value)}
            value={poFormDetails?.procure_type}
          >
            <option>Select Procure Type</option>
            <option value="domestic">Domestic</option>
            <option value="international">International</option>
          </select>
          {error.includes("procure_type") && (
            <p className="err"> Please Select Procure Type</p>
          )}
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Currency</label>
          <select
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            style={{ minHeight: "2.1rem" }}
            value={poFormDetails?.currency}
            onChange={(e) => currenyHandler(e.target.value)}
          >
            <option>select currency</option>
            {Currencies?.map((item, ind) => (
              <option value={item?.country} key={ind}>
                {item?.currency_code}
              </option>
            ))}
          </select>
          {error.includes("currency") && (
            <p className="err"> Please Currency</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start ps-0">
          <label className="mb-1">Terms & Conditions</label>
          <textarea
            rows="3"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
            placeholder="Enter Remarks"
            value={poFormDetails?.terms_conditions}
            onChange={(e) =>
              setPoFormDetails({
                ...poFormDetails,
                terms_conditions: e.target.value,
              })
            }
          />
        </Col>
      </Col>
    </Col>
  );
};

export default PoCreationForm;
