import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
import { BASE_FILE_URL } from "../../constants/Services/constants";

function WorkOrderForm({
  type,
  workOrderList,
  workOrderSelect,
  workOrderDetails,
  setWorkOrderDetails,
  workOrderNo,
  projectName,
  projectNameErr,
  setProjectName,
  projectList,
  vendors,
  setVendors,
  vendorsErr,
  setOrderDate,
  orderDate,
  setOrderNo,
  orderNo,
  orderDateErr,
  orderNoErr,
  setStartDate,
  startDate,
  startDateErr,
  setEndDate,
  endDate,
  endDateErr,
  setTerms,
  terms,
  termsErr,
  setQuote,
  quote,
  quoteErr,
  projectSelect,
  projectData,
  getProductType,
  projectType,
}) {
  const [vendorList, setVendorList] = useState([]);
  const [vendorRenderingList, setVendorRenderingList] = useState([]);
  const [vendorData, setVendorData] = useState({});
  const [upload, setUpload] = useState("");

  const getVendor = () => {
    api
      .get_vendors()
      .then((res) => {
        setVendorList(res?.vendors);
        setVendorRenderingList(res?.vendors);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getVendor();
  }, []);

  const vendorSelect = (id) => {
    setVendors(id);
    let finder = vendorList?.find((i) => i?.id == id);
    // console.log("finder", finder);
    setVendorData(finder);
  };

  // console.log("vendorData", vendorData);

  const [vendorDetail, setVendorDetail] = useState({
    company_name: "",
    company_address: "",
    drop: false,
  });

  const vendorNameSearchHandler = (en_val) => {
    let renderedList = vendorList?.filter((vendor) =>
      vendor?.company_name.toLowerCase().includes(en_val.toLowerCase())
    );
    console.log("renderedList", renderedList);
    if (en_val?.length > 0) {
      setVendorRenderingList(renderedList);
    } else {
      setVendorRenderingList(vendorList);
    }
  };

  const vendorSelectHandler = (
    ven_id,
    ven_name,
    ven_address,
    ven_gst,
    state
  ) => {
    vendorSelect(ven_id);
    setVendorDetail({
      company_name: ven_name,
      company_address: ven_address,
      drop: !vendorDetail?.drop,
    });
  };

  return (
    <Col>
      <h4 className="w-95 ms-xl-0">Work Order</h4>
      <Col className="d-flex flex-wrap mx-lg-3">
        {/* <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          <p
            style={{ minHeight: "2.1rem", width: "90%", marginRight: "auto" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1">
            Crescon Projects and Service Pvt Ltd
          </p>
        </Col> */}

        <Col xs={12} sm={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>
          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            onChange={(e) => projectSelect(e.target.value)}
            value={projectName}
          >
            <option hidden>Select Project</option>
            {projectList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
          {projectName?.length == 0 && projectNameErr && (
            <p className="err">Select Project Name</p>
          )}
        </Col>
        <Col xs={12} sm={6} className="inputinner-box text-start">
          <label className="w-100">Type</label>
          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            onChange={(e) => getProductType(e.target.value)}
            value={projectType}
            disabled={!projectName ? true : false}
          >
            <option hidden>Select Project</option>
            <option value={"billable"}>BOQ</option>
            <option value={"non-billable"}>Non BOQ</option>
          </select>
          {projectType?.length == 0 && projectNameErr && (
            <p className="err">Select Type</p>
          )}
        </Col>
        {/* <Col xs={12} sm={6} className="inputinner-box text-start">
          <label className="w-100">Vendor Name</label>
          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            onChange={(e) => vendorSelect(e.target.value)}
            value={vendors}
          >
            <option hidden>Select Vendor</option>
            {vendorList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.company_name}
              </option>
            ))}
          </select>
          {vendors?.length == 0 && vendorsErr && (
            <p className="err">Select Vendors</p>
          )}
        </Col> */}
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Address</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            value={projectData?.address}
          />
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Company Name</label>
          <p
            className="border-0 bg-lightBlue py-1 px-2 w-90 mt-1 mb-0 ms-0 pointer"
            onClick={() =>
              setVendorDetail({ ...vendorDetail, drop: !vendorDetail.drop })
            }
          >
            {vendorDetail?.company_name
              ? vendorDetail?.company_name
              : "Select Company"}
          </p>
          {vendorDetail.drop && (
            <ul
              style={{
                maxHeight: "230px",
                overflow: "scroll",
                width: "90%",
                marginRight: "auto",
              }}
              className="border-0 bg-lightBlue px-2 py-1"
            >
              <li>
                <input
                  onChange={(e) => vendorNameSearchHandler(e.target.value)}
                  placeholder="Please Search Company"
                  style={{ border: "1px solid #c2dcff" }}
                  className="bg-lightBlue rounded-1 px-2 py-1 w-100 my-2"
                />
              </li>
              {vendorRenderingList?.map((vendor) => (
                <li
                  onClick={() =>
                    vendorSelectHandler(
                      vendor?.id,
                      vendor?.company_name,
                      vendor?.address,
                      vendor?.gst_status,
                      vendor?.state
                    )
                  }
                  key={vendor?.id}
                  value={vendor?.id}
                  className="pointer fs-6"
                >
                  {vendor.company_name}
                </li>
              ))}
            </ul>
          )}
          {vendors?.length == 0 && vendorsErr && (
            <p className="err">Select Vendors</p>
          )}
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Mobile No.</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            value={projectData?.mobile}
          />
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Address</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            value={vendorData?.address}
          />
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Email</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            value={projectData?.email}
          />
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Mobile No.</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            value={vendorData?.mobile}
          />
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GST</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            value={projectData?.gst_no}
          />
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Email</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            value={vendorData?.email}
          />
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Order Date</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            type={"date"}
            onChange={(e) => setOrderDate(e.target.value)}
            value={orderDate}
          />
          {orderDate?.length == 0 && orderDateErr && (
            <p className="err">Select Order Date</p>
          )}
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GST</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            value={vendorData?.gst_no}
          />
        </Col>

        {/* <Col
          xs={12}
          sm={6}
          lg={6}
          xl={6}
          className="inputinner-box text-start me-auto">
          <label className="w-100">Order Type</label>
          <select
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            value={workOrderDetails?.order_type}
            onChange={(e) =>
              setWorkOrderDetails({
                ...workOrderDetails,
                order_type: e.target.value,
              })
            }>
            <option>Select Order Type</option>
            <option value="Hourly">Hourly</option>
            <option value="Quantity">Quantity</option>
          </select>
        </Col> */}
        {/* <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Order No.</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            onChange={(e) => setOrderNo(e.target.value)}
            value={orderNo}
          />
          {orderNo?.length == 0 && orderNoErr && (
            <p className="err">Enter Order No.</p>
          )}
        </Col> */}

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Start Date</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            type={"date"}
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
          />
          {startDate?.length == 0 && startDateErr && (
            <p className="err">Select Start Date</p>
          )}
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">End Date</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            type={"date"}
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
          />
          {endDate?.length == 0 && endDateErr && (
            <p className="err">Select End Date</p>
          )}
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Quote Ref No.</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
            onChange={(e) => setQuote(e.target.value)}
            value={quote}
          />
          {quote?.length == 0 && quoteErr && (
            <p className="err">Enter Quote Ref No.</p>
          )}
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Terms & Conditions</label>
          <textarea
            rows="2"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
            placeholder="Enter Terms & Conditions"
            onChange={(e) => setTerms(e.target.value)}
            value={terms}
          />
          {terms?.length == 0 && termsErr && (
            <p className="err">Enter Terms & Conditions</p>
          )}
        </Col>
        {/* <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Name</label>
          <input className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0" />
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Phone Number</label>
          <input
            type="number"
            className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
          />
        </Col> */}
      </Col>
    </Col>
  );
}

export default WorkOrderForm;
