import { Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import StatusModal from "../StatusModal";
import { Navigate, useNavigate } from "react-router-dom";
import { api } from "../../constants/Services";
import MaterialRequestTable from "./MaterialRequestTable";
function MaterialRequestTableSection({
  currentTableDetails,
  setCurrentTableDetails,
  finalDetails,
}) {
  // console.log("final-table", currentTableDetails);
  const navigate = useNavigate();
  const [remarks, setRemarks] = useState({});
  const [reqValErr, setReqValErr] = useState([]);
  const [IndentValue, setIndentValue] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [requiredValue, setRequiredValue] = useState({});
  const [showStatus, setShowStatus] = useState(false);
  const [disabled, setDisabled] = useState(false);

  // console.log("currentTableDetails", currentTableDetails);

  const statusModalClose = (message) => {
    setShowStatus(!showStatus);
    setStatusMessage(message);
    setTimeout(() => navigate("/notification"), 2000);
  };

  const requiredValueHandler = (
    id,
    avaliable_qty,
    prev_purchase,
    typed_value,
    Indenting_qty,
    mr_quantity,
    ind
  ) => {
    // console.log("Indenting_qty", Indenting_qty);

    let calulated_qty = avaliable_qty - prev_purchase;
    let value = typed_value.replace(/[^\s0-9.]/gi, "");

    let temp = [...currentTableDetails];

    setRequiredValue({
      ...requiredValue,
      [id]: value,
    });

    if (+typed_value == 0) {
      setDisabled(true);
    } else if (avaliable_qty !== "") {
      // if (+typed_value < +avaliable_qty || +typed_value == +avaliable_qty) {
      //   let ind_val = +avaliable_qty - +typed_value;
      if (+typed_value > +mr_quantity) {
        // console.log("ee");
        setDisabled(true);
        setReqValErr([...reqValErr, id]);
        let final = {
          ...temp[ind],
          error: 1,
        };
        temp[ind] = final;
        setCurrentTableDetails(temp);
      } else {
        setIndentValue({
          ...IndentValue,
          [id]: +typed_value,
        });
        setDisabled(false);
        reqValErr.splice(reqValErr.indexOf(id), 1);
        setReqValErr(reqValErr);
        let final = {
          ...temp[ind],
          error: 0,
        };
        temp[ind] = final;
        setCurrentTableDetails(temp);
      }
    }
  };

  const mrRequestActionHandler = () => {
    if (Object.keys(requiredValue).length > 0) {
      let formdata = new FormData();

      let count = 1;
      currentTableDetails?.map((item, index) => {
        // if (+requiredValue[item?.id] > +item?.qty - +item?.approved_po_qty) {
        //   count = count + 1;
        // }
        if (item?.error == 1) {
          count = count + 1;
        }
        formdata.append(`details[${index}][boq_code]`, item?.boq_code ?? "");
        formdata.append(
          `details[${index}][material_id]`,
          item?.material_id ?? ""
        );
        formdata.append(
          `details[${index}][required_quantity]`,
          requiredValue[item?.id] ?? 0
        );
        formdata.append(`details[${index}][remarks]`, remarks[item?.id] ?? "");
      });
      formdata.append("project_id", +finalDetails?.Project_id);
      formdata.append("head_office", finalDetails?.Head_office);
      formdata.append("request_type", +finalDetails?.Request_type);
      // formdata.append("mr_no", +finalDetails?.mr_no);
      formdata.append(" mr_date", finalDetails?.mr_date);
      formdata.append(" material_type", finalDetails?.material_type);
      // formdata.append("department_id", finalDetails?.department_id);
      formdata.append("department", finalDetails?.department_id);

      if (count == 1) {
        api
          .material_request_store(formdata)
          .then((res) => {
            // console.log(
            // "🚀 ~ file: MaterialRequestTableSection.js:76 ~ api.material_request_store ~ res:",
            // res
            // );
            setDisabled(true);
            if (res?.status == "success") {
              statusModalClose(`MR Number :${res?.mr_no}`);
              setDisabled(false);
            } else {
              console.log("marter-requ-final-store-api", res);
              setDisabled(false);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    } else {
      alert("Please Fill All Details");
    }
  };

  return (
    <Col
      xs={10}
      className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center"
    >
      <div style={{ overflowX: "scroll" }} className="user-mange-table">
        <table>
          <thead className="nowrap">
            <tr className="nowrap">
              <th>S.No</th>
              {currentTableDetails[0]?.hasOwnProperty("boq_code") && (
                <th className="nowrap" style={{ minWidth: "110px" }}>
                  BOQ Code
                </th>
              )}
              <th className="nowrap">Material Code</th>
              <th className="nowrap" style={{ minWidth: "230px" }}>
                Material Name
              </th>
              {/* <th>Material Group</th>  */}
              <th className="nowrap">Material Type</th>
              <th className="nowrap">UOM</th>
              <th className="nowrap  text-center">Required Qty</th>
              {currentTableDetails[0]?.hasOwnProperty("boq_code") && (
                <>
                  <th className="nowrap">Previouly Purchased</th>
                  <th className="nowrap">Cumulative Qty</th>
                  <th className="nowrap">Stock Qty</th>
                </>
              )}
              <th className="nowrap">Allowable Qty</th>
              <th className="nowrap">Remarks</th>
            </tr>
          </thead>
          <tbody>
            {currentTableDetails?.map((item, ind) => {
              return (
                <tr key={ind}>
                  <td>0{ind + 1}</td>
                  {currentTableDetails[0]?.hasOwnProperty("boq_code") && (
                    <td>{item?.boq_code ?? "-"}</td>
                  )}
                  <td>{item?.material_code}</td>
                  <td>{item?.material?.name || item?.name}</td>
                  <td>{item?.group || item?.material?.group}</td>
                  {/* <td>
                    {item?.material_type || item?.material?.material_type} 
                  </td> */}
                  <td>{item?.uom}</td>
                  <td>
                    {currentTableDetails[0]?.hasOwnProperty("boq_code") ? (
                      <>
                        <input
                          placeholder="Enter Required Quantity"
                          className="border-0 py-3 text-center"
                          type={"number"}
                          value={requiredValue[item?.id] || ""}
                          onChange={(e) =>
                            requiredValueHandler(
                              item?.id,
                              // item?.available_quantity,
                              item?.qty,
                              item.purchased_qty,
                              e.target.value,
                              +item?.qty - +item?.stock_quantity
                                ? +item?.qty - +item?.stock_quantity
                                : 0,
                              // +item?.mr_quantity,
                              +item?.mr_quantity < +item?.cumulative_quantity
                                ? Math.abs(
                                    +item?.cumulative_quantity - +item?.qty
                                  )
                                : item?.mr_quantity,
                              ind
                            )
                          }
                        />
                        {/* {reqValErr.includes(item?.id) ||
                          (requiredValue[item?.id] == 0 && (
                            <p className="err">More than Avaliable Qty</p>
                          ))} */}

                        {/* {reqValErr.includes(item?.id) && (
                          <p className="err">More than Avaliable Qty</p>
                        )} */}
                        {item?.error == 1 && (
                          <p className="err">More than Avaliable Qty</p>
                        )}
                      </>
                    ) : (
                      <input
                        placeholder="enter required quantity"
                        className="border-0 "
                        value={requiredValue[item?.id] || ""}
                        onChange={(e) =>
                          setRequiredValue({
                            ...requiredValue,
                            [item?.id]: e.target.value,
                          })
                        }
                      />
                    )}
                  </td>
                  {currentTableDetails[0]?.hasOwnProperty("boq_code") && (
                    <>
                      <td>
                        {/* {item?.previously_purchased
                          ? item?.previously_purchased
                          : item?.raised_po_qty
                          ? item?.raised_po_qty
                          : 0} */}
                        {+item?.previously_purchased}
                      </td>
                      {/* <td>{item.raised_mr_qty}</td> 02-04-2024 */}
                      <td className="text-center">
                        {/* {item?.approved_po_qty ? item?.approved_po_qty : 0} */}
                        {+item?.cumulative_quantity}
                      </td>
                      <td className="text-center">
                        {/* {item?.available_quantity
                          ? item?.available_quantity
                          : 0} */}
                        {item?.stock_quantity}
                      </td>
                    </>
                  )}
                  <td className="text-center">
                    <p>
                      {/* {+item?.qty - +item?.stock_quantity
                        ? +item?.qty - +item?.stock_quantity
                        : "0"} */}
                      {+item?.mr_quantity < +item?.cumulative_quantity
                        ? Math.abs(+item?.cumulative_quantity - +item?.qty)
                        : item?.mr_quantity}
                      {/* {+item?.qty} */}
                    </p>
                  </td>

                  <td>
                    <input
                      className="border-0 text-center"
                      value={remarks[item?.id] || ""}
                      onChange={(e) =>
                        setRemarks({
                          ...remarks,
                          ...item?.id,
                          [item?.id]: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* <MaterialRequestTable
        final_table_details={final_table_details}
        setCurrentTableDetails={setCurrentTableDetails}
      /> */}
      <Col sm={12} className="inputinner-box text-start">
        <button
          className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
          disabled={disabled ? true : false}
          onClick={mrRequestActionHandler}
        >
          Save
        </button>
        <button
          className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
          onClick={() => window.location.reload()}
        >
          cancel
        </button>
      </Col>
      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          statusMessage={statusMessage}
          showStatus={showStatus}
        />
      )}
    </Col>
  );
}

export default MaterialRequestTableSection;
