import { post, get, deletes, file_post } from "./method";
import { URL } from "../constants";

// LOGIN

export const login = async (data) => await post(URL.LOGIN, data);

// EMPLOYEE
export const employee_detail_save = async (data) =>
  await file_post(URL.EXPLOYEE_DETAIL_SAVE, data);

export const employee_add = async (data) =>
  await file_post(URL.EMPLOYEE_ADD, data);

export const employee_create_data = async () =>
  await get(URL.EXPLOYEE_CREATE_DATA);

export const locked_employees = async () => await get(URL.LOCKED_EMPLOYEES);

export const employees_list = async () => await get(URL.EMPLOYEE_LIST);

export const unlock_employee = async (id) =>
  await get(URL.UNLOCK_EMPLOYEE + "/" + id);

export const emp_status_change = async (id) =>
  await get(URL.EMPLOYEE_STATUS_CHANGE + "/" + id);

export const emp_resignation = async (id, data) =>
  await post(`${URL.EXPLOYEE_RESIGNATION}${id}`, data);

export const employee_roles_permissions = async (data) =>
  await post(URL.EMPLOYEE_PERMISSION, data);

export const employee_view = async (id) => await get(URL.EMPLOYEE_VIEW + id);

export const employee_role_assign = async (data) =>
  await post(URL.EMPLOYEE_ROLE_ASSIGN, data);

// MODULE
export const employee_modules_permissions = async (data) =>
  await post(URL.MODULE_PERMISSION, data);

export const modules_list = async () => await get(URL.MODULE_LIST);

// PROCUREMENT

export const projectCreate = async (data) =>
  await post(URL.PROJECT_CREATE, data);

export const vendorCreate = async (data) => await post(URL.VENDOR_CREATE, data);

export const vendorView = async (data) => await post(URL.VENDOR_VIEW, data);

export const vendorSearch = async (data) => await post(URL.VENDOR_SEARCH, data);

export const vendorGst = async (data) => await post(URL.VENDOR_GST, data);
export const vendorImport = async (data) =>
  await file_post(URL.VENDOR_IMPORT, data);

// MATERIAL AND ASSESTS
export const material_assests_search = async (type) =>
  await get(URL.MATERIAL_ASSESTS_SEARCH + type);

export const material_assests_specific_search = async (id) =>
  await get(URL.MATERIAL_ASSESTS_SPECIFIC_SEARCH + id);

export const material_assests_add = async (data) =>
  await post(URL.MATERIAL_ASSESTS_ADD, data);

export const material_assests_update = async (id, data) =>
  await post(`${URL.MATERIAL_ASSESTS_UPDATE}${id}`, data);

export const material_show = async (id) =>
  await get(`${URL.MATERIAL_SHOW}${id}`);

export const material_attribute_add = async (data) =>
  await post(`${URL.MATERIAL_ATTRIBUTE_ADD}`, data);

// PROJECT

export const project_list = async () => await get(URL.PROJECT_LIST);
export const project_list_without_boq = async () =>
  await get(URL.PROJECT_LIST_WITH_NO_BOQ);
export const project_add = async (data) => await post(URL.PROJECT_ADD, data);
export const project_update = async (data) =>
  await post(URL.PROJECT_UPDATE, data);

export const project_boq_add = async (data) =>
  await file_post(URL.PROJECT_BOQ_ADD, data);

export const project_show = async (id) => await get(`${URL.PROJECT_SHOW}${id}`);

export const project_compare = async (id) =>
  await get(`${URL.PROJECT_COMPARE}${id}`);

export const project_status = async (id) =>
  await get(`${URL.PROJECT_STATUS}${id}`);

export const project_list_assets = async () =>
  await get(URL.PROJECTS_ASSETS_LIST);

// MATERIAL REQUEST
export const material_request_list = async (data) =>
  await get(`${URL.MATERIAL_REQUEST_LIST}`, data);
export const material_request_project_list = async (data) =>
  await get(`${URL.MATERIAL_REQUEST_PROJECT_LIST}`, data);
export const material_request_view = async (id) =>
  await get(`${URL.MATERIAL_REQUEST_VIEW}${id}`);
export const project_material_request_view = async (id) =>
  await get(`${URL.PROJECT_MATERIAL_REQUEST}${id}`);
export const material_request_store = async (data) =>
  await post(URL.STORE_MATERIAL_REQUEST, data);
export const material_request_material_checked = async (data) =>
  await post(URL.MATERIAL_CHECKED_LIST, data);
export const material_request_boq_checked = async (data) =>
  await post(URL.BOQ_CHECKED_LIST, data);
export const material_request_through_material = async (id) =>
  await get(`${URL.THROUGH_MATERIAL}${id}`);
export const material_request_through_boq = async (id) =>
  await get(`${URL.THROUGH_BOQ}${id}`);

export const material_request_boq = async (id) =>
  await get(`${URL.THROUGH_BOQ_WITHOUT_PAGINATION}${id}`);

export const material_request_through_direct_po = async (id) =>
  await get(`${URL.THROUGH_DIRECT_PO}${id}`);

export const boq_list = async (id) => await get(`${URL.BOQLIST}${id}`);
export const boq_view = async (id) => await get(`${URL.BOQVIEW}${id}`);

// CODE VIEW

export const boq_code_view = async (id, boq_code) =>
  await get(`${URL.BOQ_VIEW}${id}/${boq_code}`);

export const notif_compare = async (id, boq_id) =>
  await get(`${URL.NOTIF_COMPARE}${id}/${boq_id}`);

export const material_code_view = async (id) =>
  await get(`${URL.MATERIAL_VIEW}${id}`);

export const material_code_views = async (id, projec_id) =>
  await get(`${URL.MATERIAL_VIEW}${id}/${projec_id}`);

export const material_request_through_boq_by_page = async (id, sel_pg) =>
  await get(`${URL.THROUGH_BOQ}${id}?page=${sel_pg}`);
// EMPLOYEE PROJECTS

export const employeeProjects = async () => await get(URL.EMPLOYEE_PROJECTS);
export const employeeProfileSelect = async (data) =>
  await get(URL.EMPLOYEE_PROFILE_SELECT, data);

// material

export const getMaterialsList = async () => await get(URL.MATERIAL_LIST);

export const getMaterialsLists = async (params) =>
  await get(URL.MATERIAL_LIST + params);

// MR

export const matrialList = async (data) =>
  await post(URL.MATRIAL_PRODUCT, data);

export const matrialListPo = async (data) =>
  await post(URL.MATRIAL_PRODUCT_PO, data);

export const matrialRequest = async (data) =>
  await post(URL.MATRIAL_REQUEST_DETAILS, data);

export const matrialRequestDirectPo = async (data) =>
  await post(URL.DIRECT_PO_MATERIAL_REQUEST, data);

// PURCHASE ORDER

export const purchaseOrderCreate = async (data) =>
  await post(URL.PURCHASE_ORDER_CREATE, data);

export const purchaseOrderList = async (data) =>
  await post(URL.PURCHASE_ORDER_LIST, data);
// PURCHASE_ORDER_ANNEXURE_DOWNLOAD
export const purchaseOrderOutputAnnexure = async (data) =>
  await file_post(URL.PURCHASE_ORDER_ANNEXURE_DOWNLOAD, data);

export const purchaseOrderView = async (po_id) =>
  await get(`${URL.PURCHASE_ORDER_VIEW}${po_id}`);

// NOTIFICATION

export const get_notifications = async () => await post("notifications");

export const notifyStatus = async (data) =>
  await post(URL.NOTIFICATION_STATUS, data);

export const notifyRejectList = async () => await get(URL.NOTIFICATION_REJECT);

export const messageList = async () => await get(URL.MESSAGE_LIST);

// VENDORS
export const get_vendors = async () => await get("vendors");

// HSN

export const getHSN = async () => await get(URL.HSN_CODES);

export const addHSN = async (data) => await post(URL.HSN_CODES + "/add", data);

export const updateHSN = async (data) =>
  await post(URL.HSN_CODES + "/update", data);

export const searchHSN = async (data) =>
  await post(URL.HSN_CODES + "/search", data);

// MATERIAL_IMPORT

export const materialImport = async (data) =>
  await file_post(URL.MATERIAL_IMPORT, data);

export const hsnMaterialImport = async (data) =>
  await file_post(URL.HSN_MATERIAL_IMPORT, data);

// CHANGE PASSWORD
export const changeEmployeePassword = async (data) =>
  await file_post(URL.EXPLOYEE_CHANGE_PASSWORD, data);

// DIRECT PO

export const directPoRequest = async (data) => await post(URL.DIRECT_PO, data);

// ASSETS_IMPORT

export const assetsImport = async (data) =>
  await file_post(URL.ASSETS_IMPORT, data);

// WORK_ORDER_IMPORT

export const workOrderImport = async (data) =>
  await file_post(URL.WORK_ORDER_IMPORT, data);

// GRN

export const grnList = async (param, data) =>
  await post(URL.GRN_LIST + param, data);

export const grnAdd = async (data) => await file_post(URL.GRN_ADD, data);

export const grnView = async (id) => await get(URL.GRN_VIEW + id);

export const grnDetailView = async (id) => await get(URL.GRN_DETAILS_VIEW + id);

export const grnPoLists = async (data) => await post(URL.GRN_PO_LIST, data);

// INVOICE

export const invoiceList = async (param, data) =>
  await post(URL.INVOICE_LIST + param, data);

export const invoiceAdd = async (data) =>
  await file_post(URL.INVOICE_CREATE, data);

export const invoiceView = async (id) => await get(URL.INVOICE_VIEW + id);

export const invoiceBookNbi = async (id) =>
  await get(URL.INVOICE_BOOKING_NBI + id);

// WAREHOUSE
export const warehouseList = async (data) => await get(URL.WAREHOUSE);

export const warehouseAdd = async (data) => await post(URL.WAREHOUSE, data);

export const warehouseUpdate = async (data) =>
  await post(URL.WAREHOUSE + "-update", data);

export const warehouseDelete = async (id) =>
  await get(URL.WAREHOUSE + "-delete/" + id);

export const warehousePojectView = async (id) =>
  await get(URL.WAREHOUSE_PROJECT_VIEW + id);

// PO METRIAL

export const poMatrials = async (data) => await post(URL.PO_METRIALS, data);

// ALL PROJECTS
export const allProjects = async () => await get(URL.ALL_PROJECTS);

// MATERIAL ISSUE
export const matertialIssueList = async (param, data) =>
  await post(URL.MATERTIAL_ISSUE_LIST + param, data);

export const matertialIssueAdd = async (data) =>
  await file_post(URL.MATERTIAL_ISSUE_CREATE, data);

export const matertialIssueView = async (id) =>
  await get(URL.MATERTIAL_ISSUE_VIEW + id);

export const matertialIssueViewProject = async (id) =>
  await get(URL.MATERTIAL_ISSUE_VIEW_PROJECT + id);

export const matertialStockCheck = async (data) =>
  await post(URL.MATERIAL_STOCK_QTY, data);

export const matertialIssueGetMaterial = async (data) =>
  await file_post(URL.MATERIAL_ISSUE_GET_MATERIAL, data);

export const cumulativeAmount = async (data) =>
  await file_post(URL.GET_MATERIAL_CUMULATIVE, data);

// INTERN RETURN
export const internReturnList = async (param, data) =>
  await post(URL.INTERN_RETURN_LIST + param, data);

export const internReturnAdd = async (data) =>
  await file_post(URL.INTERN_RETURN_CREATE, data);

export const internReturnView = async (id) =>
  await get(URL.INTERN_RETURN_VIEW + id);

// MATERIAL TRANSFER
export const matertialTransferList = async (param, data) =>
  await post(URL.MATERTIAL_TRANSFER_LIST + param, data);

export const matertialTransferLists = async (data) =>
  await post(URL.MATERIAL_TRANSFER_LISTS, data);

export const matertialTransferAdd = async (data) =>
  await file_post(URL.MATERTIAL_TRANSFER_CREATE, data);

export const matertialTransferView = async (id) =>
  await get(URL.MATERTIAL_TRANSFER_VIEW + id);

export const matertialTransferProject = async (id) =>
  await get(URL.MATERIAL_TRANSFER_PROJECTS + id);

export const matertialTransferProjects = async (id) =>
  await get(URL.MATERIAL_TRANSFER_PROJECTS_NEW + id);

export const matertialAvailableStock = async (data) =>
  await file_post(URL.MATERIAL_AVAILABLE_STOCK, data);

// MATERIAL RECEIPT
export const matertialReceiptList = async (param, data) =>
  await post(URL.MATERTIAL_RECEIPT_LIST + param, data);

export const matertialReceiptAdd = async (data) =>
  await file_post(URL.MATERTIAL_RECEIPT_CREATE, data);

export const matertialReceiptView = async (id) =>
  await get(URL.MATERTIAL_RECEIPT_VIEW + id);

// MATERIAL RETURN
export const matertialReturnList = async (param, data) =>
  await post(URL.MATERTIAL_RETURN_LIST + param, data);

export const matertialReturnAdd = async (data) =>
  await file_post(URL.MATERTIAL_RETURN_CREATE, data);

export const matertialReturnView = async (id) =>
  await get(URL.MATERTIAL_RETURN_VIEW + id);

export const matertialReturnGrnList = async (data) =>
  await post(URL.MATERIAL_RETURN_LISTS, data);

export const getProMaterialStock = async (data) =>
  await post(URL.GET_PROJCET_MATERIAL_STOCK, data);

// DAY END PROCESS
export const runModule = async (data) => await file_post(URL.RUN_MODULE, data);

export const employeeStatus = async (data) =>
  await file_post(URL.EMPLOYEE_STATUS, data);

// WORK ORDER
export const workOrderList = async (param, data) =>
  await file_post(URL.WORK_ORDER_LIST + param, data);

export const workOrders = async (data) => await file_post(URL.WORK_ORDER, data);

export const workOrderAdd = async (data) =>
  await file_post(URL.WORK_ORDER_CREATE, data);

export const workOrderUpdate = async (data) =>
  await file_post(URL.WORK_ORDER_UPDATE, data);

export const workOrderView = async (id) => await get(URL.WORK_ORDER_VIEW + id);

export const vendorEdit = async (data) => await post(URL.VENDOR_UPDATE, data);
