import React, { useEffect, useState } from "react";
import MrMaterialTable from "../../screens/Execution/MrMaterialTable";
import MrBoqTable from "../../screens/Execution/MrBoqTable";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";

const MaterialRequestForm = ({
  setDirectPo,
  materialType,
  setMaterialType,
  projetList,
  setCurrentTable,
  setCurrentTableDetails,
  setFinalDetails,
  projectNames,
  setTablePage,
  setProjectID,
}) => {
  const [headOffice, setHeadOffice] = useState("");
  const [materialList, setMaterialList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [requestionType, setRequestionType] = useState("");
  const [pRDDate, setPRDDate] = useState("");
  const [materialTypeErr, setMaterialTypeErr] = useState(false);
  const [projectNameErr, setProjectNameErr] = useState(false);
  const [prNoErr, setPrNoErr] = useState(false);
  const [requestionTypeErr, setRequestionTypeErr] = useState(false);
  const [pRDDateErr, setPRDDateErr] = useState(false);
  const [department, setDepartment] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [departmentIdErr, setDepartmentIdErr] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const requestionTypeHandler = (e) => {
    let sel_ele = +e.target.value;

    setRequestionType(e.target.value);
  };

  const subDepartment = [
    "ELECTRICAL",
    "CIVIL",
    "MECHANICAL",
    "HVAC",
    "FIRE FIGHTING",
    "ELV",
    "OTHERS",
    "PHE",
    "PROCESS PIPE",
    "SOLVENT PIPE",
    "ASSETS",
  ];

  const getMaterials = (pro_id) => {
    api.project_material_request_view(pro_id).then((res) => {
      if (res?.status === "success") {
        setMaterialList(res?.project_material_requests);
      }
    });
  };

  const projectSelectionHandler = (e) => {
    let projec_id = +e.target.value;
    let headOfficeName = projetList
      ?.filter((project) => project?.id === projec_id)
      .reduce((acc, fil_item) => fil_item?.head_office, "");

    let productList = projetList?.find((project) => project?.id === projec_id);
    setEndDate(productList?.end_date);
    setStartDate(productList?.start_date);
    setHeadOffice(headOfficeName);
    setProjectName(projec_id);
    getMaterials(projec_id);
    setProjectID(projec_id);
  };

  const filtered_date = (sel_date) => {
    let today = new Date();
    let selected_date = new Date(sel_date);
    let now = new Date(startDate);
    var start_date = now - 1000 * 60 * 60 * 24 * 1;
    start_date = new Date(start_date);

    let to_day = new Date(endDate); // get today's date
    let end_date = new Date(to_day);
    end_date.setDate(to_day.getDate() + 1);

    if (selected_date > start_date && selected_date < end_date) {
      setPRDDate(sel_date);
    }
  };

  const getDepartment = () => {
    api.employee_create_data().then((res) => {
      if (res?.status == "success") {
        setDepartment(res?.departments);
      }
    });
  };

  const submitHandler = () => {
    if (
      materialType?.length == 0 ||
      projectName?.length == 0 ||
      requestionType?.length == 0 ||
      pRDDate?.length == 0 ||
      departmentId?.length == 0
    ) {
      setMaterialTypeErr(true);
      setProjectNameErr(true);
      setRequestionTypeErr(true);
      setDepartmentIdErr(true);
      setPRDDateErr(true);
    } else {
      setFinalDetails({
        Project_id: +projectName,
        Head_office: headOffice,
        Request_type: requestionType,
        mr_date: pRDDate,
        material_type: materialType,
        department_id: departmentId,
      });
      if (+requestionType === 1) {
        setMaterialTypeErr(false);
        api.material_request_through_boq(+projectName).then((res) => {
          // console.log("boq_res_through", res?.boq_details);
          setCurrentTable("boq");
          let temp = [];
          res?.boq_details?.map((item, ind) => {
            temp.push({
              ...item,
              error: 0,
            });
          });
          setCurrentTableDetails(temp);
          setTablePage(true);
        });
      } else {
        let id = projectName;
        setMaterialTypeErr(false);
        api.material_request_through_direct_po(id).then((res) => {
          // console.log("material_res_direct_po", res);
          if (res?.status === "success") {
            setDirectPo({
              boq_code: res?.boq_codes,
              m_code: res?.child_materials,
            });
            setCurrentTable("material");
          }
        });
      }
    }
  };

  useEffect(() => {
    getDepartment();
  }, []);

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">Material Request</h4>
      <Col className="d-flex flex-wrap mx-lg-3">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          <p
            style={{
              minHeight: "2rem",
              width: "90%",
              marginRight: "auto",
              fontSize: "12px",
            }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            Crescon Projects and Service Pvt Ltd
          </p>
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>
          <select
            style={{ fontSize: "12px" }}
            onChange={(e) => projectSelectionHandler(e)}
            value={projectName}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
          >
            <option>Select Project</option>
            {projectNames?.map((project) => {
              // console.log(project);
              return (
                <option key={project?.id} value={project?.id}>
                  {project?.name}
                </option>
              );
            })}
          </select>

          {projectName?.length == 0 && projectNameErr && (
            <p className="err">Enter Project Name</p>
          )}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Requestion Type</label>
          <select
            style={{ fontSize: "12px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            value={requestionType}
            onChange={(e) => requestionTypeHandler(e)}
          >
            <option>Select Requestion Type</option>
            <option value={1}>
              {" "}
              <p style={{ fontSize: "12px" }}>BOQ</p>
            </option>
            <option value={2}>
              {" "}
              <p style={{ fontSize: "12px" }}></p>Direct PO
            </option>
          </select>
          {requestionType?.length == 0 && requestionTypeErr && (
            <p className="err">Enter requestion type</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Type</label>

          <select
            className="input-select bg-lightBlue ms-0"
            style={{
              border: "2px solid #f1f7ff",
              width: "90%",
              marginRight: "auto",
              fontSize: " 12px",
            }}
            onChange={(e) => setMaterialType(e.target.value)}
            value={materialType}
          >
            <option value={""}>Select Material type</option>

            {requestionType == 1 ? (
              <>
                <option value={"ELECTRICAL"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>ELECTRICAL</p>
                </option>
                <option value={"CIVIL"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>CIVIL</p>
                </option>
                <option value={"MECHANICAL"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>MECHANICAL</p>
                </option>
                <option value={"HVAC"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>HVAC</p>
                </option>
                <option value={"FIRE FIGHTING"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>FIRE FIGHTING</p>
                </option>
                <option value={"ELV"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>ELV</p>
                </option>
                <option value={"OTHERS"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>OTHERS</p>
                </option>
                <option value={"PHE"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>PHE</p>
                </option>
                <option value={"PROCESS PIPE"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>PROCESS PIPE</p>
                </option>
                <option value={"SOLVENT PIPE"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>SOLVENT PIPE</p>
                </option>
              </>
            ) : requestionType == 2 ? (
              <>
                <option value={"Painting"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>Painting</p>
                </option>
                <option value={"Fitting"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>Fitting</p>
                </option>
                <option value={"Welding "}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>Welding</p>{" "}
                </option>
                <option value={"Insulation"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>Insulation</p>
                </option>
                <option value={"Accessories 1"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>Accessories 1</p>
                </option>
                <option value={"Accessories 2"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>Accessories 2</p>
                </option>
                <option value={"Support"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>Support</p>
                </option>
                <option value={"Transport"}>
                  {" "}
                  <p style={{ fontSize: "12px" }}>Transport</p>
                </option>
              </>
            ) : (
              ""
            )}
          </select>
          {materialType?.length == 0 && materialTypeErr && (
            <p className="err">Enter Material Type</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Department</label>
          <select
            style={{ fontSize: "12px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            value={departmentId}
            onChange={(e) => setDepartmentId(e.target.value)}
          >
            <option>Select Department</option>
            {subDepartment?.map((item, ind) => (
              <option style={{ fontSize: "12px" }} value={item}>
                {item}
              </option>
            ))}
          </select>
          {departmentId?.length == 0 && departmentIdErr && (
            <p className="err">Select Department</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">MR Date</label>

          <input
            style={{ fontSize: "12px" }}
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Date"
            onChange={(e) => filtered_date(e.target.value)}
            value={pRDDate}
          />
          {pRDDate?.length == 0 && pRDDateErr && (
            <p className="err">Enter MR Date</p>
          )}
        </Col>
        <Col xs={12} sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={submitHandler}
          >
            Fetch
          </button>
        </Col>
      </Col>
    </Col>
  );
};

export default MaterialRequestForm;
