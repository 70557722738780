import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { logo } from "../../assets/img";
import { api } from "../../constants/Services";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Header from "../../component/Header/Header";
import useUser from "../../constants/Storage/userData";
// import html2PDF from "jspdf-html2canvas";
import ReactDOM from "react-dom";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { renderToString } from "react-dom/server"; // Import renderToString from ReactDOM for server-side rendering
import PrinterFooter from "../../component/PoList/PrinterFooter";



function PoOutputScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useUser();
  const [poPrintDetails, setPOPrintDetails] = useState([]);
  const [terms, setTerms] = useState([]);
  const [directPoAttributes, setDirectPoAttributes] = useState([]);
  const [poAnnex, setPOAnnex] = useState([]);
  const [numToWords, setNumToWords] = useState("");
  const [poTable, setPoTable] = useState([]);
  const [approval, setapproval] = useState("");
  // poPrintDetails?.material_request?.request_type == 1



  function doConvert(numberInput) {
    let oneToTwenty = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    let tenth = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    let numToWord = numberInput.toString();
    if (numToWord.includes(".")) numToWord = numToWord.split(".")[0];

    let num = ("000000000" + numToWord)
      .slice(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

    if (!num) return;

    let outputText =
      num[1] != 0
        ? (oneToTwenty[Number(num[1])] ||
          `${tenth[num[1][0]]} ${oneToTwenty[num[1][1]]}`) + " crore "
        : "";
    outputText +=
      num[2] != 0
        ? (oneToTwenty[Number(num[2])] ||
          `${tenth[num[2][0]]} ${oneToTwenty[num[2][1]]}`) + " lakh "
        : "";

    outputText +=
      num[3] != 0
        ? (oneToTwenty[Number(num[3])] ||
          `${tenth[num[3][0]]} ${oneToTwenty[num[3][1]]}`) + "thousand "
        : "";
    outputText +=
      num[4] != 0
        ? (oneToTwenty[Number(num[4])] ||
          `${tenth[num[4][0]]} ${oneToTwenty[num[4][1]]}`) + "hundred "
        : "";
    outputText +=
      num[5] != 0
        ? (oneToTwenty[Number(num[5])] ||
          `${tenth[num[5][0]]} ${oneToTwenty[num[5][1]]}`) + " "
        : "";
    // console.log("Result-1 = ", outputText);

    outputText = outputText?.charAt(0).toUpperCase() + outputText?.slice(1);
    // console.log("Result = ", outputText);

    setNumToWords(outputText);
  }

  const getList = (paramId) => {
    api
      .purchaseOrderView(paramId ? paramId : location.state?.id)
      .then((res) => {
        if (res?.status == "success") {
          setPOPrintDetails(res?.purchase_orders);
          setPoTable(res?.purchase_orders?.purchase_order_details);
          setPOAnnex(res?.purchase_orders?.purchase_order_annexures);
          let temp = [];

          const totalValue =
            res?.purchase_orders?.purchase_order_details?.reduce(
              (acc, po) => acc + +po?.net_value,
              0
            );
          setapproval(res?.purchase_orders?.approval[0]?.employee?.name);
          if (totalValue) {
            doConvert(totalValue);
          }
          if (
            res?.purchase_orders?.terms_conditions &&
            res?.purchase_orders?.terms_conditions.includes(",")
          ) {
            temp = res?.purchase_orders?.terms_conditions.split(",");
          } else {
            res?.purchase_orders?.terms_conditions &&
              temp.push(res?.purchase_orders?.terms_conditions);
          }
          setTerms(temp);
          if (res?.purchase_orders?.material_request?.request_type == 2) {
            let temp_att = [];
            let temp_obj = {};
            res?.purchase_orders?.purchase_order_details?.map((po) => {
              po?.attributes && po?.attributes.includes(",")
                ? temp_att.push(po?.attributes.split(","))
                : temp_att.push(po?.attributes);
              temp_obj[po?.id] = temp_att;
            });

            setDirectPoAttributes(temp_obj);
          }
        }
      });
  };
  useEffect(() => {
    if (searchParams?.get("id")) {
      getList(searchParams?.get("id"));
    } else {
      getList();
    }
  }, [searchParams?.get("id")]);

  // Define the HTML footer content
  const FooterContent = () => (
    <div
      id="page-break"
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginLeft: "0%",
        paddingBottom: "30px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "0px",
          padding: "20px",
          border: "1px solid #000",
        }}
      >
        <div>
          <p
            style={{
              textTransform: "capitalize",
              margin: 0,
              marginBottom: "10px",
            }}
          >
            {poPrintDetails?.created_user?.name}
          </p>
          <label>Prepared</label>
        </div>
        <div>
          <p
            style={{
              textTransform: "capitalize",
              margin: 0,
              marginBottom: "10px",
            }}
          >
            {approval ? approval : "-"}
          </p>
          <label>Approved</label>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 2,
          padding: "20px",
          borderBottom: "1px solid #000",
          borderLeft: "1px solid #000",
          borderRight: "1px solid #000",
        }}
      >
        <div
          style={{
            borderRight: "1px solid black",
            minHeight: "6rem",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <p style={{ margin: 0, fontSize: "15px" }}>
            Please Sign and Return Duplicate Copy as a Token of Acceptance
          </p>
          <p
            style={{
              marginTop: "auto",
              marginBottom: "1rem",
              fontSize: "15px",
            }}
          >
            Vendor Acceptance
          </p>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minHeight: "6rem",
            paddingLeft: "10px",
          }}
        >
          <p
            style={{
              marginLeft: "auto",
              marginRight: "2rem",
              paddingRight: "2rem",
              margin: 0,
              fontSize: "15px",
              textAlign: "right",
            }}
          >
            For Crescon Projects and Services Pvt Ltd
          </p>
          <p
            style={{
              marginTop: "auto",
              marginLeft: "auto",
              marginRight: "2rem",
              marginBottom: "1rem",
              fontSize: "15px",
            }}
          >
            Authorised Signatory
          </p>
        </div>
      </div>
    </div>
  );

  const addFooter = async (doc, footerHTML, pageHeight) => {
    const tempDiv = document.createElement("div");
    document.body.appendChild(tempDiv);
    ReactDOM.render(footerHTML, tempDiv);

    const canvas = await html2canvas(tempDiv);
    const imgData = canvas.toDataURL("image/png");
    const imgProps = doc.getImageProperties(imgData);
    const imgWidth = 190;
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

    doc.addImage(
      imgData,
      "PNG",
      10,
      pageHeight - imgHeight - 10,
      imgWidth,
      imgHeight
    );

    ReactDOM.unmountComponentAtNode(tempDiv);
    document.body.removeChild(tempDiv);
  };

  const downloadPdfDocument = async (div_id) => {
    const divToPrint = document.getElementById(div_id);

    window.scrollTo(0, 0);
    const canvas = await html2canvas(divToPrint);
    const imgData = canvas.toDataURL("image/png");
    const imgWidth = 190;
    const pageHeight = 290;
    const footerHeight = 50; // Estimated height of footer content in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    const footerHTML = <FooterContent />;

    let heightLeft = imgHeight;
    const doc = new JsPDF("pt", "mm");
    let position = 0;

    // let str = "" + doc.internal.getNumberOfPages();
    // doc.setFontSize(10);


    doc.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight - footerHeight);
    await addFooter(doc, footerHTML, pageHeight);
    heightLeft -= pageHeight - footerHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(
        imgData,
        "PNG",
        10,
        position,
        imgWidth,
        imgHeight - footerHeight
      );
      await addFooter(doc, footerHTML, pageHeight);
      heightLeft -= pageHeight - footerHeight;
    }

    doc.save("PoInvoice.pdf");
    // doc.text(575, 830, str);
  };

 
  


  // Example usage
  // downloadPdfDocument('some_div_id');

  return (
    <Col
      xs={12}
      className="py-2 px-1 p-md-3 w-lg-90 paged"
      style={
        {
          // width: "1039px",
          // , height: "1216px"
        }
      }
    >
      <Header />
      <div
        // style={{ height: "100vh", overflow: "hidden" }}
        className="po-main-box my-3 avoid-break"
      >
        <section className="p-3">
          <div id="po-out-main-cont">
            <header className="d-flex ">
              <Col xs={1} className="d-flex my-3 ">
                <img
                  className="ms-4"
                  style={{ width: "70px", height: "70px" }}
                  src={logo}
                  alt="logo"
                />
              </Col>
              <Col
                xs={7}
                className="d-flex flex-column po-vendor-detail-invoice-box my-3"
              >
                <h5 className="mx-auto py-1">
                  Crescon Projects and Services Pvt Ltd
                </h5>
                <p className="py-1 mx-auto ">
                  3rd Floor, Gulecha Towers 158, Arcot Road, Vadapalani Chennai
                  – 600 026
                </p>
                <h6 className="m-auto py-1">Purchase Order</h6>
              </Col>
              <Col xs={4} className="my-auto ">
                <p className="py-1 fs-13px">
                  ISO 9001:2015 : Quality Management System
                </p>
                <p className="py-1 fs-13px">
                  ISO 14001:2015 : Environmental Management System
                </p>
                <p className="py-1 fs-13px">
                  ISO 45001:2018 : Occupational Health & Safety Management
                  System
                </p>
              </Col>
            </header>
            <main className="">
              <section className="d-flex">
                <Col xs={6} className="po-vendor-detail-box p-3">
                  <h6>To</h6>
                  <div className="d-flex  py-1 my-1">
                    <Col md={4}>
                      <label>Vendor Name</label>
                    </Col>
                    <Col md={8}>
                      <p>{poPrintDetails?.vendor?.company_name}</p>
                    </Col>
                  </div>
                  <div className="d-flex  my-1 py-1">
                    <Col md={4}>
                      <label>Address</label>
                    </Col>
                    <Col md={8}>
                      <p>{`${poPrintDetails?.vendor?.door_no} , ${poPrintDetails?.vendor?.address}`}</p>
                    </Col>
                  </div>
                  <div className="d-flex ">
                    <Col md={4}>
                      <label>State</label>
                    </Col>
                    <Col md={8}>
                      <p>{poPrintDetails?.vendor?.state}</p>
                    </Col>
                  </div>
                  <div className="d-flex  py-1 my-1">
                    <Col md={4}>
                      <label>Mobile No</label>
                    </Col>
                    <Col md={8}>
                      <p>{poPrintDetails?.vendor?.mobile ?? "-"}</p>
                    </Col>
                  </div>
                  <div className="d-flex ">
                    <Col md={4}>
                      <label>Email ID</label>
                    </Col>

                    <Col md={8}>
                      <p>{poPrintDetails?.vendor?.email}</p>
                    </Col>
                  </div>
                  <div className="d-flex ">
                    <Col md={4}>
                      <label>GST No</label>
                    </Col>

                    <Col md={8}>
                      <p>{poPrintDetails?.vendor?.gst_no ?? "-"}</p>
                    </Col>
                  </div>
                </Col>
                <Col xs={6} className="po-vendor-detail-invoice-box p-3">
                  <div className="d-flex ">
                    <label>Po No</label>
                    <p>{poPrintDetails?.po_no}</p>
                  </div>
                  <div className="d-flex ">
                    <label>PR Date</label>
                    <p>{poPrintDetails?.po_date}</p>
                  </div>
                  {poPrintDetails?.type == 2 && (
                    <>
                      {" "}
                      <div className="d-flex ">
                        <label>Mr No</label>
                        <p>{poPrintDetails?.material_request?.mr_no}</p>
                      </div>
                      <div className="d-flex ">
                        <label>MR Date</label>
                        <p>{poPrintDetails?.material_request?.mr_date}</p>
                      </div>
                    </>
                  )}
                </Col>
              </section>
              <section className="d-flex">
                <Col
                  style={{ borderRight: "1px solid black" }}
                  xs={6}
                  className="po-vendor-detail-invoice-box p-3"
                >
                  <h6>Invoicing Address:</h6>

                  <div className="d-flex flex-wrap">
                    <Col md={4}>
                      <label>Head Office</label>
                    </Col>
                    <Col md={8}>
                      <p>Crescon Projects and Services Pvt Ltd</p>
                    </Col>
                    <Col md={4}>
                      <label>Address</label>
                    </Col>
                    <Col md={8}>
                      <p>
                        3rd Floor, Gulecha Towers 158, Arcot Road, Vadapalani
                        Chennai – 600 026
                      </p>
                    </Col>

                    <Col md={4} className="mt-4">
                      <label>Contact Person</label>
                    </Col>
                    <Col md={8} className="mt-4">
                      <p>M. Janakiraman</p>
                    </Col>
                    <Col md={4}>
                      <label>Contact No</label>
                    </Col>
                    <Col md={8}>
                      <p>044 - 23664945</p>
                    </Col>
                    <Col md={4}>
                      <label>Mail id</label>
                    </Col>
                    <Col md={8}>
                      <p>purchase@cresconprojects.com</p>
                    </Col>
                    <Col md={4}>
                      <label>Gst No</label>
                    </Col>
                    <Col md={8}>
                      <p>33AAECC6175F1ZT</p>
                    </Col>
                  </div>
                </Col>
                <Col xs={6} className="po-vendor-detail-invoice-box p-3">
                  <h6>Delivery Address:</h6>
                  <div className="d-flex">
                    <Col xs={4}>
                      <label>Project Name</label>
                    </Col>
                    <Col xs={8}>
                      <p>{poPrintDetails?.project?.name ?? "-"}</p>
                    </Col>
                  </div>
                  <div className="d-flex">
                    <Col xs={4}>
                      <label>Project Address</label>
                    </Col>
                    <Col xs={8}>
                      <p>
                        {`${poPrintDetails?.project?.door_no}, ${poPrintDetails?.project?.address}` ??
                          "-"}
                      </p>
                    </Col>
                  </div>
                  <div className="d-flex">
                    <Col xs={4}>
                      <label>State</label>
                    </Col>
                    <Col xs={8}>
                      <p>{poPrintDetails?.project?.state ?? "-"}</p>
                    </Col>
                  </div>
                  <div className="d-flex">
                    <Col xs={4}>
                      <label>District</label>
                    </Col>
                    <Col xs={8}>
                      <p>{poPrintDetails?.project?.district ?? "-"}</p>
                    </Col>
                  </div>
                  <div className="d-flex">
                    <Col xs={4}>
                      <label>Pincode</label>
                    </Col>

                    <Col xs={8}>
                      <p>{poPrintDetails?.project?.pincode ?? "-"}</p>
                    </Col>
                  </div>
                  <div className="d-flex">
                    <Col xs={4}>
                      <label>Contact No</label>
                    </Col>
                    <Col xs={8}>
                      <p>{poPrintDetails?.project?.mobile ?? "-"}</p>
                    </Col>
                  </div>
                  <div className="d-flex">
                    <Col xs={4}>
                      <label>Mail id</label>
                    </Col>
                    <Col xs={8}>
                      <p>{poPrintDetails?.project?.email ?? "-"}</p>
                    </Col>
                  </div>
                </Col>
              </section>
              <Col className="p-2  m-1 text-center">
                <div
                  // style={{ overflowX: "scroll" }}
                  className="po-out-table bg-white"
                >
                  <table
                    id="po-output-table"
                    className="table table-responsive-xl"
                  >
                    <thead>
                      {poPrintDetails?.material_request?.request_type == 1 ? (
                        <>
                          <tr>
                            <th>Sl.No</th>
                            <th>
                              {poPrintDetails?.type == 1
                                ? "Assets Code"
                                : "Material Description"}{" "}
                            </th>
                            <th>UOM</th>
                            <th>Quantity</th>
                            <th>Unit Rate (INR) </th>
                            <th>Total Value</th>
                            <th>HSN Code</th>
                            {+poTable[0]?.igst_value < 1 && (
                              <>
                                <th>SGST</th>
                                <th>CGST</th>
                              </>
                            )}

                            {+poTable[0]?.igst_value > 0 && <th>IGST</th>}
                            <th>Net Value</th>
                          </tr>
                        </>
                      ) : (
                        <>
                          <tr>
                            <th>Sl.No</th>
                            <th>
                              {poPrintDetails?.type == 1
                                ? "Assets Code"
                                : "Material Description"}{" "}
                            </th>
                            <th>UOM</th>
                            <th>Quantity</th>
                            <th>Basic Rate (INR) </th>
                            <th>Total Value</th>
                          </tr>
                        </>
                      )}
                    </thead>

                    {poPrintDetails?.material_request?.request_type == 1 ? (
                      <tbody>
                        {poTable?.map((item, ind) => {
                          return (
                            <>
                              <tr className="newpage" key={item?.id}>
                                <td>{ind + 1}</td>
                                <td className="text-start">
                                  {item?.material_description}
                                </td>
                                <td>{item?.uom}</td>
                                <td>{item?.qty}</td>
                                <td>{item?.basic_price}</td>
                                <td>{item?.total_value}</td>
                                <td> {item?.hsn_code?.code_name ?? "-"}</td>
                                {item?.igst_value < 1 && (
                                  <>
                                    <td>{item?.sgst_value}</td>
                                    <td>{item?.cgst_value}</td>
                                  </>
                                )}

                                {+item?.igst_value > 0 && (
                                  <td>{item?.igst_value}</td>
                                )}
                                <td>{item?.net_value}</td>
                              </tr>
                              <tr>
                                <td colSpan="1">
                                  <label
                                    className="text-nowrap"
                                    style={{ fontFamily: "f2" }}
                                  >
                                    Attributes :
                                  </label>
                                </td>
                                <td colspan="11" className="text-start">
                                  <ul>
                                    {item?.specifications?.map((item, ind) => (
                                      <li>{item}</li>
                                    ))}
                                  </ul>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        {poTable?.map((item, ind) => {
                          let spi = item?.material?.attribute;
                          return (
                            <>
                              <tr className="newpage" key={item?.id}>
                                <td>{ind + 1}</td>
                                <td className="text-start">
                                  {item?.material?.name}
                                </td>
                                <td>{item?.uom || item?.material?.uom}</td>
                                <td>{item?.qty}</td>
                                <td>{item?.basic_price}</td>
                                <td>{item?.net_value}</td>
                              </tr>
                              <tr>
                                <td colSpan="1">
                                  <label
                                    className="text-nowrap"
                                    style={{ fontFamily: "f2" }}
                                  >
                                    Attributes :
                                  </label>
                                </td>
                                <td colspan="11" className="text-start ">
                                  <ul>
                                    {item?.attributes
                                      ? item?.attributes
                                      : item?.attributes == null
                                        ? item?.specifications?.length > 0
                                          ? item?.specifications?.map(
                                            (ite, inde) => (
                                              <li key={inde}>{ite}</li>
                                            )
                                          )
                                          : `${spi?.specification1
                                            ? spi?.specification1 + " ,"
                                            : ""
                                          } ${spi?.specification2
                                            ? spi?.specification2 + " ,"
                                            : ""
                                          } ${spi?.specification3
                                            ? spi?.specification3 + " ,"
                                            : ""
                                          } ${spi?.specification4
                                            ? spi?.specification4 + " ,"
                                            : ""
                                          }
                                    ${spi?.specification5
                                            ? spi?.specification5 + " ,"
                                            : ""
                                          } ${spi?.specification6
                                            ? spi?.specification6 + " ,"
                                            : ""
                                          }`
                                        : directPoAttributes[item?.id] &&
                                        directPoAttributes[item?.id][0]?.map(
                                          (att, ind) => <li key={ind}>{att}</li>
                                        )}
                                  </ul>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
              </Col>

              <div className="p-2  m-1 text-center">
                <div
                  // style={{ overflowX: "scroll" }}
                  className="po-out-table bg-white"
                >
                  <table
                    id="po-output-table"
                    className="table table-responsive-xl"
                  >
                    <thead>
                      <tr>
                        {poTable[0]?.basic_price > 0 && (
                          <th>Basic Rate (INR) </th>
                        )}
                        {poTable[0]?.discount_value > 0 && <th>Disc</th>}
                        {poTable[0]?.cgst_value > 0 && <th>CGST</th>}
                        {poTable[0]?.igst_value > 0 && <th>IGST</th>}
                        {poTable[0]?.sgst_value > 0 && <th>SGST</th>}
                        {poTable[0]?.net_value > 0 && <th>Total Value</th>}
                      </tr>
                    </thead>
                    {poTable?.length > 0 ? (
                      <tbody>
                        <tr>
                          {poTable[0]?.basic_price > 0 && (
                            <td>
                              {poTable.reduce((accumulator, object) => {
                                return (
                                  Number(accumulator) +
                                  Number(object.basic_price)
                                );
                              }, 0)}
                            </td>
                          )}

                          {poTable[0]?.discount_value > 0 && (
                            <td>
                              {poTable.reduce((accumulator, object) => {
                                return accumulator + object.discount_value;
                              }, 0)}
                            </td>
                          )}

                          {poTable[0]?.cgst_value > 0 && (
                            <td>
                              {poTable.reduce((accumulator, object) => {
                                return Number(
                                  Number(accumulator) +
                                  Number(object.cgst_value)
                                ).toFixed(2);
                              }, 0)}
                            </td>
                          )}

                          {poTable[0]?.igst_value > 0 && (
                            <td>
                              {poTable.reduce((accumulator, object) => {
                                return Number(
                                  Number(accumulator) +
                                  Number(object.igst_value)
                                ).toFixed(2);
                              }, 0)}
                            </td>
                          )}

                          {poTable[0]?.sgst_value > 0 && (
                            <td>
                              {poTable.reduce((accumulator, object) => {
                                return Number(
                                  Number(accumulator) +
                                  Number(object.sgst_value)
                                ).toFixed(2);
                              }, 0)}
                            </td>
                          )}

                          {poTable[0]?.net_value > 0 && (
                            <td>
                              {poTable.reduce((accumulator, object) => {
                                return Number(
                                  Number(accumulator) + Number(object.net_value)
                                ).toFixed(2);
                              }, 0)}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          {poTable[0]?.basic_price > 0 && (
                            <td>{poTable[0]?.basic_price}</td>
                          )}
                          {poTable[0]?.discount_value > 0 && (
                            <td>{poTable[0]?.discount_value}</td>
                          )}
                          {poTable[0]?.cgst_value > 0 && (
                            <td>{poTable[0]?.cgst_value}</td>
                          )}
                          {poTable[0]?.igst_value > 0 && (
                            <td>{poTable[0]?.igst_value}</td>
                          )}
                          {poTable[0]?.sgst_value > 0 && (
                            <td>{poTable[0]?.sgst_value}</td>
                          )}
                          {poTable[0]?.net_value > 0 && (
                            <td>{poTable[0]?.net_value}</td>
                          )}
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>

              <div className="d-flex gap-2 m-3 page_break_before_after">
                <label>Amount In Words</label>
                <p className="mt-1">
                  ({numToWords ? `Rupees ${numToWords} Only` : "-"})
                </p>
              </div>
              {terms[0] != "" && (
                <section className="po-terms-box page_break_before_after">
                  <h6>Terms And Conditions</h6>
                  <div>
                    <ul className="">
                      {terms?.map((term) => (
                        <li className="py-1 page_break_before_after">{term}</li>
                      ))}
                    </ul>
                  </div>
                </section>
              )}

              <section>
                {poAnnex?.length > 0 && (
                  <div className="page_break_before_after">
                    <h6 className="px-2 pt-2 m-1">Annexure Details</h6>
                    <Col className="emply-master-form px-2  m-1 text-center">
                      <div
                        style={{ overflowX: "scroll" }}
                        className="boq-table bg-white"
                      >
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th className="">Sl.No</th>
                              <th className="">Spefication and Description</th>
                              <th className="">Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {poAnnex &&
                              poAnnex?.map((annex, ind) => (
                                <tr key={annex?.id}>
                                  <td>{ind + 1}</td>
                                  <td>{annex?.descriptions}</td>
                                  <td>{annex?.quantity}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </div>
                )}
              </section>
            </main>
          </div>
          <div
            className="d-flex justify-content-between w-100 page_break_before_after"
            style={{
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              padding: "10px",
            }}
          >
            <div>
              <p className="text-capitalize">
                {poPrintDetails?.created_user?.name}
              </p>
              <label>Prepared</label>
            </div>
            <div>
              <p className="text-capitalize">{approval ? approval : "-"}</p>
              <label>Approved</label>
            </div>
          </div>
          <div
            id="page-break"
            className="d-flex"
            style={{
              border: "1px solid black",
              padding: "0 10px 0",
              marginBottom: "20px",
            }}
          >
            <Col
              xs={6}
              style={{
                borderRight: "1px solid black",
                minHeight: "6rem",
                paddingTop: "10px",
              }}
              className="d-flex flex-column"
            >
              <p className="">
                Please Sign and Return Duplicate Copy as a Token of Acceptance
              </p>
              <p className="mt-auto mb-1">Vendor Acceptance</p>
            </Col>
            <Col xs={6} className="d-flex flex-column">
              <p className="ms-auto me-2 pe-3">
                For Crescon Projects and Services Pvt Ltd
              </p>
              <p className="mt-auto ms-auto me-4 mb-1">Authorised Signatory</p>
            </Col>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              {poPrintDetails?.status == 2 && (
                <button
                  class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
                  onClick={() => downloadPdfDocument("po-out-main-cont")}
                >
                  Print
                </button>
              )}
            </div>
            <div className="">
              {searchParams?.get("id") ? (
                <Link
                  class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2 "
                  to={`/procurement/puchase-order-list?projectname=${searchParams?.get(
                    "projectname"
                  )}&project=${searchParams?.get("project")}`}
                >
                  Close
                </Link>
              ) : (
                <button
                  class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
                  onClick={() => navigate(-1)}
                >
                  Close
                </button>
              )}
            </div>
          </div>
        </section>
      </div>
    </Col>
  );
}

export default PoOutputScreen;
