// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { api } from "../../../constants/Services";

const InternalMaterialRemarks = ({
  types,
  setTypesErr,
  retrunSlipNo,
  setRetrunSlipNoErr,
  materialIssueDate,
  setMaterialIssueNoErr,
  materialIssueNo,
  ProjectTo,
  setProjectToErr,
  retrunSlipDate,
  setRetrunSlipDateErr,
  WareHouse,
  setWareHouseErr,
  setModal,
  tableSel,
  setSuccessMessage,
}) => {
  const [Contact, setContact] = useState("");
  const [Remarks, setRemarks] = useState("");
  const [ContactErr, setContactErr] = useState(false);
  const [RemarksErr, setRemarksErr] = useState(false);

  const navigate = useNavigate();
  // console.log("tableSel", tableSel);

  const submitHandler = () => {
    if (
      // types?.length == 0 ||
      // retrunSlipNo?.length == 0 ||
      materialIssueDate?.length == 0 ||
      materialIssueNo?.length == 0 ||
      ProjectTo?.length == 0 ||
      retrunSlipDate?.length == 0 ||
      WareHouse?.length == 0 ||
      Contact?.length == 0
    ) {
      // setTypesErr(true);
      // setRetrunSlipNoErr(true);
      setMaterialIssueNoErr(true);
      setProjectToErr(true);
      setRetrunSlipDateErr(true);
      setWareHouseErr(true);
      setContactErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("project_id", ProjectTo);
      formdata.append("material_issue_id", materialIssueNo);
      formdata.append("return_slip_no", retrunSlipNo);
      formdata.append("return_slip_date", retrunSlipDate);
      formdata.append("return_by", Contact);
      formdata.append("type", 1);

      if (Remarks) {
        formdata.append("remarks", Remarks);
      }

      let count = 1;

      tableSel?.map((item, ind) => {
        if (item?.error == 1) {
          count = count + 1;
        }

        formdata.append(
          `internal_return_details[${ind}][material_issue_detail_id]`,
          item?.id
        );
        formdata.append(
          `internal_return_details[${ind}][return_qty]`,
          item?.return_qty
        );
      });

      if (count == 1) {
        api
          .internReturnAdd(formdata)
          .then((res) => {
            if (res?.status == "success") {
              setSuccessMessage(res?.internal_return_no);
              setModal(true);
            } else {
              alert(res?.message);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Return By</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
            placeholder="Enter Return By"
            onChange={(e) => setContact(e.target.value)}
            value={Contact}
          />
          {Contact?.length == 0 && ContactErr && (
            <p className="err">Enter Contact </p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start ps-0">
          <label className="w-100">Remarks</label>
          <textarea
            rows="4"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
            placeholder="Enter Remarks"
            onChange={(e) => setRemarks(e.target.value)}
            value={Remarks}
          />
          {Remarks?.length == 0 && RemarksErr && (
            <p className="err">Enter Remarks </p>
          )}
        </Col>
      </Col>
      <Col className="d-flex justify-content-between">
        <div>
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2 me-3"
            onClick={submitHandler}
          >
            Save
          </button>
          <button className="bg-gray border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
            Cancel
          </button>
        </div>
      </Col>
    </Col>
  );
};

export default InternalMaterialRemarks;
