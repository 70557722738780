// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../../constants/Services";

const MaterialTransferForm = ({
  projectList,
  warehouseSelector,
  TransferType,
  setTransferType,
  setProject,
  project,
  setProjectTo,
  projectTo,
  setTransferDate,
  transferDate,
  setFiles,
  files,
  setWarehouse,
  wareHouse,
  setWarehouseTo,
  wareHouseTo,
  projectToErr,
  projectErr,
  transferDateErr,
  filesErr,
  wareHouseErr,
  wareHouseToErr,
  TransferTyperErr,
  setMaterialIssueList,
  setTableSel,
  setRow,
}) => {
  const [list, setList] = useState([]);
  const [listTo, setListTo] = useState([]);
  const [listProTo, setListProTo] = useState([]);

  const mrTransferDateHandler = (mr_date) => {
    let current_date = new Date();
    let enter_date = new Date(mr_date);

    if (enter_date > current_date) {
    } else {
      setTransferDate(mr_date);
    }
  };

  const getWareHouse = (id) => {
    setProject(id);
    setTableSel([]);
    setRow([1]);
    api
      .warehousePojectView(id)
      .then((res) => {
        // console.log("warehousePojectView", res);
        setList(res?.warehouse_project);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const selectWarehouse = (id) => {
    warehouseSelector(id);
    let temp = [];
    temp.push(+id);
    setListTo(temp);
    setWarehouseTo("");
    setTableSel([]);
    setRow([1]);
  };

  const selectProject = (id) => {
    getWareHouse(id);
    let temp = [];
    temp.push(+id);
    setListProTo(temp);
    setProjectTo("");
    setTableSel([]);
    setRow([1]);
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">Material Transfer</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          <p
            style={{ minHeight: "2rem", width: "90%", marginRight: "auto",fontSize:"12px" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            Crescon Projects and Service Pvt Ltd
          </p>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>
          <select
            style={{ minHeight: "2.1rem",fontSize:"12px"  }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => selectProject(e.target.value)}
            value={project}
          >
            <option hidden>Select Project</option>
            {projectList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
          {project?.length == 0 && projectErr && (
            <p className="err">Select Project</p>
          )}
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Transfer Type</label>
          <select style={{fontSize:"12px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
            value={TransferType}
            onChange={(e) => setTransferType(e.target.value)}
          >
            <option value={""} hidden>
              Select Transfer Type
            </option>
            <option value={1}>Warehouse</option>
            <option value={2}>Project to Project</option>
          </select>
          {TransferType?.length == 0 && TransferTyperErr && (
            <p className="err">Select Transfer Type</p>
          )}
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Warehouse From</label>
          <select
            style={{ minHeight: "2.1rem",fontSize:"12px"  }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => selectWarehouse(e.target.value)}
            value={wareHouse}
          >
            <option hidden>Select Warehouse</option>
            {list?.map((item, ind) => {
              return (
                <option key={ind} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          {wareHouse?.length == 0 && wareHouseErr && (
            <p className="err">Select Warehouse</p>
          )}
        </Col>
        {TransferType == 2 && (
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Project To</label>
            <select
              style={{ minHeight: "2.1rem",fontSize:"12px"  }}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              onChange={(e) => setProjectTo(e.target.value)}
              value={projectTo}
            >
              <option hidden>Select Project</option>
              {projectList?.map(
                (item, index) =>
                  !listProTo.includes(item?.id) && (
                    <option key={index} value={item?.id}>
                      {item?.name}
                    </option>
                  )
              )}
            </select>
            {projectTo?.length == 0 && projectToErr && (
              <p className="err">Select Project To</p>
            )}
          </Col>
        )}
        {TransferType == 1 && (
          <Col
            xs={12}
            sm={6}
            lg={6}
            xl={6}
            className="inputinner-box text-start"
          >
            <label className="w-100">Warehouse To</label>
            <select
              style={{ minHeight: "2.1rem",fontSize:"12px"  }}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              onChange={(e) => setWarehouseTo(e.target.value)}
              value={wareHouseTo}
            >
              <option hidden>Select Warehouse</option>
              {list?.map((item, ind) => {
                return (
                  !listTo.includes(item?.id) && (
                    <option key={ind} value={item?.id}>
                      {item?.name}
                    </option>
                  )
                );
              })}
            </select>
            {wareHouseTo?.length == 0 && wareHouseToErr && (
              <p className="err">Select Warehouse To</p>
            )}
          </Col>
        )}

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Transfer Date</label>
          <input
            type="date" style={{fontSize:"12px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
            onChange={(e) => setTransferDate(e.target.value)}
            value={transferDate}
          />
          {transferDate?.length == 0 && transferDateErr && (
            <p className="err">Select Transfer Date</p>
          )}
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Document</label>
          <div
            style={{ overflowX: "hidden", width: "90%", minHeight: "2.1rem",fontSize:"12px"  }}
            className="d-flex ms-0 emply-mas-doc-box accordion-body bg-lightBlue"
          >
            <input
              type="file"
              className="border-0 px-1"
              onChange={(e) => setFiles(e.target.files[0])}
              accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf,"
            />
          </div>
          {files?.length == 0 && filesErr && (
            <p className="err">Upload Document</p>
          )}
        </Col>
      </Col>
    </Col>
  );
};

export default MaterialTransferForm;
