import React, { useState, useEffect } from "react";
import StatusModal from "./StatusModal";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { api } from "../constants/Services";
import { Col } from "react-bootstrap";
import Header from "../component/Header/Header";

function ProjectActionComponent() {
  const location = useLocation();
  const navigate = useNavigate();
  const [projectDetails, setProjectDetails] = useState([]);
  const [projectUpdateDetails, setProjectUpdateDetails] = useState({
    head_office: "Crescon Projects and Service Pvt Ltd",
    project_id: "",
    project_no: "",
    email: "",
    alternate_email: "",
    start_date: "",
    name: "",
    awarded_date: "",
    eot_date: "",
    value: "",
    mobile: "",
    phone_no: "",
    door_no: "",
    district: "",
    address: "",
    state: "",
    taluk: "",
    pincode: "",
    loi_date: "",
    end_date: "",
  });
  const [showStatus, setShowStatus] = useState(false);
  const statusModalClose = () => {
    setShowStatus(!showStatus);
    navigate(-1);
  };

  const { project_id, action } = location?.state;

  const projectUpdateHandler = () => {
    api.project_update(projectUpdateDetails).then((res) => {
      if (res?.status == "succes") {
        statusModalClose();
      }
    });
  };

  useEffect(() => {
    api.project_show(project_id).then((res) => {
      setProjectUpdateDetails({
        head_office: "Crescon Projects and Service Pvt Ltd",
        project_id: res?.project?.id,
        project_no: res?.project?.project_no,
        start_date: res?.project?.start_date,
        name: res?.project?.name,
        eot_date: res?.project?.eot_date,
        awarded_date: res?.project?.awarded_date,
        alternate_email: res?.project?.alternate_email,
        email: res?.project?.email,
        value: res?.project?.value,
        mobile: res?.project?.mobile,
        phone_no: res?.project?.phone_no,
        door_no: res?.project?.door_no,
        district: res?.project?.district,
        address: res?.project?.address,
        state: res?.project?.state,
        taluk: res?.project?.taluk,
        pincode: res?.project?.pincode,
        loi_date: res?.project?.loi_date,
        end_date: res?.project?.end_date,
      });
      setProjectDetails(res?.project);
    });
  }, [project_id]);

  return (
    <Col xs={12} className="p-3">
      <Header />

      <Col
        xs={12}
        className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <h4>Project Details</h4>

        {action == "view" ? (
          <Col xs={12} xxl={8} md={10} className="d-flex flex-wrap mx-auto">
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Name</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.name}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Project Number</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.project_no ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Project Value</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.value ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Start Date</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.start_date ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">End Date </label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.end_date ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Loi Date</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.loi_date ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">EOT Date</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.eot_date ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">District</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.district}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Address</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.address ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">State</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.state}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Taluk</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.taluk ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Pincode</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.pincode ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Mobile No</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.mobile ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Telephone No</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.phone_no ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Email</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.email ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Alternate Email</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1">
                {projectDetails?.alternate_email ?? "-"}
              </p>
            </Col>

            <div className="d-flex w-100">
              <Link
                to={-1}
                className="bg-gray border-0 ms-auto me-5 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2">
                Close
              </Link>
            </div>
          </Col>
        ) : (
          <Col xs={12} xxl={8} md={10} className="d-flex flex-wrap mx-auto">
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Project Name</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.name}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    name: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Project Number</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.project_no}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    project_no: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Project Value</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.value}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    value: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Start Date</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.start_date}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    start_date: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">End Date </label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.end_date}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    end_date: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Loi Date</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.loi_date}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    loi_date: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">EOT Date</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.eot_date}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    eot_date: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">District</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.district}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    district: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Door No</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.door_no}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    door_no: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Address</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.address}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    address: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">State</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.state}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    state: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Taluk</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.taluk}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    taluk: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Pincode</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.pincode}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    pincode: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Telephone No</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.phone_no}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    phone_no: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Mobile No</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.mobile}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    mobile: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Email</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.email}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    email: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Alternate Email</label>
              <input
                className="border-0 bg-lightBlue px-2 w-90 py-1 mt-1"
                value={projectUpdateDetails?.alternate_email}
                onChange={(e) =>
                  setProjectUpdateDetails({
                    ...projectUpdateDetails,
                    alternate_email: e.target.value,
                  })
                }
              />
            </Col>
            <div className="d-flex w-100">
              <button
                onClick={projectUpdateHandler}
                className="bg-primary border-0 ms-auto rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2">
                Update
              </button>
              <Link
                to={-1}
                className="bg-gray border-0 ms-3 me-5 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2">
                Close
              </Link>
            </div>
          </Col>
        )}
      </Col>
      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
        />
      )}
    </Col>
  );
}

export default ProjectActionComponent;
