// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../../constants/Services";

const MaterialRurnForm = ({
  podate,
  setPoDate,
  dateError,
  setDateError,
  projectType,
  setProjectType,
  projectList,
  POList,
  vendorName,
  setGrn,
  poSelector,
  grn,
  selectGrn,
  grnList,
  grnDate,
  poNo,
  warehouse,
  returnNo,
  setReturnNo,
  setReturnDate,
  returnDete,
  projectName,
  projectNameErr,
  projectSelectionHandler,
  poNoErr,
  grnErr,
  returnNoErr,
  returnDeteErr,
  setWarehouse,
}) => {
  const [warehouseList, setWarehouseList] = useState([]);

  const getWarehouse = (id) => {
    api
      .warehousePojectView(id)
      .then((res) => {
        if (res?.status == "success") {
          setWarehouseList(res?.warehouse_project);
        } else {
          console.log("ware", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // useEffect(() => {
  //   getWarehouse();
  // }, []);

  const filtered_date = (sel_date,podate) => {
    let today = new Date();
    let selected_date = new Date(sel_date);
    let now = new Date(podate);
    var defaultDate = now - 1000 * 60 * 60 * 24 * 1;
    defaultDate = new Date(defaultDate);
    if (selected_date > defaultDate || selected_date == defaultDate) {
      setReturnDate(sel_date);
    }
  };

  

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">Material Return</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          <p
            style={{ width: "90%", marginRight: "auto" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            Crescon Projects and Service Pvt Ltd
          </p>
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>
          <select
            onChange={(e) => {
              projectSelectionHandler(e);
              getWarehouse(e.target.value);
              setProjectType(e.target.value);
            }}
            value={projectName}
            className="border-0 bg-lightBlue px-2 py-2 w-90 mt-1"
          >
            <option>Select Project</option>
            {projectList?.map((project) => (
              <option key={project?.id} value={project?.id}>
                {project?.name}
              </option>
            ))}
          </select>

          {projectName?.length == 0 && projectNameErr && (
            <p className="err">Select Project Name</p>
          )}
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">PO Number</label>
          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => poSelector(e.target.value)}
            value={poNo}
          >
            <option>Select PO Number</option>
            {POList?.map((item, ind) => {
              return (
                <option value={item?.purchase_order?.id} key={ind}>
                  {item?.purchase_order?.po_no}
                </option>
              );
            })}
          </select>
          {poNo?.length == 0 && poNoErr && <p className="err">Select PO No.</p>}
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Vendor Name</label>
          <input
            disabled
            type="text"
            value={vendorName}
            className="border-0 bg-lightBlue px-2 py-2 w-90 mt-1 fs-14"
          />
        </Col>

        {/* <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GRN Number</label>
          <select
            className="border-0 bg-lightBlue px-2 py-2 w-90 mt-1 fs-14"
            onChange={(e) => selectGrn(e.target.value)}
            value={grn}
          >
            <option hidden>Select GRN</option>
            {grnList?.map((item, ind) => {
              return (
                <option value={item?.id} key={ind}>
                  {item?.grn_no}
                </option>
              );
            })}
          </select>
          {grn?.length == 0 && grnErr && <p className="err">Select GRN No.</p>}
        </Col> */}

        {/* <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GRN Date</label>
          <input
            disabled
            type="text"
            value={grnDate}
            className="border-0 bg-lightBlue px-2 py-2 w-90 mt-1 fs-14"
          />
        </Col> */}

        {/* <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Warehouse</label>
          <select
            className="border-0 bg-lightBlue px-2 py-2 w-90 mt-1 fs-14"
            value={warehouse}
            onChange={(e) => setWarehouse(e.target.value)}
          >
            <option hidden>Select Warehouse</option>
            {warehouseList?.map((item, ind) => {
              return (
                <option value={item?.id} key={ind}>
                  {item?.name}
                </option>
              );
            })}
          </select>
        </Col> */}

        {/* <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Return Receipt Number</label>
          <input
            type="number"
            className="border-0 bg-lightBlue px-2 py-2 w-90 mt-1 fs-14"
            onChange={(e) => setReturnNo(e.target.value)}
            value={returnNo}
          />
          {returnNo?.length == 0 && returnNoErr && (
            <p className="err">Enter Return Receipt No.</p>
          )}
        </Col> */}

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Return Receipt Date</label>
          <input
            type="date"
            className="border-0 bg-lightBlue px-2 py-2 w-90 mt-1 fs-14"
            onChange={(e) => filtered_date(
              e.target.value,
             podate

            )}
            value={returnDete}
          />
          {returnDete?.length == 0 && returnDeteErr && (
            <p className="err">Enter Return Receipt Date</p>
          )}
           {dateError?.includes("returndate") && (
            <p className="err">Future Days Not Allowed</p>
          )}
        </Col>
      </Col>
    </Col>
  );
};

export default MaterialRurnForm;
