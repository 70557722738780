import React, { useState, useEffect } from "react";
import { api } from "../../constants/Services";
import { Col } from "react-bootstrap";
import { tickIcon } from "../../assets/img";

const PoCreationAmendForm = ({
  projectList,
  purchaseOrderDetails,
  PurchaseOrderDetailsHandler,
  setMasterType,
  masterType,
  setDepartment,
  department,
  subDepartment,
  setPurchseOrderDetails,
  retrunSlipDate,
  setRetrunSlipDate,
  materialIssueDate,
  setMaterialIssueDate,
}) => {
  const [check, setCheck] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedPO, setSelectedPO] = useState("");
  const sparesClickHandler = () => setCheck(!check);
  const [POList, setPOList] = useState([]);
const [error, setError] = useState([]);
const [poFormDetails, setPoFormDetails] = useState({
  project_id: "",
  vendor_id: "",
  po_date: "",
  procure_type: "",
  currency: "",
  material_request_id: "",
  terms_conditions: "",
  project_no: "12345678",
  material_type: "",
  type_of_material: "",
  is_gst: null,
});

  const getPurchaseOrderList = () => {
    let data = {
      project_id: +selectedProject,
    };
    api.purchaseOrderList(data).then((res) => {
      if (res?.status == "success") {
        let finder = res?.purchase_orders?.filter((i)=>i?.purchase_order?.status == 2)
        // console.log('finder', finder);
        // setPOList(res?.purchase_orders);
        setPOList(finder)
      }
    });
  };
  useEffect(() => {
    if (selectedPO) {
      PurchaseOrderDetailsHandler(+selectedPO);
    }
  }, [selectedPO]);

  useEffect(() => {
    if (selectedProject) {
      getPurchaseOrderList();
    }
  }, [selectedProject]);
  console.log('purchaseOrderDetails', purchaseOrderDetails);
  
  const poDateHandler = (sel_date, grn_date) => {
    console.log(grn_date,"grn_date");
    let today = new Date();
    let selected_date = new Date(sel_date);
    let now = new Date(grn_date);
    var defaultDate = now - 1000 * 60 * 60 * 24 * 1;
    defaultDate = new Date(defaultDate);
    if (selected_date > defaultDate || selected_date == defaultDate) {
      setRetrunSlipDate(sel_date)
      setPurchseOrderDetails({...purchaseOrderDetails, po_date:sel_date})
      }
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">Po Amendment</h4>
      <Col className="d-flex flex-wrap mx-lg-3">
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          <p
            style={{ minHeight: "2rem", width: "90%", marginRight: "auto" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            Crescon Projects and Service Pvt Ltd
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Master Type</label>
          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => setMasterType(e.target.value)}
            value={masterType}
          >
            <option>Select Master Type</option>
            <option value="Assets">Assets</option>
            <option value="Material">Material</option>
          </select>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>

          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 ms-auto pointer"
            value={selectedProject}
            onChange={(e) => setSelectedProject(e.target.value)}
          >
            <option value={""}>Select Project</option>
            {projectList?.map((project) => (
              <option key={project?.id} value={project?.id}>
                {project?.name}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">PO No</label>
          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 ms-auto pointer"
            value={selectedPO}
            onChange={(e) => setSelectedPO(e.target.value)}
          >
            <option value={""}>Select Project</option>
            {POList?.map((purcaseOrder) => (
              <option
                key={purcaseOrder?.purchase_order?.id}
                value={purcaseOrder?.purchase_order?.id}
              >
                {purcaseOrder?.purchase_order?.po_no}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">MR No</label>
          <p
            style={{ width: "90%", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1"
          >
            {purchaseOrderDetails?.mr_no}
          </p>
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">MR Date</label>
          <p
            style={{ width: "90%", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1"
            onChange={(e) => setMaterialIssueDate(e.target.value)}
            value={materialIssueDate}
            disabled
          >
            {purchaseOrderDetails?.mr_date}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Type</label>
          <p
            style={{ width: "90%", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1"
          >
            {purchaseOrderDetails?.material_type}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Department</label>
          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => setDepartment(e.target.value)}
            value={department}
          >
            <option>Select Department</option>
            {subDepartment?.map((item) => (
              <option value={item?.id}>{item}</option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Requestion Type</label>
          <p
            style={{ width: "90%", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1"
          >
            {purchaseOrderDetails?.requestion_type == 1 ? "BOQ" : "Direct PO"}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">PO Date</label>
          {/* <p
            style={{ width: "90%", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1"
          >
            {purchaseOrderDetails?.po_date}
          </p> */}
          
          <input type="date"
           value={purchaseOrderDetails?.po_date}
           onChange={(e) => poDateHandler(e.target.value, purchaseOrderDetails?.mr_date)}
           placeholder="Enter Date"
          //  value={retrunSlipDate}
          //  min={value == "Material" && mrDate ? mrDate : ""}
          // onChange={(e)=>setPurchseOrderDetails({...purchaseOrderDetails, po_date:e.target.value})}  style={{ width: "90%", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1"/>
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Vendor Name</label>
          <p
            style={{ width: "90%", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1"
          >
            {purchaseOrderDetails?.company_name}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GST Registration</label>
          <p
            style={{ width: "90%", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1"
          >
            {purchaseOrderDetails?.gst_status == 1 ? "Yes" : "No"}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Vendor address</label>
          <p
            style={{ width: "90%", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1"
          >
            {purchaseOrderDetails?.vendor_address}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Procure Type</label>
          <p
            style={{ width: "90%", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1"
          >
            {purchaseOrderDetails?.currency == "India"
              ? "Domestic"
              : "International"}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Currency</label>
          <p
            style={{ width: "90%", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1"
          >
            {purchaseOrderDetails?.currency}
          </p>
        </Col>
      </Col>
    </Col>
  );
};

export default PoCreationAmendForm;
