// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { api } from "../../constants/Services";

const MaterialInstruction = ({
  user,
  setModal,
  totalPrice,
  projectName,
  setProjectName,
  setProjectNameErr,
  grnNo,
  setGrnNoErr,
  vendorName,
  setVendorNameErr,
  file,
  setFileErr,
  setInvoiceDateErr,
  setInvoiceNoErr,
  InvoiceNo,
  InvoiceDate,
  list,
  dcDate,
  dcNo,
  setShowStatus,
  setStatusMessage,
  refInvoiceNo,
}) => {
  const navigate = useNavigate();
  const [btn, setBtn] = useState(false);
  const [Freight, setFreight] = useState("");
  const [TCS, setTCS] = useState("");
  const [TotalValue, setTotalValue] = useState("");
  const [Prepared, setPrepared] = useState("");
  const [Remarks, setRemarks] = useState("");

  const [FreightErr, setFreightErr] = useState(false);
  const [TCSErr, setTCSErr] = useState(false);
  const [TotalValueErr, setTotalValueErr] = useState(false);
  const [PreparedErr, setPreparedErr] = useState(false);
  const [RemarksErr, setRemarksErr] = useState(false);

  const submitHandler = () => {
    // setModal(true);
    if (
      projectName?.length == 0 ||
      grnNo?.length == 0 ||
      vendorName?.length == 0 ||
      // InvoiceNo?.length == 0 ||
      InvoiceDate?.length == 0
      // ||Remarks?.length == 0
    ) {
      setProjectNameErr(true);
      setGrnNoErr(true);
      setInvoiceDateErr(true);
      // setInvoiceNoErr(true);
      setVendorNameErr(true);
      // setRemarksErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("grn_id", grnNo);
      formdata.append("project_id", projectName);
      formdata.append("vendor_id", vendorName);

      // formdata.append("invoice_no", InvoiceNo);
      formdata.append("grn_invoice_no", refInvoiceNo);
      formdata.append("invoice_date", InvoiceDate);
      formdata.append("remarks", Remarks);
      formdata.append("total_value", totalPrice);
      formdata.append("image", file);

      if (dcNo) {
        formdata.append("dc_no", dcNo);
      }

      if (dcDate) {
        formdata.append("dc_date", dcDate);
      }

      let check = 1;

      if (list?.length > 0) {
        list?.map((item, ind) => {
          if (item?.error == 1) {
            check = check + 1;
          }
          if (item?.custom == "custom") {
            formdata.append(
              `invoice_details[${ind}][project_nb_item_id]`,
              item?.id
            );
          } else {
            formdata.append(`invoice_details[${ind}][project_nb_item_id]`, "");
          }

          formdata.append(
            `invoice_details[${ind}][grn_detail_id]`,
            item?.custom == "custom" ? "" : item?.id
          );
          formdata.append(
            `invoice_details[${ind}][material_id]`,
            item?.custom == "custom" ? "" : item?.material_id
          );
          formdata.append(
            `invoice_details[${ind}][warranty_certificate]`,
            item?.custom == "custom" ? 0 : item?.warranty_certificate
          );
          formdata.append(
            `invoice_details[${ind}][mrir_certificate]`,
            item?.custom == "custom" ? 0 : item?.mrir_certificate
          );
          formdata.append(
            `invoice_details[${ind}][received_qty]`,
            +item?.received_qty
          );
          formdata.append(
            `invoice_details[${ind}][basic_price]`,
            +item?.basic_price
          );
          formdata.append(
            `invoice_details[${ind}][total_price]`,
            +item?.total_price
          );

          formdata.append(
            `invoice_details[${ind}][net_price]`,
            +item?.net_value
          );

          if (item?.hsn_igst == "-") {
            formdata.append(
              `invoice_details[${ind}][gst_percent]`,
              +item?.hsn_cgst + +item?.hsn_sgst
            );
            formdata.append(
              `invoice_details[${ind}][gst_value]`,
              Number(item?.hsn_value).toFixed(2)
            );
          } else {
            formdata.append(
              `invoice_details[${ind}][gst_percent]`,
              +item?.hsn_igst
            );
            formdata.append(
              `invoice_details[${ind}][gst_value]`,
              Number(item?.hsn_value).toFixed(2)
            );
          }

          if (item?.tcs) {
            formdata.append(`invoice_details[${ind}][tcs_percent]`, item?.tcs);
            formdata.append(
              `invoice_details[${ind}][tcs_value]`,
              item?.tcs_value
            );
          }

          if (item?.tds) {
            formdata.append(`invoice_details[${ind}][tds_percent]`, item?.tds);
            formdata.append(
              `invoice_details[${ind}][tds_value]`,
              item?.tds_value
            );
          }

          formdata.append(
            `invoice_details[${ind}][remarks]`,
            item?.remarks ? item?.remarks : "-"
          );
        });
      }

      if (check == 1) {
        setBtn(true);
        api
          .invoiceAdd(formdata)
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              setShowStatus(true);
              setStatusMessage(res?.message + " " + res?.invoice_no);
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} className="inputinner-box text-start">
          <label className="w-100">Net Value</label>
          <p
            style={{ minHeight: "2.1rem", width: "90%", lineHeight: "2.1rem" }}
            className="input-select bg-lightBlue ms-0 ps-3 my-1"
          >
            {totalPrice ? totalPrice : "-"}
          </p>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start ps-0 ">
          <label className="w-100">Remarks</label>
          <textarea
            rows="4"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
            placeholder="Enter Remarks"
            onChange={(e) => setRemarks(e.target.value)}
            value={Remarks}
          />
          {Remarks?.length == 0 && RemarksErr && (
            <p className="err">Enter Remarks</p>
          )}
        </Col>
      </Col>
      <Col className="d-flex justify-content-between">
        <div>
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2 me-3"
            onClick={submitHandler}
            disabled={btn ? true : false}
          >
            Save
          </button>
          <button
            className="bg-gray border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={() => window.location.reload()}
          >
            Cancel
          </button>
        </div>
        {/* <button className="bg-gray border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
          Export
        </button> */}
      </Col>
    </Col>
  );
};

export default MaterialInstruction;
