import React, { useState, useEffect } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import Header from "../../component/Header/Header";
import MaterialReceiptForm from "../../component/inventory/MaterialReceipt/MaterialReceiptForm";
import MaterialReceiptTable from "../../component/inventory/MaterialReceipt/MaterialReceiptTable";
import MaterialInstruction from "../../component/inventory/MaterialReceipt/MatrialInstruction";
import { api } from "../../constants/Services";
import { useNavigate } from "react-router";
import useUser from "../../constants/Storage/userData";
import StatusModal from "../../component/StatusModal";

const IsmMaterialReceiptScreen = () => {
  const [showStatus, setShowStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const { user } = useUser();
  const [projectList, setProjectList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [materialDetailList, setMaterialDetailList] = useState([]);
  const [MTRDate, setMTRDate] = useState("");
  const [ProjectFrom, setProjectFrom] = useState("");
  const [warehouseFrom, setWarehouseFrom] = useState("");
  const [warehouseFromName, setWarehouseFromName] = useState("");
  const [warehouseTo, setWarehouseTo] = useState("");
  const [Project, setProject] = useState("");
  const [receiptDate, setReceiptDate] = useState("");
  const [transferNoErr, setTransferNoErr] = useState(false);
  const [ProjectErr, setProjectErr] = useState(false);
  const [warehouseToErr, setWarehouseToErr] = useState(false);
  const [receiptDateErr, setReceiptDateErr] = useState(false);
  const [transferNo, setTransferNo] = useState("");
  const [transferNumber, setTransferNumber] = useState("");

  const [mrTableStaticData, setMrTableStaticTable] = useState({
    transfer_qty: {},
    remarks: {},
    net_value: {},
  });
  const [tableSel, setTableSel] = useState([]);

  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const poPrintHandler = () => {
    setModal(!modal);
  };

  const getProject = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };

  const materialTransferList = () => {
    api
      .matertialTransferLists()
      .then((res) => {
        if (res?.status == "success") {
          setMaterialList(res?.material_transfer);
        } else {
          console.log("res", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const materialTransferLists = (id) => {
    api
      .matertialTransferProjects(id)
      .then((res) => {
        if (res?.status == "success") {
          setMaterialList(res?.transfer_projects);
        } else {
          console.log("res", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProject();
    // materialTransferList();
  }, []);

  const selectProject = (id) => {
    setProject(id);
    materialTransferLists(id);
    setTransferNo("");
    setMaterialDetailList([]);
  };

  const selectMatrialNo = (id) => {
    setTransferNo(id);
    let finder = materialList?.find((i) => i?.id == id);
    let temp = [];

    finder?.material_transfer_detail?.map((item, ind) => {
      if (item?.transfer_qty !== 0) {
        temp.push({
          ...item,
          remarks: "",
        });
      }
    });

    setMaterialDetailList(temp);
    // console.log("finder", finder);
    setTransferNumber(finder?.transfer_no);
    setMTRDate(finder?.transfer_date);
    setProjectFrom(finder?.project_id);
    setWarehouseFrom(finder?.from_ware_house_id);
    setWarehouseFromName(finder?.from_warehouse?.name);
  };

  const statusModalClose = () => {
    setShowStatus(!showStatus);
  };

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <MaterialReceiptForm
        selectProject={selectProject}
        projectList={projectList}
        materialList={materialList}
        selectMatrialNo={selectMatrialNo}
        transferNo={transferNo}
        MTRDate={MTRDate}
        ProjectFrom={ProjectFrom}
        warehouseFrom={warehouseFrom}
        warehouseFromName={warehouseFromName}
        warehouseTo={warehouseTo}
        setWarehouseTo={setWarehouseTo}
        Project={Project}
        setProject={setProject}
        setReceiptDate={setReceiptDate}
        receiptDate={receiptDate}
        transferNoErr={transferNoErr}
        ProjectErr={ProjectErr}
        warehouseToErr={warehouseToErr}
        receiptDateErr={receiptDateErr}
      />
      <MaterialReceiptTable
        setTableSel={setTableSel}
        tableSel={tableSel}
        materialDetailList={materialDetailList}
        setMaterialDetailList={setMaterialDetailList}
        mrTableStaticData={mrTableStaticData}
        setMrTableStaticTable={setMrTableStaticTable}
      />
      <MaterialInstruction
        user={user?.name}
        setModal={setModal}
        setTransferNoErr={setTransferNoErr}
        setProjectErr={setProjectErr}
        setWarehouseToErr={setWarehouseToErr}
        setReceiptDateErr={setReceiptDateErr}
        Project={Project}
        receiptDate={receiptDate}
        warehouseTo={warehouseTo}
        MTRDate={MTRDate}
        ProjectFrom={ProjectFrom}
        warehouseFrom={warehouseFrom}
        transferNo={transferNo}
        materialDetailList={materialDetailList}
        transferNumber={transferNumber}
        setShowStatus={setShowStatus}
        setStatusMessage={setStatusMessage}
      />

      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
          statusMessage={statusMessage}
        />
      )}

      <Modal show={modal} centered onHide={() => setModal(!modal)}>
        <Modal.Header closeButton className="border-0">
          <label className="w-50 mx-auto my-1 py-1">Material Receipt : </label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <p className="text-center fs-6">Do you want to Print the Output?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-primary"
            onClick={poPrintHandler}
            variant="primary"
          >
            Print
          </Button>
          <Button variant="secondary" onClick={() => setModal(!modal)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default IsmMaterialReceiptScreen;
