// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { api } from "../../../constants/Services";
import { department } from "../../../constants/StaticData";

const MaterialInstruction = ({
  user,
  setModal,
  setTransferNoErr,
  setProjectErr,
  setWarehouseToErr,
  setReceiptDateErr,
  Project,
  receiptDate,
  warehouseTo,
  MTRDate,
  ProjectFrom,
  warehouseFrom,
  transferNo,
  materialDetailList,
  transferNumber,
  setShowStatus,
  setStatusMessage,
}) => {
  const [Instructions, setInstructions] = useState("");
  const [btn, setBtn] = useState(false);
  const navigate = useNavigate();

  const submitHandler = () => {
    if (
      warehouseTo?.length == 0 ||
      receiptDate?.length == 0 ||
      Project?.length == 0 ||
      transferNo?.length == 0
    ) {
      setTransferNoErr(true);
      setProjectErr(true);
      setWarehouseToErr(true);
      setReceiptDateErr(true);
    } else {
      let formdata = new FormData();

      formdata.append("project_id", Project);
      formdata.append("material_transfer_id", transferNo);
      formdata.append("from_project_id", ProjectFrom);
      formdata.append("from_ware_house_id", warehouseFrom);
      formdata.append("to_ware_house_id", warehouseTo);
      formdata.append("receipt_date", receiptDate);
      formdata.append("transfer_date", MTRDate);
      formdata.append("transfer_no", transferNumber);

      if (Instructions?.length > 0) {
        formdata.append("remarks", Instructions);
      }

      let count = 1;

      if (materialDetailList?.length > 0) {
        materialDetailList?.map((item, ind) => {
          if (item?.error == 1) {
            count = count + 1;
          }
          formdata.append(
            `material_receipt_details[${ind}][material_transfer_detail_id]`,
            item?.material_transfer_id
          );

          formdata.append(
            `material_receipt_details[${ind}][received_qty]`,
            item?.accept_qty
          );

          formdata.append(
            `material_receipt_details[${ind}][remarks]`,
            item?.remarks !== undefined
              ? item?.remarks
              : item?.remarks !== "undefined"
              ? item?.remarks
              : ""
          );
        });
      }

      if (count == 1) {
        setBtn(true);
        api
          .matertialReceiptAdd(formdata)
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              setShowStatus(true);
              setStatusMessage(res?.message + " " + res?.material_receipt_no);
            } else {
              console.log("res", res);
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <Col className="d-flex flex-wrap">
        <Col xs={12} xl={6} className="inputinner-box text-start ps-0 pe-4">
          <label className="w-100">Remarks</label>
          <textarea
            rows="4"
            className="border-0 bg-lightBlue px-2 py-1 w-100 mt-1 fs-14"
            placeholder="Enter Remarks"
            onChange={(e) => setInstructions(e.target.value)}
            value={Instructions}
          />
        </Col>
      </Col>
      <Col className="d-flex justify-content-between">
        <div>
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2 me-3"
            onClick={submitHandler}
            disabled={btn ? true : false}
          >
            Save
          </button>
          <button className="bg-gray border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
            Cancel
          </button>
        </div>
        {/* <button className="bg-gray border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
          Export
        </button> */}
      </Col>
    </Col>
  );
};

export default MaterialInstruction;
