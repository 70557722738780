// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../../constants/Services";
import moment from "moment/moment";

const InternalReceiptForm = ({
  projectList,
  setWareHouse,
  WareHouse,
  WareHouseErr,
  setProjectTo,
  ProjectTo,
  setMaterialIssueNo,
  setMaterialIssueDate,
  setRetrunSlipNo,
  setTypes,
  setRetrunSlipDate,
  materialIssueNo,
  materialIssueDate,
  retrunSlipNo,
  types,
  retrunSlipDate,
  typesErr,
  retrunSlipDateErr,
  materialIssueNoErr,
  ProjectToErr,
  setMatertialList,
  setTableSel,
}) => {
  const [warehouseList, setWareHouseList] = useState([]);
  const [materialIssueNoList, setMaterialIssueNoList] = useState([]);
  const getWarehouse = () => {
    api
      .warehouseList()
      .then((res) => {
        setWareHouseList(res?.warehouses);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getWarehouse();
  }, []);

  const materialIssue = (id) => {
    setMatertialList({});
    setProjectTo(id);
    api
      .matertialIssueViewProject(id)
      .then((res) => {
        setMaterialIssueNoList(res?.project_material_issue);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const selectMaterialNo = (item) => {
    setMaterialIssueNo(item);
    setTableSel([]);
    let finder = materialIssueNoList?.find((i) => i?.id == +item);
    setMatertialList(finder);
    setMaterialIssueDate(finder?.issue_date);
    setWareHouse(finder?.ware_house_id);
  };

  const filtered_date = (sel_date, material_date) => {
    let today = new Date();
    let selected_date = new Date(sel_date);
    let now = new Date(material_date);
    var defaultDate = now - 1000 * 60 * 60 * 24 * 1;
    defaultDate = new Date(defaultDate);
    if (selected_date > defaultDate || selected_date == defaultDate) {
      setRetrunSlipDate(sel_date);
    }
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">Internal Return</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          <p
            style={{ minHeight: "2rem", width: "90%", marginRight: "auto" ,fontSize:"12px"}}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            Crescon Projects and Service Pvt Ltd
          </p>
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>
          <select
            style={{ minHeight: "2.1rem",fontSize:"12px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => materialIssue(e.target.value)}
            value={ProjectTo}
          >
            <option>Select Project</option>
            {projectList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
          {ProjectTo?.length == 0 && ProjectToErr && (
            <p className="err">Select Project Name</p>
          )}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Issue No</label>
          <select
            style={{ minHeight: "2.1rem" ,fontSize:"12px"}}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => selectMaterialNo(e.target.value)}
            value={materialIssueNo}
          >
            <option hidden>Select Material Issue No</option>
            {materialIssueNoList?.map((item, ind) => {
              return (
                <option key={ind} value={item?.id}>
                  {item?.issue_no}
                </option>
              );
            })}
          </select>
          {materialIssueNo?.length == 0 && materialIssueNoErr && (
            <p className="err">Select Material Issue No</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Issue Date</label>
          <input style={{fontSize:"12px"}}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => setMaterialIssueDate(e.target.value)}
            value={materialIssueDate}
            disabled
          />
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">WareHouse</label>
          <select
            style={{ minHeight: "2.1rem",fontSize:"12px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => setWareHouse(e.target.value)}
            value={WareHouse}
            disabled
          >
            <option disabled hidden>
              Select WareHouse
            </option>
            {warehouseList?.map((item, ind) => {
              return (
                <option key={ind} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          {WareHouse?.length == 0 && WareHouseErr && (
            <p className="err">Enter WareHouse </p>
          )}
        </Col>

        {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Return Slip No</label>

          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => setRetrunSlipNo(e.target.value)}
            value={retrunSlipNo}
          />
          {retrunSlipNo?.length == 0 && WareHouseErr && (
            <p className="err">Enter Retrun Slip No </p>
          )}
        </Col> */}
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Return Slip Date</label>
          <input style={{fontSize:"12px"}}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            type="date"
            onChange={(e) => filtered_date(e.target.value, materialIssueDate)}
            value={retrunSlipDate}
          />
          {retrunSlipDate?.length == 0 && retrunSlipDateErr && (
            <p className="err">Enter Retrun Slip Date </p>
          )}
        </Col>

        {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Type</label>
          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => setTypes(e.target.value)}
            value={types}
          >
            <option hidden>Select Material Issue No</option>
            <option value={1}>Returnable</option>
            <option value={2}>Excess Qty</option>
          </select>
          {types?.length == 0 && typesErr && <p className="err">Select Type</p>}
        </Col> */}
      </Col>
    </Col>
  );
};

export default InternalReceiptForm;
